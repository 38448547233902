import React, { useState } from 'react';
import { MenuItemLink, useResourceDefinitions, useRedirect, DashboardMenuItem, useSidebarState } from 'react-admin';
import { styled } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import DefaultIcon from '@mui/icons-material/ViewList';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

const StyledSubMenuItem = styled('div')({
    paddingLeft: '32px',
});

const StyledMenuItem = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const StyledExpandIcon = styled('div')({
    marginLeft: 'auto',
    padding: '12px',
    cursor: 'pointer',
});

const Menu = ({ onMenuClick, logout }) => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const [open] = useSidebarState();
    const resources = useResourceDefinitions();
    const [expandedMenus, setExpandedMenus] = useState({
        Milestones: true
    });
    const redirect = useRedirect();

    // Group resources by parent
    const menuStructure = Object.keys(resources)
        .filter(name => {
            const resource = resources[name];
            return resource.hasList || resource.hasEdit || resource.hasShow || resource.hasCreate;
        })
        .reduce((acc, name) => {
            const resource = { ...resources[name], name };
            if (resource.options && resource.options.parent) {
                const parent = resource.options.parent;
                if (!acc[parent]) {
                    acc[parent] = [];
                }
                acc[parent].push(resource);
            } else {
                if (!acc.root) acc.root = [];
                acc.root.push(resource);
            }
            return acc;
        }, {});

    const handleToggle = (menu) => {
        setExpandedMenus(prev => ({
            ...prev,
            [menu]: !prev[menu]
        }));
    };

    const handleParentClick = (e, resource) => {
        e.preventDefault();
        redirect('list', `/${resource.name}`);
        if (onMenuClick) {
            onMenuClick();
        }
    };

    const handleExpandClick = (e, label) => {
        e.stopPropagation();
        handleToggle(label);
    };

    const renderMenuItem = (resource) => {
        // Handle submenu items
        if (resource.options && resource.options.subMenu) {
            const subMenuItems = menuStructure[resource.options.menuLabel] || [];
            const isExpanded = expandedMenus[resource.options.menuLabel];

            return (
                <div key={resource.name}>
                    <StyledMenuItem>
                        <MenuItemLink
                            to={`/${resource.name}`}
                            primaryText={resource.options.menuLabel}
                            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                            onClick={(e) => handleParentClick(e, resource)}
                            sidebarIsOpen={open}
                            sx={{ flex: 1 }}
                        />
                        <StyledExpandIcon 
                            onClick={(e) => handleExpandClick(e, resource.options.menuLabel)}
                        >
                            {isExpanded ? <ExpandLess /> : <ExpandMore />}
                        </StyledExpandIcon>
                    </StyledMenuItem>
                    {isExpanded && subMenuItems.map(subItem => (
                        <StyledSubMenuItem key={subItem.name}>
                            <MenuItemLink
                                to={`/${subItem.name}`}
                                primaryText={subItem.options.menuLabel}
                                leftIcon={subItem.icon ? <subItem.icon /> : <DefaultIcon />}
                                onClick={onMenuClick}
                                sidebarIsOpen={open}
                            />
                        </StyledSubMenuItem>
                    ))}
                </div>
            );
        }

        // Don't render items that are part of a submenu at root level
        if (resource.options && resource.options.parent) {
            return null;
        }

        // Regular menu items
        return (
            <MenuItemLink
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={
                    (resource.options && resource.options.label) ||
                    capitalizeFirstLetter(resource.name)
                }
                leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
        );
    };

    return (
        <div>
            <DashboardMenuItem 
                onClick={onMenuClick} 
                sidebarIsOpen={open}
                sx={{ marginTop: '20px' }}
            />
            {menuStructure.root && menuStructure.root.map(renderMenuItem)}
            {isXSmall && logout}
        </div>
    );
};

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export default Menu;
