import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    BooleanInput,
    required,
    SaveButton,
    TopToolbar,
    useRecordContext,
    useNotify,
    useRedirect,
    Toolbar,
    useRefresh
} from 'react-admin';
import { orgsFields } from '../orgs.model';
import { validatePhone } from '../../utils/validation.js';
import ProviderRoleOrderedList from '../../components/ProviderRoleOrderedList';

const EditToolbar = () => (
    <Toolbar>
        <SaveButton label="SAVE" />
    </Toolbar>
);

const OrgTitle = () => {
    const record = useRecordContext();
    return record ? `Organization ${ record[ orgsFields.orgName ] }` : '';
};

const OrgEdit = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onSuccess = () => {
        notify('Organization updated successfully');
        refresh();
        redirect('list', 'organizations');
    };

    const onError = (error) => {
        notify(error?.reason || error?.message || 'Error updating the organization', { type: 'error' });
    };

    return (
        <Edit
            title={<OrgTitle />}
            mutationMode="pessimistic"
            mutationOptions={{
                onSuccess,
                onError
            }}
        >
            <SimpleForm toolbar={<EditToolbar />}>
                <TextInput
                    source={orgsFields.orgName}
                    label='Name'
                    validate={required()}
                    sx={{ width: 400 }}
                />
                <TextInput
                    source={orgsFields.orgNursePhone}
                    label='Nurse Phone'
                    validate={validatePhone()}
                    sx={{ width: 400 }}
                />
                <TextInput
                    source={orgsFields.clientGroupId}
                    label="Healthie Client Group ID"
                    sx={{ width: 400 }}
                />
                <TextInput
                    source={orgsFields.payerId}
                    label="Payer ID"
                    sx={{ width: 400 }}
                />
                <BooleanInput
                    source={orgsFields.contentLibraryEnabled} 
                    label="Content Library Enabled"
                    sx={{ width: 400 }} 
                />
                <BooleanInput
                    source={orgsFields.medicaidIdRegistrationQuestionEnabled}
                    label="Medicaid ID Registration Question Enabled"
                    sx={{ width: 400 }}
                />
                <BooleanInput
                    source={orgsFields.chatEnabled}
                    label="Chat Enabled"
                    sx={{ width: 400 }}
                />
                <BooleanInput
                    source={orgsFields.enrollmentCodeRequired}
                    label="Enrollment Code Required"
                    sx={{ width: 400 }}
                />
                <BooleanInput
                    source={orgsFields.requiresWicId}
                    label="WIC ID Required"
                    sx={{ width: 400 }}
                />
                <ProviderRoleOrderedList
                    rolesFieldName={orgsFields.roleIds}
                    sx={{ width: 400 }}
                />
            </SimpleForm>
        </Edit>
    );
};

export default OrgEdit;
