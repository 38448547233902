import React from 'react';
import { 
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    useRecordContext
} from 'react-admin';
import { RESOURCE_NAME as PROVIDER_ROLES_RESOURCE } from '../../../roles/provider.roles.dataProvider';
import { rolesFields } from '../../../roles/roles.model';
import { codesFields } from '../../codes.model';

const SupportedRoles = (props) => {
    const record = useRecordContext(props);
    
    if (!record) return null;
    
    const roleIds = record[codesFields.roleIds];
    if (!roleIds || roleIds.length === 0) {
        return 'n/a';
    }

    return (
        <ReferenceArrayField 
            label="Supported Roles" 
            reference={PROVIDER_ROLES_RESOURCE} 
            source={codesFields.roleIds}
        >
            <SingleFieldList linkType={false}>
                <ChipField source={rolesFields.roleName} />
            </SingleFieldList>
        </ReferenceArrayField>
    );
};

export default SupportedRoles;