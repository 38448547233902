import React from 'react';
import { Link, useRecordContext } from 'react-admin';
import { RESOURCE_NAME as USERS_RESOURCE } from '../../../users/user.dataProvider';
import ProviderFullName from './providerFullName.component';
import { callsFields } from '../../calls.model';

const ProviderLinkField = ({ label = 'Provider', ...props }) => {
    const record = useRecordContext();
    if (!record || !record[callsFields.providerId]) return null;

    return (
        <Link to={`/${USERS_RESOURCE}/${record[callsFields.providerId]}/show`}>
            <ProviderFullName {...props} />
        </Link>
    );
};

export default ProviderLinkField;
