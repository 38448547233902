import React from 'react';
import { useRecordContext, useGetMany, TextField } from 'react-admin';
import { Box, Chip } from '@mui/material';
import { RESOURCE_NAME as PROVIDER_ROLES_RESOURCE } from '../../../roles/provider.roles.dataProvider';
import { userFields } from '../../user.model';
import DefaultPlaceholderField from '../../../material/defPlaceholderField.component';

const placeholderStyle = {
    color: 'rgba(0, 0, 0, 0.38)',
    fontStyle: 'italic'
};

const UserRoleField = ({ label, addLabel = true, ...rest }) => {
    const record = useRecordContext();

    if (!record) {
        return null;
    }

    // If user has provider roles, show them as chips
    if (record.isProvider && record[userFields.providerRoleIds] && record[userFields.providerRoleIds].length > 0) {
        const { data: roles = [], isLoading } = useGetMany(
            PROVIDER_ROLES_RESOURCE,
            { 
                ids: record[userFields.providerRoleIds],
                meta: {
                    cacheLifetime: 5 * 60 * 1000, // 5 minutes cache
                    staleWhileRevalidate: true
                }
            }
        );

        if (isLoading) return null;

        // Create a map of roles by ID for quick lookup
        const rolesMap = roles.reduce((acc, role) => {
            acc[role.id] = role;
            return acc;
        }, {});

        return (
            <div>
                <div>Provider</div>
                <Box>
                    {record[userFields.providerRoleIds].map((roleId) => {
                        const role = rolesMap[roleId];
                        if (!role) return null;
                        return (
                            <Chip 
                                key={roleId}
                                label={role.name}
                                sx={{ mr: 1, mb: 1 }}
                            />
                        );
                    })}
                </Box>
            </div>
        );
    }

    // If no provider roles, just show the role name
    return <DefaultPlaceholderField source={userFields.roleName} wrapField={<TextField/>} label={label} />;
};

export default UserRoleField;

