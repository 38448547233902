import React from 'react';
import { useRecordContext } from 'react-admin';
import YesNoField from '../../../material/yesNoField.component';

const ConditionalYesNoField = ({ source, showIfFieldExists, ...rest }) => {
    const record = useRecordContext();

    if (!record || !source) {
        return null;
    }

    // Check if the field exists in the record
    if (showIfFieldExists && !record[showIfFieldExists]) {
        return <span style={{ color: '#666', fontStyle: 'italic' }}>n/a</span>;
    }

    return <YesNoField source={source} record={record} {...rest} />;
};

export default ConditionalYesNoField;