import React from 'react';
import { TextField, Link, useRecordContext, useGetOne } from 'react-admin';
import { codesFields } from '../../codes.model';
import { RESOURCE_NAME as USERS_RESOURCE } from '../../../users/user.dataProvider';
import { RESOURCE_NAME as PROVIDERS_RESOURCE } from '../../../users/provider.dataProvider';
import { RESOURCE_NAME as PATIENTS_RESOURCE } from '../../../users/patient.dataProvider';
import { RESOURCE_NAME as ADMIN_RESOURCE } from '../../../users/admin.dataProvider';
import { USER_ROLES } from '../../../roles/constants';

const CodeUserInfo = (props) => {
    const record = useRecordContext(props);
    const userId = record?.[codesFields.userId] || record?.user_id;
    
    const { data: userData, isLoading, error } = useGetOne(
        USERS_RESOURCE,
        { id: userId },
        { enabled: !!userId }
    );

    if (!record) {
        return 'n/a';
    }

    const usersCount = record[codesFields.usersCount] || 0;
    if (usersCount === 0) {
        return 'n/a';
    }
    if (usersCount > 1) {
        return `Redeemed ${usersCount} times`;
    }

    const firstName = record[codesFields.userFirstName] || '';
    const lastName = record[codesFields.userLastName] || '';
    const fullName = `${firstName} ${lastName}`.trim();

    if (!userId) {
        return 'n/a';
    }

    if (isLoading) {
        return <span>{fullName || 'Loading...'}</span>;
    }

    if (error) {
        console.warn('Error loading user data:', error);
        return <span>{fullName || 'Unknown User'}</span>;
    }

    // The user data provider's afterRead callback will enrich the record with roleName
    const roleName = userData?.roleName?.toLowerCase();

    // Determine the correct resource based on role
    let resource;
    switch (roleName) {
        case USER_ROLES.PROVIDER:
            resource = PROVIDERS_RESOURCE;
            break;
        case USER_ROLES.PATIENT:
            resource = PATIENTS_RESOURCE;
            break;
        case USER_ROLES.ADMIN:
            resource = ADMIN_RESOURCE;
            break;
        default:
            // If we don't know the role, we can't show a link
            return <span>{fullName || 'Unknown User'}</span>;
    }

    return (
        <Link to={`/${resource}/${userId}/show`}>            
            <span style={{ color: 'var(--ra-palette-primary-main)' }}>
                {fullName || 'Unknown User'}
            </span>
        </Link>
    );
};

CodeUserInfo.defaultProps = {
    addLabel: true,
    label: 'User'
};

export default CodeUserInfo;