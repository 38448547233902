import { useFormContext } from 'react-hook-form';
import React from "react";

export const ConditionalField = ({ condition, children }) => {
    const { getValues } = useFormContext();
    const values = getValues();

    if (!values || !condition(values)) {
        return null;
    }

    return (
        <>
            {children}
        </>
    );
};