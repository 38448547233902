import React from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import { useRecordContext } from 'react-admin';
import { callsFields } from '../../calls.model';

const CallTitle = () => {
    const record = useRecordContext();
    if (!record) return null;

    return (
        <div sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 1
        }}>
            <PhoneIcon sx={{ verticalAlign: 'middle' }}/>
            <span>{record[callsFields.id]}</span>
        </div>
    );
};

export default CallTitle;


///<CallIcon style={{padding: "1px"}}/>

///<CallIcon style={{padding: "1px"}}/>


//<CallIcon style={{paddingLeft: "25"}}/>
        //<span style={{paddingLeft: "15"}} >&nbsp;{`Call queue # ${callId}`}</span>