import * as React from 'react';
import { useInput } from 'react-admin';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { startOfDay, endOfDay } from 'date-fns';
import { FormHelperText, TextField } from '@mui/material';

const CustomDatePicker = props => {
    const {
        field,
        fieldState,
        formState,
        isRequired,
        sx,
        ...rest
    } = useInput(props);

    // Convert the string date to a Date object for the DatePicker
    const dateValue = field.value ? new Date(field.value) : null;

    const datePickerOnChangeHelper = changeValue => {
        if (!changeValue) {
            field.onChange(null);
            return;
        }

        let returnDate = new Date(changeValue);
        
        if (props.useStartOfDay) {
            returnDate = startOfDay(returnDate);
        } else if (props.useEndOfDay) {
            returnDate = endOfDay(returnDate);
        }
        
        field.onChange(returnDate.toISOString());
    };

    return (
        <div className="ra-input">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    {...field}
                    value={dateValue}
                    onChange={datePickerOnChangeHelper}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={props.label}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            required={isRequired}
                            sx={props.sx}
                            fullWidth
                        />
                    )}
                />
            </LocalizationProvider>
            <FormHelperText 
                sx={{ 
                    marginLeft: '14px',
                    marginRight: '14px',
                    marginTop: '3px',
                    marginBottom: 0,
                    minHeight: '1.25rem'
                }}
            >
                <span>​</span>
            </FormHelperText>
        </div>
    );
};

export default CustomDatePicker;