import { parseISO, format, fromUnixTime } from 'date-fns';
import { queuesFields } from '../../queues.model';
import { useNotify } from 'react-admin';
import {
  dateStringFromTimestamp,
  secondsToDurationString,
  timeStringFromTimestamp
} from "../../../utils/dateFormat";

export const queueExporter = async (queues, notify = useNotify()) => {
  // Handle large export download
  if (queues[0]?.url) {
    notify('Downloading queue data...', { type: 'info' });
    try {
      const response = await fetch(queues[0].url);
      queues = await response.json();
      notify('Queue data downloaded successfully', { type: 'info' });
    } catch (error) {
      console.error('Error downloading large export:', error);
      notify('Error downloading queue data', { type: 'error' });
      return;
    }
  }

  notify('Preparing CSV export...', { type: 'info' });

  const formatDate = (dateStr) => {
    if (!dateStr) return '';
    try {
      const date = parseISO(dateStr);
      return isNaN(date.getTime()) ? '' : format(date, 'yyyy-MM-dd');
    } catch (e) {
      return '';
    }
  };

  const queuesForExport = queues.map(queue => {
    const patientCreatedAtTimestamp = queue[queuesFields.patientCreatedAt];
    const formattedPatientCreatedAtTimestamp = patientCreatedAtTimestamp ? format(fromUnixTime(patientCreatedAtTimestamp/1000), 'yyyy-MM-dd') : ""

    const startedAt = queue[queuesFields.startedAt];
    const callStartedAtTime = timeStringFromTimestamp(startedAt);
    const callStartedAtDate = dateStringFromTimestamp(startedAt);

    const acceptedAt = queue[queuesFields.acceptedAt];
    const callAcceptedAtTime = timeStringFromTimestamp(acceptedAt);
    const callAcceptedAtDate = dateStringFromTimestamp(acceptedAt);

    const endedAt = queue[queuesFields.endedAt];
    const callEndedAtTime = timeStringFromTimestamp(endedAt);
    const callEndedAtDate = dateStringFromTimestamp(endedAt);

    const duration = queue[queuesFields.duration]
    const formattedDuration = secondsToDurationString(duration)

    const timeToAnswer = queue[queuesFields.timeToAnswer]
    const formattedTimeToAnswer = secondsToDurationString(timeToAnswer)

    const timeToCancel = queue[queuesFields.timeToCancel]
    const formattedTimeToCancel = secondsToDurationString(timeToCancel)

    return {
      'Id': queue[queuesFields.id],
      'Conversation Id': queue[queuesFields.conversationId],
      'Patient Id': queue[queuesFields.patientId],
      'Patient Fist Name': queue[queuesFields.patientFirstName],
      'Patient Last Name': queue[queuesFields.patientLastName],
      'Patient DOB': formatDate(queue[queuesFields.patientDob]),
      'Patient Phone': queue[queuesFields.patientPhone],
      'Patient Zip': queue[queuesFields.patientZip],
      'Patient Created At': formattedPatientCreatedAtTimestamp,
      'Provider Id': queue[queuesFields.providerId],
      'Provider First Name': queue[queuesFields.providerFirstName],
      'Provider Last Name': queue[queuesFields.providerLastName],
      'Role Id': queue[queuesFields.roleId],
      'Role Name': queue[queuesFields.roleName],
      'Type': queue[queuesFields.type],
      'Language': queue[queuesFields.lang],
      'Status': queue[queuesFields.status],
      'Consultation': queue[queuesFields.consultation],
      'Service': queue[queuesFields.service],
      'Org Codes': queue[queuesFields.orgCodes].map(code => code && code.name),
      'Organization': queue[queuesFields.organizations].map(org => org && org.name),
      'Patient Feedback': queue[queuesFields.patientFeedback],
      'Patient Rating': queue[queuesFields.patientRating],
      'Call Started At Date': callStartedAtDate,
      'Call Started At Time': callStartedAtTime,
      'Call Accepted At Date': callAcceptedAtDate,
      'Call Accepted At Time': callAcceptedAtTime,
      'Call Ended At Date': callEndedAtDate,
      'Call Ended At Time': callEndedAtTime,
      'Duration': formattedDuration,
      'Time To Answer': formattedTimeToAnswer,
      'Time To Cancel': formattedTimeToCancel,
    };
  });

  const fields = [
    "Id",
    "Conversation Id",
    "Type",
    "Language",
    "Status",
    "Consultation",
    "Service",
    "Role Id",
    "Role Name",
    "Patient Id",
    "Patient Fist Name",
    "Patient Last Name",
    "Patient DOB",
    "Patient Phone",
    "Patient Zip",
    "Patient Created At",
    "Patient Feedback",
    "Patient Rating",
    "Org Codes",
    "Organization",
    "Provider Id",
    "Provider First Name",
    "Provider Last Name",
    "Call Started At Date",
    "Call Started At Time",
    "Call Accepted At Date",
    "Call Accepted At Time",
    "Call Ended At Date",
    "Call Ended At Time",
    "Duration",
    "Time To Answer",
    "Time To Cancel",
  ];

  const csvData = fields.join(',') + '\n' +
    queuesForExport.map(queue =>
      fields.map(field => {
        const value = queue[field];
        return value === null || value === undefined ? '' : `"${String(value).replace(/"/g, '""')}"`;
      }).join(',')
    ).join('\n');

  const timestamp = format(new Date(), 'yyyy-MM-dd-HHmmss');
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `queues-${timestamp}.csv`;
  link.click();
  URL.revokeObjectURL(link.href);

  notify('CSV export completed successfully', { type: 'success' });
};