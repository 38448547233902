import * as React from 'react';
import UserIcon from '@mui/icons-material/Group';
import SummaryIcon from '@mui/icons-material/Assessment';
import MembershipCodeIcon from '@mui/icons-material/CardMembership';
import CodeIcon from '@mui/icons-material/Code';
import { Admin, Resource } from 'react-admin';
import { createTheme } from "@mui/material/styles";
import merge from 'lodash/merge';
import Dashboard from '../sdk-dashboard/dashboard.component';
import UserList from '../sdk/users/component/sdk.userList.component';
import UserShow from '../sdk/users/component/sdk.userShow.component';
import CodesList from '../sdk/sdk-codes/component/codesList.component';
import SummaryList from '../sdk/codes-summary/component/summaryList.component';
import CodeShow from '../sdk/sdk-codes/component/codeShow.component';
import DiscountCodesList from '../sdk/discount-codes/component/list.component';
import DiscountCodeShow from '../sdk/discount-codes/component/show.component';

import authProvider from '../auth/authProvider';
import dataProvider from './dataProvider';
import LoginComponent from '../auth/login.component';

import { RESOURCE_NAME as USERS_RESOURCE } from '../sdk/users/sdk.user.dataProvider';
import { RESOURCE_NAME as SDK_CODE_PLAN_RESOURCE } from '../sdk/plans/plans.dataProvider';
import { RESOURCE_NAME as SDK_CODES_SUMMARY_RESOURCE } from '../sdk/codes-summary/summary.dataProvider';
import { RESOURCE_NAME as SDK_CODES_RESOURCE } from '../sdk/sdk-codes/codes.dataProvider';
import { RESOURCE_NAME as US_STATES_RESOURCE } from '../usStates/usStates.dataProvider';
import { RESOURCE_NAME as DISCOUNT_CODES_RESOURCE } from '../sdk/discount-codes/discountCodes.dataProvider';
import { RESOURCE_NAME as PERMISSION_TEMPLATE_RESOURCE } from "../permissionTemplates/permission.template.dataProvider";

let theme = createTheme(merge({}, {
  palette: {
    mode: 'light',
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#F2F3F4",
        },
      },
    },
  },
}));

export const DATE_OPTIONS =  {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
  timeZone: 'Etc/GMT+1'  
};

export default () => (
  <Admin
    customRoutes={customRoutes}
    loginPage={LoginComponent}
    dataProvider={dataProvider}
    authProvider={authProvider}
    dashboard={Dashboard}
    layout={CustomLayout}
    theme={theme}
  >
    <Resource name={USERS_RESOURCE} icon={UserIcon} list={UserList} show={UserShow} />
    <Resource name={SDK_CODES_RESOURCE} options={{ label: 'Membership Codes' }} icon={MembershipCodeIcon} list={CodesList} show={CodeShow} />
    <Resource name={DISCOUNT_CODES_RESOURCE} options={{ label: 'Discount Codes' }} icon={CodeIcon} list={DiscountCodesList} show={DiscountCodeShow}/>
    <Resource name={SDK_CODES_SUMMARY_RESOURCE} options={{ label: 'Codes Summary' }} icon={SummaryIcon} list={SummaryList} />
    {/* Non visible resources required for API calls for necessary data */}
    <Resource name={US_STATES_RESOURCE} />
    <Resource name={SDK_CODE_PLAN_RESOURCE} />
    <Resource name={PERMISSION_TEMPLATE_RESOURCE} />
  </Admin>
);
