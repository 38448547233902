import React from 'react';
import { Link, TextField, useRecordContext } from 'react-admin';
import { RESOURCE_NAME as ORGANIZATIONS_RESOURCE } from '../../organizations.dataProvider';
import { orgsFields } from '../../orgs.model';

const OrganizationLinkField = ({ source, addLabel = true, ...props }) => {
    const record = useRecordContext(props);
    
    if (!record || !record[orgsFields.id]) {
        return 'n/a';
    }

    return (
        <Link to={`/${ORGANIZATIONS_RESOURCE}/${record[orgsFields.id]}/show`}>
            <TextField source={source} {...props} />
        </Link>
    );
};

export default OrganizationLinkField;
