import React from 'react';
import { Card, CardHeader } from '@mui/material';
import styled from 'styled-components';

const VersionWrapper = styled.div`
  text-align: center;
  margin-bottom: 8px;
`;

export default () => (
    <Card>
        <VersionWrapper color="grayDark">
            <CardHeader title="Pacify SDK Admin Dashboard" />
            <div>{import.meta.env.VITE_NAME}: {import.meta.env.VITE_VERSION}</div>
        </VersionWrapper>
    </Card>
);
