import React from 'react';
import { Link, TextField, useRecordContext } from 'react-admin';
import { RESOURCE_NAME as CALLS_RESOURCE } from '../../calls.dataProvider';
import { callsFields } from '../../calls.model';

const CallLinkField = ({ source = callsFields.id, label = 'Id', ...props }) => {
    const record = useRecordContext();
    if (!record || !record[source]) return null;

    return (
        <Link to={`/${CALLS_RESOURCE}/${record.id}/show`}>
            <TextField source={source} sx={{ color: 'primary.main' }} {...props}/>
        </Link>
    );
};

export default CallLinkField;
