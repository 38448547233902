import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    TextInput,
    SelectInput,
    ShowButton,
    SingleFieldList,
    ArrayField,
    downloadCSV,
    useListContext,
    TopToolbar,
    ListBase
} from 'react-admin';
import { userFields, userOrgCodeFields, userGiftFields, userRelativeFields } from '../sdk.user.model';
import DefaultPlaceholderField from '../../../material/defPlaceholderField.component';
import { DATE_OPTIONS } from '../../../sdk-app/app.component';
import UserLinkField from '../../../users/component/fields/userLinkField.component';
import SdkCodeLinkField from './fields/codeLinkList.component';
import UserIsActiveField from './fields/userIsActiveField.component';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import moment from 'moment';
import YesNoField from '../../../material/yesNoField.component';
import dateUtils from '../../../utils/dateUtils';

const exporter = users => {
    const usersForExport = users.map(user => {
        
        let sortedRelatives = user[userFields.relatives].sort(function (a, b) { return dateUtils.momentFromDbDateString(a[userRelativeFields.dob]) - dateUtils.momentFromDbDateString(b[userRelativeFields.dob])});
        let youngestChildDOB = sortedRelatives && sortedRelatives[sortedRelatives.length-1] && sortedRelatives[sortedRelatives.length-1][userRelativeFields.dob];
        // console.log("youngest: " + youngestChildDOB);
        let familyMembers = "";
        sortedRelatives.forEach(userRelative => {
            if (userRelative && userRelative[userRelativeFields.dob]) {
                
                familyMembers = familyMembers + userRelative[userRelativeFields.name] + ", " + dateUtils.dateStringFromDbDateString(userRelative[userRelativeFields.dob]) + ". ";
            }
        });
        // console.log("exporter user ::: ", user);
        user['Id'] = user[userFields.id];
        user['Email'] = user[userFields.email];
        user['First name'] = user[userFields.firstName];
        user['Last name'] = user[userFields.lastName];
        user['Phone'] = user[userFields.phoneNumber];
        user['Role'] = user[userFields.roleName];
        user['Dob'] = dateUtils.dateStringFromDbDateString(user[userFields.dob]);
        user['Due date'] = dateUtils.dateStringFromDbDateString(user[userFields.dueDate]);
        user['Youngest child dob'] = dateUtils.dateStringFromDbDateString(youngestChildDOB);
        user['Lang'] = user[userFields.lang];
        user['Recurly active'] = user[userFields.recurlyActive];
        user['Is active'] = user[userFields.is_active_sdk_user];
        user['Organization'] = user[userFields.orgName];
        user['Organization code'] = user[userFields.orgCodes].map((code) => code[userOrgCodeFields.name]).join();
        user['Gift'] = user[userFields.gifts].map((code) => code[userGiftFields.code]).join();
        let userCreatedAt = user[userFields.createdAt];
        if (userCreatedAt) {            
            user['Created at date'] =  dateUtils.dateStringFromTimestamp(userCreatedAt);
            user['Created at time'] = dateUtils.timeStringFromTimestamp(userCreatedAt);
            user['Days since account creation'] = moment(new Date()).diff(moment(userCreatedAt), 'days');
        }
        user['State'] = user[userFields.usState];
        user['Push enabled'] = user[userFields.pushEnabled];
        user['Share feedback'] = user[userFields.shareFeedback];
        user['Family members'] = familyMembers;
        return user;
    });

    const csv = convertToCSV({
        data: usersForExport,
        fields: ['Id', 'Email', 'First name', 'Last name', 'Phone', 'Role', 'Dob', 'Due date', 'Youngest child dob', 'Lang', 
        'Recurly active', 'Is active', 'Organization', 'Organization code', 'Gift', 'Created at date', 'Created at time', 
        'Days since account creation', 'State', 'Push enabled', 'Share feedback', 'Family members']
    });
    downloadCSV(csv, 'users-' + moment(new Date()).format('YYYY-MM-DD').toString());
};

const userFilters = [
    <TextInput label="Email" source={userFields.email} alwaysOn />,
    <TextInput label="First Name" source={userFields.firstName} />,
    <TextInput label="Last Name" source={userFields.lastName} />,
    <SelectInput 
        label="Is Active"
        source={userFields.is_active_sdk_user}
        choices={[
            { id: 0, name: 'No' },
            { id: 1, name: 'Yes' }
        ]}
    />,
    <TextInput label="Code" source={userFields.orgCodes} />
];

const ListActions = () => {
    const { 
        resource,
        displayedFilters,
        filterValues,
        showFilter
    } = useListContext();
    
    return (
        <TopToolbar>
            {/* Add any custom actions here */}
        </TopToolbar>
    );
};

const UserList = () => (
    <List 
        filters={userFilters}
        actions={<ListActions />}
        exporter={exporter}
        bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
        title="All users"
    >
        <Datagrid rowClick="show">
            <UserLinkField source={userFields.id} label="Id" />
            <DefaultPlaceholderField source={userFields.email} wrapField={<TextField/>} label="Email" />
            <DefaultPlaceholderField source={userFields.firstName} wrapField={<TextField/>} label="First Name" />
            <DefaultPlaceholderField source={userFields.lastName} wrapField={<TextField/>} label="Last Name" />
            <DefaultPlaceholderField source={userFields.createdAt} wrapField={<DateField/>} options={DATE_OPTIONS} label="Date Created" />
            <YesNoField source={userFields.is_active_sdk_user} label="Is Active" addLabel />
            <SdkCodeLinkField label="Code" addLabel />
            <ShowButton />
        </Datagrid>
    </List>
);

export default UserList;
