import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { versionsFields } from '../../versions.model';

const VersionAppType = ({ label = 'App Type' }) => {
    const record = useRecordContext();
    if (!record) return null;
    
    let appType;
    switch (record[versionsFields.appType]) {
        case 1:
            appType = "Patient";
            break;
        case 2:
            appType = "Provider";
            break;     
        default:
            appType = "Not Defined";
            break;
    }

    return (
        <div sx={{ 
            display: 'flex',
            alignItems: 'center',
            fontSize: '0.875rem'
        }}>
            {appType}
        </div>
    );
};

export default VersionAppType;
