import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    DateField,
    TopToolbar,
    Button,
    useRecordContext,
    useRedirect
} from 'react-admin';
import { callsFields } from '../calls.model';
import DefaultPlaceholderField from '../../material/defPlaceholderField.component';
import YesNoField from '../../material/yesNoField.component';
import CallStatus from './fields/callStatus.component';
import CallProvChartShow from './fields/callProvChartShow.component';
import PatientLinkField from './fields/patientLink.component';
import ProviderLinkField from './fields/providerLink.component';
import CallTimeLabel from './fields/callTimeLabel.component';
import ProviderEndReason from './fields/providerEndReason.component';
import PatientEndReason from './fields/patientEndReason.component';
import CallTitle from './fields/callTitle.component';
import { DATE_TIME_OPTIONS } from '../../app/app.component';
import EditIcon from '@mui/icons-material/Edit';

const EditChartButton = () => {
    const record = useRecordContext();
    const redirect = useRedirect();
    if (!record) return null;

    // Don't show button if call is non-clinical
    if (record[callsFields.nonClinical]) return null;

    // Don't show button if there's no PHI data
    let phi = null;
    try {
        if (record[callsFields.phi]) {
            phi = JSON.parse(record[callsFields.phi]);
        }
    } catch (e) {
        return null;
    }

    if (!phi || !phi.feedbackFormData) return null;

    // For default chart type, check if there's any content
    if (!phi.type) {
        const { feedbackFormData } = phi;
        const hasContent = feedbackFormData.recommendations || feedbackFormData.complaint || feedbackFormData.follow_up;
        if (!hasContent) return null;
    }

    return (
        <Button
            label="Edit Chart"
            onClick={() => redirect(`/calls/${record.id}/edit-chart`)}
            children={<EditIcon />}
        />
    );
};

const ShowActions = () => (
    <TopToolbar>
        <EditChartButton />
    </TopToolbar>
);

const CallShow = () => {
    return (
        <Show 
            title={<CallTitle />}
            actions={<ShowActions />}
        >
            <SimpleShowLayout>
                <DefaultPlaceholderField source={callsFields.id} wrapField={<TextField/>} label="Id" />
                <DefaultPlaceholderField source={callsFields.groupName} wrapField={<TextField/>} label="Type" />
                <DefaultPlaceholderField source={callsFields.lang} wrapField={<TextField/>} label="Lang" />
                <CallStatus source={callsFields.status} label="Status" />
                <CallProvChartShow label="Provider Chart" />
                <YesNoField source={callsFields.nonClinical} label="No Clinical Content" />
                <PatientLinkField label="Patient" />
                <DefaultPlaceholderField source={callsFields.patientRating} wrapField={<TextField/>} label="Patient Rating" />
                <DefaultPlaceholderField source={callsFields.patientFeedback} wrapField={<TextField/>} label="Patient Feedback" />
                <ProviderLinkField label="Provider" />
                <DefaultPlaceholderField source={callsFields.providerRating} wrapField={<TextField/>} label="Provider Rating" />
                <DefaultPlaceholderField source={callsFields.providerFeedback} wrapField={<TextField/>} label="Provider Feedback" />
                <DefaultPlaceholderField source={callsFields.callStartedAt} options={DATE_TIME_OPTIONS} wrapField={<DateField/>} label="Started At" />
                <DefaultPlaceholderField source={callsFields.acceptedAt} wrapField={<DateField/>} options={DATE_TIME_OPTIONS} label="Accepted At" />
                <DefaultPlaceholderField source={callsFields.endedAt} wrapField={<DateField/>} options={DATE_TIME_OPTIONS} label="Ended At" />
                <CallTimeLabel source={callsFields.timeToAnswer} label="Time to Answer" />
                <CallTimeLabel source={callsFields.timeToCancel} label="Time to Cancel" />
                <DefaultPlaceholderField source={callsFields.service} wrapField={<TextField/>} label="Service" />
                <ProviderEndReason label="Provider End Reason" />
                <PatientEndReason label="Patient End Reason" />
                <YesNoField source={callsFields.introCall} label="Intro Call" />
            </SimpleShowLayout>
        </Show>
    );
};

export default CallShow;
