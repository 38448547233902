import { useEffect, useState, useMemo } from 'react';
import { useInput, useGetList, useGetMany } from 'react-admin';
import {
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { RESOURCE_NAME as PROVIDER_ROLES_RESOURCE } from '../../roles/provider.roles.dataProvider';

export const useProviderRoles = (record, rolesFieldName) => {
  const [orderedProviderRoles, setOrderedProviderRoles] = useState([]);
  
  const {
    field: { value, onChange }
  } = useInput({
    source: rolesFieldName,
    defaultValue: record?.[rolesFieldName] || []
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // Debug log for initial data
  // console.log('Record:', record);
  // console.log('RolesFieldName:', rolesFieldName);
  // console.log('Role IDs from record:', record?.[rolesFieldName]);

  const { data: getListData, isLoading: isLoadingList, error: listError } = useGetList(
    PROVIDER_ROLES_RESOURCE,
    { 
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'id', order: 'ASC' }
    }
  );

  // Debug log for list data
  // console.log('GetList Data:', getListData);
  // console.log('GetList Loading:', isLoadingList);
  // console.log('GetList Error:', listError);

  // Use value from the form field instead of record directly
  const roleIds = value;
  const validRoleIds = useMemo(() => {
    const ids = roleIds || [];
    return Array.isArray(ids) ? ids : [];
  }, [roleIds]);

  // Debug log for role IDs
  // console.log('Valid Role IDs:', validRoleIds);

  const { data: getManyData, isLoading: isLoadingMany, error: manyError } = useGetMany(
    PROVIDER_ROLES_RESOURCE,
    { ids: validRoleIds }
  );

  // Debug log for getManyData
  // console.log('GetMany Data:', getManyData);
  // console.log('GetMany Loading:', isLoadingMany);
  // console.log('GetMany Error:', manyError);

  useEffect(() => {
    if (!getManyData) return;

    const roleMap = getManyData.reduce((acc, role) => {
      acc[role.id] = role;
      return acc;
    }, {});
    
    // console.log('Role Map:', roleMap);
    
    const filteredRoles = validRoleIds.map(roleId => {
      const role = roleMap[roleId];
      if (!role) {
        // console.log('Missing role for ID:', roleId);
        return {
          id: roleId,
          name: "Deleted Role",
          isDeleted: true,
        };
      }
      return { ...role, isDeleted: false };
    });
    
    // console.log('Filtered Roles:', filteredRoles);
    setOrderedProviderRoles(filteredRoles);
  }, [getManyData, validRoleIds]);

  const setOrderedRoles = (roles) => {
    const newRoleIds = roles.map(role => role.id);
    onChange(newRoleIds);
    setOrderedProviderRoles(roles);
  };

  return {
    orderedProviderRoles,
    setOrderedRoles,
    sensors,
    getListData,
    isLoading: isLoadingList || isLoadingMany,
    error: listError || manyError
  };
}; 