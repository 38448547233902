import React from 'react';
import { Card, CardContent } from '@mui/material';
import PinpointCsvUploader from './PinpointCsvUploader.component';

const PinpointEdit = () => {
    return (
        <Card>
            <CardContent>
                <PinpointCsvUploader />
            </CardContent>
        </Card>
    );
};

export default PinpointEdit;
