import React from 'react';
import { Link, useRecordContext } from 'react-admin';
import { queuesFields } from "../../queues.model";
import { RESOURCE_NAME as PATIENT_RESOURCE_NAME} from "../../../users/patient.dataProvider";
import EmptyField from "@/material/emptyField.component";
import {RESOURCE_NAME} from "@/organizations/organizations.dataProvider";

const OrganizationLinkField = ({ source }) => {
  const record = useRecordContext();

  if (!record || !source) {
    return null;
  }

  const value = record[source];
  if (!value || value.length === 0) {
    return <EmptyField />;
  }

  return (
    <>
      {
        value.map((org, index) => (
          <Link key={index} to={`/${RESOURCE_NAME}/${org.id}/show`}>
            {org.name}
            {" "}
          </Link>
        ))
      }
    </>
  );
};

export default OrganizationLinkField;
