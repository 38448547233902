import {
    GET_LIST,
    GET_MANY,
} from 'react-admin';
import queryString from 'query-string';

const API_URL = import.meta.env.VITE_SDK_API_URL;

export const RESOURCE_NAME = "sdk/codes_summary";

const providerRequestToHttpRequest = (requestType, requestParams) => {
    switch (requestType) {
        case GET_LIST: 
        // console.log("Codes plans GET_LIST ::: ");
            return composeGetSummaryListRequest(requestParams);
        case GET_MANY: {
            // console.log("Codes plans GET_MANY ::: ");
            const query = {
                filter: JSON.stringify({ id: requestParams.ids }),
            };
            return { url: `${API_URL}/${RESOURCE_NAME}?${queryString.stringify(query)}` };
        }
        default:
            throw new Error(`Unsupported fetch action type for "SDK_CODES_PLANS" resource ${requestType}`);
    }
};

const composeGetSummaryListRequest = (requestParams) => {
    // console.log("composeGetSummaryListRequest requestParams::: ", requestParams);
    const { page, perPage } = requestParams.pagination;
    const { field, order } = requestParams.sort;
    let prepareFilters = () => {
        let requestFilters = requestParams.filter;
        if (requestFilters === undefined) {
            return {};
        }
        let createDateBetweenFromFilter = (name, value) => ({ name, comparison: "betweenFrom", value });
        let createDateBetweenToFilter = (name, value) => ({ name, comparison: "betweenTo", value });

        let resultFilters = [];
       
        // append "redeemed at" filter
        if (requestFilters.hasOwnProperty('redeemed')) {
            let between = requestFilters['redeemed'];
            // console.log("redeemed at from between: ", between);
            if (between.gte != null) {
                // console.log("redeemed at from between.gte: ", between.gte);
                resultFilters.push(createDateBetweenFromFilter('activated_at', between.gte));
            }
            if (between.lte != null) {
                // console.log("redeemed at to between.lte: ",  between.lte);
                resultFilters.push(createDateBetweenToFilter('activated_at', between.lte));
            }
        }

        // append "created at" filter
        if (requestFilters.hasOwnProperty('created')) {
            let between = requestFilters['created'];
            // console.log("created at from endedBetween: ", between);
            if (between.gte != null) {
                // console.log("created at from between.gte: ", between.gte);
                resultFilters.push(createDateBetweenFromFilter('created_at', between.gte));
            }
            if (between.lte != null) {
                // console.log("created at to between.lte: ", between.lte);
                resultFilters.push(createDateBetweenToFilter('created_at', between.lte));
            }
        }
        return resultFilters;
    };

    const queryParams = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage]),
        filters: JSON.stringify(prepareFilters()),
    };
    return { url: `${API_URL}/${RESOURCE_NAME}?${queryString.stringify(queryParams)}` };
}

const httpResponseToProviderData = (httpResponse, requestType, requestParams) => {
    let { headers, json } = httpResponse;
    switch (requestType) {
        case GET_LIST:
        // console.log("Codes plans json.map(sdkCodePlanItem => sdkCodePlanItem) ::: ", json.map(sdkCodePlanItem => sdkCodePlanItem));
            return {
                data: json.map(sdkCodePlanItem => sdkCodePlanItem),
                total: parseInt(headers.get('content-range').split('/').pop()),
            };
        case GET_MANY:
            let sdkCodePlanIdsToGet = requestParams.ids;
            return {
                data: json.filter(sdkCodePlanItem => sdkCodePlanIdsToGet.includes(sdkCodePlanItem.id))
            };
        default:
            throw new Error(`Unsupported fetch action type ${requestType}`);
    }
};

export default {
    resource: RESOURCE_NAME,
    providerInterface: {
        providerRequestToHttpRequest,
        httpResponseToProviderData
    },
};
