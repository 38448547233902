import { GET_LIST, GET_ONE } from 'react-admin';
import queryString from 'query-string';
import { queuesFields } from "./queues.model";

const API_URL = import.meta.env.VITE_API_URL;

export const RESOURCE_NAME = "queues";

const providerRequestToHttpRequest = (requestType, requestParams) => {
    switch (requestType) {
        case GET_LIST:
            return composeGetQueuesListRequest(requestParams);
        case GET_ONE:
            return { url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}` };
        default:
            throw new Error(`Unsupported fetch action type ${requestType}`);
    }
};

const composeGetQueuesListRequest = (requestParams) => {
    const { page, perPage } = requestParams.pagination;
    const { field, order } = requestParams.sort;
    let prepareFilters = () => {
        let requestFilters = requestParams.filter;
        if (requestFilters === undefined) {
            return {};
        }
        let createEqFilter = (name, value) => ({ name, comparison: "eq", value });
        let createNeqFilter = (name, value) => ({ name, comparison: "neq", value });
        let createContainsFilter = (name, value) => ({ name, comparison: "contains", value });
        let createDateBetweenFromFilter = (name, value) => ({ name, comparison: "betweenFrom", value });
        let createDateBetweenToFilter = (name, value) => ({ name, comparison: "betweenTo", value });

        let resultFilters = [];
        // append "call id" filter
        if (requestFilters.hasOwnProperty(queuesFields.id)) {
            resultFilters.push(createContainsFilter('id', requestFilters[queuesFields.id]));
        }
        // append "patient id" filter
        if (requestFilters.hasOwnProperty(queuesFields.patientId)) {
            resultFilters.push(createContainsFilter('patientId', requestFilters[queuesFields.patientId]));
        }
        // append "patient first name" contains
        if (requestFilters.hasOwnProperty(queuesFields.patientFirstName)) {
            resultFilters.push(createContainsFilter('patientFirstName', requestFilters[queuesFields.patientFirstName]));
        }
        // append "patient last name" contains
        if (requestFilters.hasOwnProperty(queuesFields.patientLastName)) {
            resultFilters.push(createContainsFilter('patientLastName', requestFilters[queuesFields.patientLastName]));
        }
        // append "provider id" contains
        if (requestFilters.hasOwnProperty(queuesFields.providerId)) {
            resultFilters.push(createContainsFilter('providerId', requestFilters[queuesFields.providerId]));
        }
        // append "provider first name" contains
        if (requestFilters.hasOwnProperty(queuesFields.providerFirstName)) {
            resultFilters.push(createContainsFilter('providerFirstName', requestFilters[queuesFields.providerFirstName]));
        }
        // append "provider last name" contains
        if (requestFilters.hasOwnProperty(queuesFields.providerLastName)) {
            resultFilters.push(createContainsFilter('providerLastName', requestFilters[queuesFields.providerLastName]));
        }
        // append "orgId" filter
        if (requestFilters.hasOwnProperty(queuesFields.orgId)) {
            resultFilters.push(createEqFilter('patientOrgId', requestFilters[queuesFields.orgId]));
        }
        // append "call status" filter
        if (requestFilters.hasOwnProperty(queuesFields.status)) {
            resultFilters.push(createContainsFilter('status', requestFilters[queuesFields.status]));
        }
        // append "type" filter
        if (requestFilters.hasOwnProperty(queuesFields.type)) {
            resultFilters.push(createEqFilter('type', requestFilters[queuesFields.type]));
        }
        // append "created at" filter
        if (requestFilters.hasOwnProperty('startedBetween')) {
            let between = requestFilters['startedBetween'];
            // console.log("started at from startedBetween: ", between)
            if (between.gte != null) {
                // console.log("started at from startedBetween.gte: ", between.gte)
                resultFilters.push(createDateBetweenFromFilter('startedAtFrom', between.gte));
            }
            if (between.lte != null) {
                // console.log("started at to startedBetween.lte: ",  between.lte)
                resultFilters.push(createDateBetweenToFilter('startedAtTo', between.lte));
            }
        }
        // append "ended at" filter
        if (requestFilters.hasOwnProperty('endedBetween')) {
            let between = requestFilters['endedBetween'];
            // console.log("ended at from endedBetween: ", between)
            if (between.gte != null) {
                // console.log("ended at from between.gte: ", between.gte)
                resultFilters.push(createDateBetweenFromFilter('endedAtFrom', between.gte));
            }
            if (between.lte != null) {
                // console.log("ended at to between.lte: ", between.lte)
                resultFilters.push(createDateBetweenToFilter('endedAtTo', between.lte));
            }
        }
        // append "service" filter
        if (requestFilters.hasOwnProperty(queuesFields.service)) {
            resultFilters.push(createEqFilter(queuesFields.service, requestFilters[queuesFields.service]));
        }
        if (requestFilters.hasOwnProperty('service_neq')) {
            const valueToExclude = Object.keys(requestFilters['service_neq'])[0];
            if (requestFilters['service_neq'][valueToExclude]) {
                resultFilters.push(createNeqFilter(queuesFields.service, valueToExclude));
            }
        }
        return resultFilters;
    };

    const queryParams = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage]),
        filters: JSON.stringify(prepareFilters()),
        export: requestParams.export
    };

    return { url: `${API_URL}/${RESOURCE_NAME}?${queryString.stringify(queryParams)}` };
}

const httpResponseToProviderData = (httpResponse, requestType, requestParams) => {
    const { headers, json } = httpResponse;

    switch (requestType) {
        case GET_LIST:
            if (json['url'] != null) { // Large Export Hack -- a url is returned to a presigned s3 file for download, handled downstream.
              return { data: [{ id: 9999999, url: json['url']}], total: 9999999 }; // Have to use this format as part of the hack to slip through react-admin validation
            }
            return {
                data: json.map(x => x),
                total: parseInt(headers.get('content-range').split('/').pop()),
            };
        default:
            return { data: json };
    }
};

export default {
    resource: RESOURCE_NAME,
    providerInterface: {
        providerRequestToHttpRequest,
        httpResponseToProviderData
    },
};
