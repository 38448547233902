import React from 'react';
import { Layout } from 'react-admin';
import { makeStyles } from '@mui/styles';
import CustomMenu from './customMenu';
import { CustomAppBar } from './customAppBar';

const useStyles = makeStyles((theme) => ({
    layout: {
        '& .RaLayout-content': {
            paddingBottom: '50px', // Space for version
        }
    },
    version: {
        color: 'rgba(0, 0, 0, 0.6)',
        textAlign: 'right',
        padding: '16px',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        marginTop: 'auto',
        background: '#f5f5f5',
    }
}));

const CustomLayout = (props) => {
    const classes = useStyles();
    
    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Layout
                {...props}
                className={classes.layout}
                menu={CustomMenu}
                appBar={CustomAppBar}
            />
            <div className={classes.version}>
                {import.meta.env.VITE_VERSION}
            </div>
        </div>
    );
};

export default CustomLayout;