import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    NumberInput,
    SelectInput,
    BooleanInput,
    required,
    SaveButton,
    TopToolbar,
    useRecordContext,
    useNotify,
    useRedirect
} from 'react-admin';
import { Box } from '@mui/material';

const notificationTypeChoices = [
    { id: 'DOULA', name: 'Doula' },
    { id: 'LC', name: 'LC' },
];

const milestoneTypeChoices = [
    { id: 'prenatal', name: 'Prenatal' },
    { id: 'postnatal', name: 'Postnatal' },
];

const HiddenInput = ({ source, defaultValue }) => (
    <input type="hidden" name={source} defaultValue={defaultValue} />
);

const EditActions = () => (
    <TopToolbar>
        <SaveButton />
    </TopToolbar>
);

const NotificationTitle = () => {
    const record = useRecordContext();
    return record ? `Notification ${record.title}` : '';
};

const inputSx = { width: 400 };

export const PregnancyMilestoneNotificationEdit = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify('Notification updated successfully');
        redirect('list', 'pregnancy-milestone-notifications');
    };

    return (
        <Edit 
            title={<NotificationTitle />} 
            mutationMode="pessimistic"
            mutationOptions={{ onSuccess }}
        >
            <SimpleForm>
                <Box sx={{ maxWidth: 800, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextInput source="title" validate={required()} sx={inputSx} />
                    <TextInput source="body" validate={required()} multiline rows={4} sx={inputSx} />
                    <TextInput source="liquid_body" multiline rows={4} sx={inputSx} />
                    <NumberInput source="days_from_due_date" validate={required()} sx={inputSx} />
                    <TextInput source="scheduled_time" validate={required()} sx={inputSx} />
                    <SelectInput 
                        source="notification_type" 
                        choices={notificationTypeChoices}
                        validate={required()}
                        sx={inputSx}
                    />
                    <SelectInput 
                        source="milestone_type" 
                        choices={milestoneTypeChoices}
                        validate={required()}
                        sx={inputSx}
                    />
                    <TextInput source="deep_link" sx={inputSx} />
                    <HiddenInput source="push_message_type" defaultValue="milestone" />
                    <HiddenInput source="notification_channel" defaultValue="" />
                    <HiddenInput source="time_to_live" defaultValue={86400} />
                    <HiddenInput source="android_channel_id" defaultValue="" />
                    <HiddenInput source="android_accent_color" defaultValue="" />
                    <HiddenInput source="ios_sound" defaultValue="default" />
                    <BooleanInput source="is_active" defaultValue={true} sx={inputSx} />
                </Box>
            </SimpleForm>
        </Edit>
    );
}; 