import React from 'react';
import { callsFields } from '../../calls.model';
import { Link, useRecordContext } from 'react-admin';
import { RESOURCE_NAME as CALLS_RESOURCE } from '../../calls.dataProvider';

const CallProvChartList = ({ label = 'Provider Chart' }) => {
  const record = useRecordContext();
  if (!record) return null;

  let divStyle = {
      display: 'flex',
      verticalAlign: 'bottom'
  };

  if (record[callsFields.nonClinical]) {
    return (
      <div style={divStyle}>
        <span>No clinical content</span>
      </div>
    );
  }

  let phi = null;
  try {
    if (record[callsFields.phi]) {
      phi = JSON.parse(record[callsFields.phi]);
    }
  } catch (e) {
    console.error('Error parsing phi:', e);
  }

  if (!phi || !phi.feedbackFormData || (phi.feedbackFormData.recommendations === "" && phi.feedbackFormData.complaint === "" && phi.feedbackFormData.follow_up === "")) {
    return (
      <div style={divStyle}>
        <span>No chart</span>
      </div>
    )
  }

  return (
    <Link to={`/${CALLS_RESOURCE}/${record.id}/show`}>
      <span>Show chart</span>
    </Link>
  );
};

export default CallProvChartList;