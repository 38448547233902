import React from 'react';
import { useRecordContext, DateField } from 'react-admin';

const placeholderStyle = { color: '#666', fontStyle: 'italic', fontSize: 14 };

const DefaultPlaceholderField = (props) => {
    const { source, wrapField, placeholder = 'n/a', addLabel, ...rest } = props;
    const record = useRecordContext(props);

    if (!record || !source) {
        return null;
    }

    const value = record[source];
    const isEmpty = value === null || value === undefined || 
                   (Array.isArray(value) && value.length === 0) ||
                   value === '';

    if (isEmpty) {
        // Special handling for DateField to prevent "Invalid Date"
        if (wrapField.type === DateField) {
            return <span style={placeholderStyle}>{placeholder}</span>;
        }

        // Create a new record with the placeholder value
        const recordWithPlaceholder = {
            ...record,
            [source]: placeholder
        };
        
        const element = React.cloneElement(wrapField, {
            source,
            record: recordWithPlaceholder,
            sx: { ...placeholderStyle },  // Use sx instead of style for MUI components
            ...rest
        });
        return element;
    }

    const element = React.cloneElement(wrapField, {
        source,
        record,
        ...rest
    });
    return element;
};

export default DefaultPlaceholderField;