import React, { useEffect } from 'react';
import {
    Button,
    CreateButton,
    Datagrid,
    DateField,
    EditButton,
    EmailField,
    ExportButton,
    FilterButton,
    List,
    ShowButton,
    TextField,
    TopToolbar,
    useListContext,
    useNotify,
    useResourceContext,
    useStore,
    useListController
} from 'react-admin';
import DefaultPlaceholderField from '../../material/defPlaceholderField.component';
import YesNoField from '../../material/yesNoField.component';
import { userFields } from '../user.model';
import OrganizationLinkField from './fields/OrganizationLinkField.component';
import UserLinkField from './fields/userLinkField.component';
import UserRoleField from './fields/userRoleField.component';
import { UserSidebarFilters } from './UserSidebarFilters';
import { userExporter } from './utils/userExport';
import { UserFilters } from './utils/userFilters';
import { PATIENT_RESOURCE } from '../constants';
import ClearIcon from '@mui/icons-material/Clear';

const ListActions = () => {
    const { 
        total, 
        filterValues, 
        setFilters,
        displayedFilters
    } = useListContext();
    const notify = useNotify();
    const resource = useResourceContext();
    
    // Only show Create button for resources other than patients
    const isPatient = resource === PATIENT_RESOURCE;
    
    // Check if any filters are active
    const hasActiveFilters = Object.keys(filterValues).length > 0;
    
    // Clear all filters
    const handleClearFilters = () => {
        setFilters({}, displayedFilters);
    };

    return (
        <TopToolbar>
            <FilterButton />
            {hasActiveFilters && (
                <Button
                    label="Clear all filters"
                    onClick={handleClearFilters}
                    startIcon={<ClearIcon />}
                />
            )}
            {!isPatient && <CreateButton />}
            <ExportButton
                disabled={total === 0}
                exporter={(users) => userExporter(users, notify)}
            />
        </TopToolbar>
    );
};

// Store key for tracking the last visited resource
const LAST_RESOURCE_KEY = 'lastVisitedUserResource';

// Default filter values
const defaultFilterValues = { 
    q: '',
    id: '',
    email: '',
    healthie_id: ''
};

const UserListView = () => {
    const resource = useResourceContext();
    const isPatient = resource === PATIENT_RESOURCE;
    const [lastResource, setLastResource] = useStore(LAST_RESOURCE_KEY);
    const listContext = useListController({
        resource,
        filterDefaultValues: defaultFilterValues,
        sort: { field: userFields.id, order: 'DESC' },
        perPage: 25
    });
    
    const { setFilters } = listContext;
    
    // Reset filters when navigating between different user types
    useEffect(() => {
        if (lastResource && lastResource !== resource) {
            // Reset filters to default values
            setFilters(defaultFilterValues, {});
        }
        
        // Update the last resource
        setLastResource(resource);
    }, [resource, lastResource, setLastResource, setFilters]);
    
    return (
        <List
            actions={<ListActions />}
            filters={UserFilters}
            exporter={userExporter} 
            aside={<UserSidebarFilters />}
            perPage={25}
            sort={{ field: userFields.id, order: 'DESC' }}
            filterDefaultValues={defaultFilterValues}
            displayedFilters={{
                q: true,
                id: true,
                email: true,
                healthie_id: true
            }}
            // Add a key based on the resource to force re-render when resource changes
            key={resource}
        >
            <Datagrid rowClick={false} bulkActionButtons={false}>
                <UserLinkField source={userFields.id} label="Id" addLabel />
                <EmailField source={userFields.email} />
                <UserRoleField source={userFields.roleName} />
                <OrganizationLinkField label="Organization" />
                <DefaultPlaceholderField 
                    source={userFields.wicId} 
                    label="WIC ID" 
                    wrapField={<TextField source={userFields.wicId} />} 
                />
                <DefaultPlaceholderField 
                    source={userFields.dob} 
                    label="DOB" 
                    wrapField={<DateField source={userFields.dob} />}
                />
                {/* Only show due date for patients */}
                {isPatient && (
                    <DefaultPlaceholderField 
                        source={userFields.dueDate} 
                        label="Due Date" 
                        wrapField={<DateField source={userFields.dueDate} />}
                    />
                )}
                <DefaultPlaceholderField 
                    source={userFields.lang} 
                    label="Lang" 
                    wrapField={<TextField source={userFields.lang} />}
                /> 
                <YesNoField source={userFields.isActive} label="Active" />
                <YesNoField source={userFields.isDeleted} label="Deleted" />
                <DateField source={userFields.createdAt} label="Created" showTime />
                <DateField source={userFields.updatedAt} label="Updated" showTime />
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    );
};

export default UserListView; 