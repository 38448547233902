export let queuesFields = {
  id: "id",
  patientId: "patient_id",
  patientFirstName: "patient_first_name",
  patientLastName: "patient_last_name",
  patientDob: "patient_dob",
  patientPhone: "patient_phone",
  patientZip: "patient_zip",
  patientCreatedAt: "patient_created_at",
  type: "type",
  lang: "lang",
  status: "status",
  consultation: "consultation",
  service: "service",
  startedAt: "started_at",
  acceptedAt: "accepted_at",
  endedAt: "ended_at",
  providerId: "provider_id",
  providerFirstName: "provider_first_name",
  providerLastName: "provider_last_name",
  orgCodes: "org_codes",
  organizations: "organizations",
  duration: "duration",
  timeToAnswer: "time_to_answer",
  timeToCancel: "time_to_cancel",
  conversationId: "conversation_id",
  patientFeedback: "patient_feedback",
  patientRating: "patient_rating",
  roleName: "role_name",
  roleId: "role_id",
  orgId: "org_id"
};

export const queueStatus = {
  REQUESTED: "Requested",
  ACCEPTED: "Accepted",
  CANCELED: "Canceled",
  COMPLETED: "Completed",
  MISSED: "Missed",
  ERROR: "Error"
}
