import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE
} from 'react-admin';
import queryString from 'query-string';
import {
    discountCodesFields
} from "./discountCodes.model";

const API_URL = import.meta.env.VITE_SDK_API_URL;

export const RESOURCE_NAME = "sdk/discount-codes";

const providerRequestToHttpRequest = (requestType, requestParams) => {
    // console.log("provderRequestToHttpRequest");
    // console.log("requestType: " + requestType);
    // console.log("requestParams: " + JSON.stringify(requestParams));

    switch (requestType) {
        case GET_LIST:
            return composeGetCodesListRequest(requestParams);
        case GET_ONE:
            return {
                url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}`
            };
        case GET_MANY: {
            const query = {
                filter: JSON.stringify({
                    id: requestParams.ids
                }),
            };
            return {
                url: `${API_URL}/${RESOURCE_NAME}?${queryString.stringify(query)}`
            };
        }
        case GET_MANY_REFERENCE: {
            const {
                page,
                perPage
            } = requestParams.pagination;
            const {
                field,
                order
            } = requestParams.sort;
            const query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([(page - 1) * perPage, (page * perPage) - 1]),
                filter: JSON.stringify({
                    ...requestParams.filter,
                    [requestParams.target]: requestParams.id
                }),
            };
            return {
                url: `${API_URL}/${RESOURCE_NAME}?${queryString.stringify(query)}`
            };
        }
        default:
            throw new Error(`Unsupported fetch action type ${requestType}`);
    }
};

const composeGetCodesListRequest = (requestParams) => {
    const {
        page,
        perPage
    } = requestParams.pagination;
    const {
        field,
        order
    } = requestParams.sort;
    let prepareFilters = () => {
        let requestFilters = requestParams.filter;
        if (requestFilters === undefined) {
            return {};
        }

        let createContainsFilter = (name, value) => ({
            name,
            comparison: "contains",
            value
        });
        let createDateBetweenFromFilter = (name, value) => ({
            name,
            comparison: "betweenFrom",
            value
        });
        let createDateBetweenToFilter = (name, value) => ({
            name,
            comparison: "betweenTo",
            value
        });
        let createIsFilter = (name, value) => ({
            name,
            comparison: "is",
            value
        });
        let resultFilters = [];

        // console.log("requestFilters: " + JSON.stringify(requestFilters));
        // append "redeemed" filter
        if (requestFilters.hasOwnProperty(discountCodesFields.appliedAt)) {
            resultFilters.push(createIsFilter('appliedAt', requestFilters[discountCodesFields.appliedAt]));
        }
        // append "deactivated" filter
        if (requestFilters.hasOwnProperty(discountCodesFields.deactivatedAt)) {
            resultFilters.push(createIsFilter('deactivatedAt', requestFilters[discountCodesFields.deactivatedAt]));
        }
        if (requestFilters.hasOwnProperty('redeemed')) {
            let between = requestFilters['redeemed'];
            // console.log("redeemed at from between: ", between)
            if (between.gte != null) {
                // console.log("redeemed at from between.gte: ", between.gte)
                resultFilters.push(createDateBetweenFromFilter('appliedAt', between.gte));
            }
            if (between.lte != null) {
                // console.log("redeemed at to between.lte: ", between.lte)
                resultFilters.push(createDateBetweenToFilter('appliedAt', between.lte));
            }
        }
        // append "created at" filter
        if (requestFilters.hasOwnProperty('created')) {
            let between = requestFilters['created'];
            // console.log("created at from endedBetween: ", between);
            if (between.gte != null) {
                // console.log("created at from between.gte: ", between.gte);
                resultFilters.push(createDateBetweenFromFilter('createdAt', between.gte));
            }
            if (between.lte != null) {
                // console.log("created at to between.lte: ", between.lte);
                resultFilters.push(createDateBetweenToFilter('createdAt', between.lte));
            }
        }
        if (requestFilters.hasOwnProperty(discountCodesFields.uniqueCouponCode)) {
            resultFilters.push(createContainsFilter('uniqueCouponCode', requestFilters[discountCodesFields.uniqueCouponCode]));
        }
        return resultFilters;
    };

    const queryParams = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filters: JSON.stringify(prepareFilters())
    };
    return {
        url: `${API_URL}/${RESOURCE_NAME}?${queryString.stringify(queryParams)}`
    };
}

const httpResponseToProviderData = (httpResponse, requestType, requestParams) => {
    var {
        headers,
        json
    } = httpResponse;
    switch (requestType) {
        case GET_LIST:
            return {
                data: json.map(x => x),
                    total: parseInt(headers.get('content-range').split('/').pop()),
            };
        default:
            return {
                data: json
            };
    }
};

export default {
    resource: RESOURCE_NAME,
    providerInterface: {
        providerRequestToHttpRequest,
        httpResponseToProviderData
    },
};