import React from 'react';
import { useRecordContext, TextField } from 'react-admin';
import { Paper, Typography, Box, Divider } from '@mui/material';
import { callsFields } from '../../calls.model';

const ChartRow = ({ label, value }) => (
    <Box sx={{ mb: 1.5 }}>
        <Typography 
            component="span" 
            sx={{ 
                color: 'rgba(0, 0, 0, 0.6)',
                fontSize: '.75rem',
                fontWeight: 500,
                display: 'block',
                mb: 0.5
            }}
        >
            {label}
        </Typography>
        <Typography 
            component="span" 
            sx={{ 
                color: 'rgba(0, 0, 0, 0.87)',
                fontSize: '.75rem',
                display: 'block',
                ml: 2 
            }}
        >
            {value || 'n/a'}
        </Typography>
    </Box>
);

const CallProvChartShow = ({ label = 'Provider Chart' }) => {
    const record = useRecordContext();
    if (!record) return null;

    if (record[callsFields.nonClinical]) {
        return (
            <Paper elevation={2} sx={{ p: 2, bgcolor: '#f5f5f5', width: 400 }}>
                <Typography sx={{ fontSize: '.70rem', color: 'rgba(0, 0, 0, 0.6)' }}>
                    No clinical content
                </Typography>
            </Paper>
        );
    }

    let phi = null;
    try {
        if (record[callsFields.phi]) {
            phi = JSON.parse(record[callsFields.phi]);
        }
    } catch (e) {
        console.error('Error parsing phi:', e);
        return (
            <Paper elevation={2} sx={{ p: 2, bgcolor: '#fff3e0', width: 400 }}>
                <Typography sx={{ fontSize: '.70rem', color: 'error.main' }}>
                    Error reading chart data
                </Typography>
            </Paper>
        );
    }

    if (!phi || !phi.feedbackFormData) {
        return (
            <Paper elevation={2} sx={{ p: 2, bgcolor: '#f5f5f5', width: 400 }}>
                <Typography sx={{ fontSize: '.70rem', color: 'rgba(0, 0, 0, 0.6)' }}>
                    No chart
                </Typography>
            </Paper>
        );
    }

    const { feedbackFormData, type } = phi;

    // Handle different chart types
    if (type === 'doula') {
        return (
            <Paper elevation={2} sx={{ p: 2, bgcolor: '#fafafa', width: 400 }}>
                {feedbackFormData.findings && (
                    <ChartRow label="Findings" value={feedbackFormData.findings} />
                )}
                {feedbackFormData.birth_plan && (
                    <ChartRow label="Birth Plan" value={feedbackFormData.birth_plan} />
                )}
                {feedbackFormData.birth_partner && (
                    <ChartRow label="Birth Partner" value={feedbackFormData.birth_partner} />
                )}
                {feedbackFormData.prenatal_visit && (
                    <ChartRow label="Prenatal Visit" value={feedbackFormData.prenatal_visit} />
                )}
                {feedbackFormData.recommendations && (
                    <ChartRow label="Recommendations" value={feedbackFormData.recommendations} />
                )}
                {feedbackFormData.follow_up && (
                    <ChartRow label="Follow Up" value={feedbackFormData.follow_up} />
                )}
            </Paper>
        );
    } else if (type === 'postpartum_doula') {
        return (
            <Paper elevation={2} sx={{ p: 2, bgcolor: '#fafafa', width: 400 }}>
                {feedbackFormData.findings && (
                    <ChartRow label="Findings" value={feedbackFormData.findings} />
                )}
                {feedbackFormData.postpartum_visits && (
                    <ChartRow label="Postpartum Visits" value={feedbackFormData.postpartum_visits} />
                )}
                {feedbackFormData.pediatrician_visits && (
                    <ChartRow label="Pediatrician Visits" value={feedbackFormData.pediatrician_visits} />
                )}
                {feedbackFormData.phq2 && (
                    <ChartRow label="PHQ2" value={feedbackFormData.phq2} />
                )}
                {feedbackFormData.recommendations && (
                    <ChartRow label="Recommendations" value={feedbackFormData.recommendations} />
                )}
                {feedbackFormData.follow_up && (
                    <ChartRow label="Follow Up" value={feedbackFormData.follow_up} />
                )}
            </Paper>
        );
    } else {
        // Default chart type
        const hasContent = feedbackFormData.recommendations || feedbackFormData.complaint || feedbackFormData.follow_up;
        if (!hasContent) {
            return (
                <Paper elevation={2} sx={{ p: 2, bgcolor: '#f5f5f5', width: 400 }}>
                    <Typography sx={{ fontSize: '.70rem', color: 'rgba(0, 0, 0, 0.6)' }}>
                        No chart
                    </Typography>
                </Paper>
            );
        }

        return (
            <Paper elevation={2} sx={{ p: 2, bgcolor: '#fafafa', width: 400 }}>
                {feedbackFormData.complaint && (
                    <ChartRow label="Complaint" value={feedbackFormData.complaint} />
                )}
                {feedbackFormData.recommendations && (
                    <ChartRow label="Recommendations" value={feedbackFormData.recommendations} />
                )}
                {feedbackFormData.follow_up && (
                    <ChartRow label="Follow Up" value={feedbackFormData.follow_up} />
                )}
            </Paper>
        );
    }
};

export default CallProvChartShow;