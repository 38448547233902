import React from 'react';
import { Show, SimpleShowLayout, TextField, DateField } from 'react-admin';
import { queuesFields } from '../queues.model';
import DefaultPlaceholderField from '../../material/defPlaceholderField.component';
import QueueTitle from './fields/queueTitle.component';
import { DATE_TIME_OPTIONS } from '../../app/app.component';
import PatientLinkField from "./fields/patientLinkField.component";
import ProviderLinkField from "./fields/providerLinkField.component";
import QueueTimeLabel from "./fields/queueTimeLabel.component";
import OrganizationLinkField from "@/queues/component/fields/organizationField.component";

const QueueShow = () => {
    return (
        <Show title={<QueueTitle />} >
            <SimpleShowLayout>
                <DefaultPlaceholderField source={queuesFields.id} wrapField={<TextField/>} label="Id" />
                <DefaultPlaceholderField
                  source={queuesFields.type}
                  label="Type"
                  wrapField={<TextField source={queuesFields.type} />}
                />
                <DefaultPlaceholderField
                  source={queuesFields.lang}
                  label="Language"
                  wrapField={<TextField source={queuesFields.lang} />}
                />
                <DefaultPlaceholderField
                  source={queuesFields.status}
                  label="Status"
                  wrapField={<TextField source={queuesFields.status} />}
                />
                <DefaultPlaceholderField
                  source={queuesFields.consultation}
                  label="Consultation"
                  wrapField={<TextField source={queuesFields.consultation} />}
                />
                <DefaultPlaceholderField
                  source={queuesFields.service}
                  label="Service"
                  wrapField={<TextField source={queuesFields.service} />}
                />
                <DefaultPlaceholderField
                  source={queuesFields.roleName}
                  label="Role"
                  wrapField={<TextField source={queuesFields.roleName} />}
                />
                <PatientLinkField label="Patient" source={queuesFields.patientId} />
                <DefaultPlaceholderField
                  source={queuesFields.patientFeedback}
                  label="Patient Feedback"
                  wrapField={<TextField source={queuesFields.patientFeedback} />}
                />
                <DefaultPlaceholderField
                  source={queuesFields.patientRating}
                  label="Patient Rating"
                  wrapField={<TextField source={queuesFields.patientRating} />}
                />
                <ProviderLinkField source={queuesFields.providerId} label="Provider" addLabel />
                <OrganizationLinkField source={queuesFields.organizations} label="Organization" />
                <DefaultPlaceholderField
                  source={queuesFields.startedAt}
                  options={DATE_TIME_OPTIONS}
                  wrapField={<DateField/>}
                  label="Started At"
                />
                <DefaultPlaceholderField
                  source={queuesFields.acceptedAt}
                  options={DATE_TIME_OPTIONS}
                  wrapField={<DateField/>}
                  label="Accepted At"
                />
                <DefaultPlaceholderField
                  source={queuesFields.endedAt}
                  options={DATE_TIME_OPTIONS}
                  wrapField={<DateField/>}
                  label="Ended At"
                />
                <QueueTimeLabel source={queuesFields.timeToAnswer} label="Time To Answer" />
                <QueueTimeLabel source={queuesFields.duration} label="Duration" />
            </SimpleShowLayout>
        </Show>
    );
};

export default QueueShow;
