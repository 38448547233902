import * as React from 'react';
import TagIcon from '@mui/icons-material/TagFaces';
import { makeStyles } from '@mui/styles';
import { rolesFields } from '../../roles.model';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginRight: 8,
    }
});

const RoleTitle = ({ record = {} }) => {
    const classes = useStyles();
    const roleName = record[rolesFields.roleName] || 'n/a';
    
    return (
        <div className={classes.root}>
            <TagIcon className={classes.icon} />
            <span>{roleName}</span>
        </div>
    );
};

export default RoleTitle;
