import {formatDuration, intervalToDuration} from 'date-fns'
import { format, fromUnixTime } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const DEFAULT_DATE_FORMAT = "yyyy-MM-dd"
const DEFAULT_TIME_FORMAT = "HH:mm:ss"
const DEFAULT_TIMEZONE = "America/Toronto"

export const secondsToDurationString = (seconds) => {
  if (!seconds) return ""
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  return [
    hours.toString().padStart(2, "0"),
    minutes.toString().padStart(2, "0"),
    secs.toString().padStart(2, "0")
  ].join(":");
}

export const timeStringFromTimestamp = (
  timestamp,
  timeFormat = DEFAULT_TIME_FORMAT,
  zone = DEFAULT_TIMEZONE,
) => {
  if (!timestamp) return "";

  const date = fromUnixTime(timestamp / 1000);
  const zonedDate = utcToZonedTime(date, zone);
  return format(zonedDate, timeFormat);
};

export const dateStringFromTimestamp = (
  timestamp,
  dateFormat = DEFAULT_DATE_FORMAT,
  zone = DEFAULT_TIMEZONE,
) => {
  if (!timestamp) return "";

  const date = fromUnixTime(timestamp / 1000);
  const zonedDate = utcToZonedTime(date, zone);
  return format(zonedDate, dateFormat);
};