import React, { useState } from 'react';
import { 
    Card, 
    Box, 
    Tabs,
    Tab
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TabbedShowLayout } from 'react-admin';
import PersonIcon from '@mui/icons-material/Person';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import DashboardRecentPatients from './DashboardRecentPatients';
import DashboardRecentCalls from './DashboardRecentCalls';

const useStyles = makeStyles((theme) => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    tabContent: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    }
}));

const DashboardCarousel = () => {
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Card className={classes.card}>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
            >
                <Tab icon={<PhoneInTalkIcon />} label="Recent Calls" />
                <Tab icon={<PersonIcon />} label="Recent Patients" />
            </Tabs>
            <Box className={classes.tabContent}>
                {tabValue === 0 && <DashboardRecentCalls />}
                {tabValue === 1 && <DashboardRecentPatients />}
            </Box>
        </Card>
    );
};

export default DashboardCarousel; 