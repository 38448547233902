import * as React from 'react';
import { Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
    root: {
        // height: '24px',  // Match MUI's small chip height
        '& .MuiChip-labelSmall': {  // Target small label specifically
            padding: '0 12px',
            lineHeight: '24px',      // Match the height for vertical centering
            fontSize: '0.8125rem'     // Match the font size from the styles
        }
    },
    label: {
        textDecoration: 'line-through',
    }
});

const YesNoChip = ({ isActive, className, ...props }) => {
    const classes = useStyles();
    
    return isActive ? (
        <Chip 
            label="Yes" 
            color="primary"
            size="small"
            className={classNames(classes.root, className)} 
            {...props} 
        />
    ) : (
        <Chip 
            label="No" 
            color="default"
            size="small"
            className={classNames(classes.root, className)} 
            {...props} 
        />
    );
};

const YesNoBadge = ({ isActive }) => {
    return <YesNoChip isActive={isActive} />;
};

export default YesNoBadge;
