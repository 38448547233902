import * as React from 'react';
import {
    Edit,
    TabbedForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    required,
    AutocompleteInput,
    SaveButton,
    TopToolbar,
    useNotify,
    useRedirect,
    useRecordContext,
    Toolbar
} from 'react-admin';
import { codesFields, CodeTypeChoices } from '../codes.model';
import { orgsFields } from '../../organizations/orgs.model';
import { ALL_RESOURCE_NAME as ORGANIZATIONS_RESOURCE } from '../../organizations/organizations.dataProvider';
import ProviderRoleOrderedList from '../../components/ProviderRoleOrderedList';

const EditToolbar = () => (
    <Toolbar>
        <SaveButton 
            label="Save"
            sx={{ marginLeft: 2 }}
        />
    </Toolbar>
);

const validateCodeName = [required('Code name is required')];
const validateOrganization = [required('Organization is required')];
const validateCodeType = [required('Code type is required')];

const RolesList = () => {
    const record = useRecordContext();
    if (!record) return null;

    return (
        <ProviderRoleOrderedList 
            rolesFieldName={codesFields.roleIds}
            record={record}
        />
    );
};

const CodeEdit = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (data) => {
        notify('Code updated successfully', { type: 'success' });
        redirect('show', 'codes', data.id);
    };

    const onError = (error) => {
        notify(`Error: ${error.message}`, { type: 'error' });
    };

    return (
        <Edit
            title="Edit Membership Code"
            mutationMode="pessimistic"
            mutationOptions={{ 
                onSuccess,
                onError
            }}
            sx={{
                '& .RaEdit-main': {
                    margin: '0.5em'
                }
            }}
        >
            <TabbedForm
                mode="onBlur"
                toolbar={<EditToolbar />}
            >
                <TabbedForm.Tab
                    label="Code Details"
                >
                    <TextInput 
                        source={codesFields.codeName} 
                        label="Code Name" 
                        validate={validateCodeName}
                        sx={{ width: 400 }}
                    />
                    <ReferenceInput 
                        source={codesFields.orgId}
                        reference={ORGANIZATIONS_RESOURCE} 
                        label="Organization"
                        sx={{ width: 400 }}
                    >
                        <AutocompleteInput 
                            optionText={orgsFields.orgName}
                            validate={validateOrganization}
                            sx={{ width: 400 }}
                        />
                    </ReferenceInput>
                    <SelectInput 
                        label="Code Type"
                        source={codesFields.codeType}
                        validate={validateCodeType}
                        choices={CodeTypeChoices}
                        sx={{ width: 400 }}
                    />
                    <RolesList />
                </TabbedForm.Tab>
            </TabbedForm>
        </Edit>
    );
};

export default CodeEdit;
