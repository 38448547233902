import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    DateField,
    TextInput,
    SelectInput,
    downloadCSV,
    useListContext
} from 'react-admin';
import { discountCodesFields } from '../discountCodes.model';
import DefaultPlaceholderField from '../../../material/defPlaceholderField.component';
import DiscountField from './fields/discountField.component';
import NotEmptyYesNoField from './fields/notEmptyYesNoField.component';
import UserLinkField from './fields/userLink.component';
import PeriodInput from "../../../app/periodInput.component";
import { DATE_OPTIONS } from '../../../app/app.component';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import moment from 'moment';

const exporter = codes => {
    const codesForExport = codes.map(code => {
        // console.log("exporter code ::: ", code);
        let createdTime = code.createdAt ? moment(code.createdAt).zone(60).format('YYYY-MM-DD HH:mm:ss') : "";
        let deactivatedTime = code.deactivatedAt ? moment(code.deactivatedAt).zone(60).format('YYYY-MM-DD HH:mm:ss') : "";
        let appliedTime = code.appliedAt ? moment(code.appliedAt).zone(60).format('YYYY-MM-DD HH:mm:ss') : "";
        code['Id'] = code.id;
        code['Unique coupon code'] = code.uniqueCouponCode;
        code['Dollar discount'] = code.discount;
        code['Percent discount'] = code.discountPercent;
        code['Created at'] = createdTime;
        code['Deactivated at'] = deactivatedTime;
        code['Deactivated'] = code.deactivated;
        code['Applied at'] = appliedTime;
        code['Redeemed'] = code.redeemed;
        code['Plans'] = code.plans;
        code['User id'] = code.userId;
        code['User first name'] = code.userFirstName;
        code['User last name'] = code.userLastName;
        return code;
    });

    const csv = convertToCSV({
        data: codesForExport,
        fields: ['Id', 'Unique coupon code', 'Dollar discount', 'Percent discount', 'Created at', 'Deactivated at', 'Deactivated',
         'Applied at','Redeemed', 'Plans', 'User id', 'User first name', 'User last name']
    });
    downloadCSV(csv, 'discount-codes-' + moment(new Date()).format('YYYY-MM-DD').toString());
};

const discountCodesFilters = [
    <PeriodInput
        source="redeemed"
        fromLabel="Redeemed At From"
        toLabel="Redeemed At To"
        label="Date Redeemed"
    />,
    <PeriodInput
        source="created"
        fromLabel="Created At From"
        toLabel="Created At To"
        label="Date Created"
    />,
    <SelectInput
        label="Redeemeed"
        source={discountCodesFields.appliedAt}
        choices={[
            { id: "not null", name: 'Yes' },
            { id: "null", name: 'No' }
        ]}
    />,
    <SelectInput
        label="Deactivated"
        source={discountCodesFields.deactivatedAt}
        choices={[
            { id: "not null", name: 'Yes' },
            { id: "null", name: 'No' }
        ]}
    />,
    <TextInput 
        label="Code" 
        source={discountCodesFields.uniqueCouponCode} 
        allowEmpty 
    />
];

const DiscountCodesList = () => (
    <List 
        title="Discount Codes"
        filters={discountCodesFilters}
        exporter={exporter}
        bulkActionButtons={false}
    >
        <Datagrid rowClick="show">
            <DefaultPlaceholderField 
                source={discountCodesFields.id} 
                wrapField={<TextField/>}  
                label="Id"
            />
            <DefaultPlaceholderField 
                source={discountCodesFields.uniqueCouponCode} 
                wrapField={<TextField/>}  
                label="Code"
            />
            <DiscountField 
                label="Discount" 
                source="discountStringKey"
            />
            <DefaultPlaceholderField 
                source={discountCodesFields.createdAt} 
                wrapField={<DateField/>} 
                options={DATE_OPTIONS} 
                label="Date Created"
            />
            <DefaultPlaceholderField 
                source={discountCodesFields.appliedAt} 
                wrapField={<DateField/>} 
                options={DATE_OPTIONS} 
                label="Date Redeemed"
            />
            <NotEmptyYesNoField 
                source={discountCodesFields.redeemed} 
                label="Redeemed" 
                sortable={false}
            />
            <NotEmptyYesNoField 
                source={discountCodesFields.deactivated} 
                label="Deactivated" 
                sortable={false}
            />
            <DefaultPlaceholderField 
                source={discountCodesFields.plans} 
                wrapField={<TextField/>} 
                label="Plans" 
                sortable={false} 
            />
            <UserLinkField 
                label="User" 
                addLabel 
                source="fullName"
            />
            <ShowButton />
        </Datagrid>
    </List>
);

export default DiscountCodesList;
