import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Filter,
    TextInput,
    ReferenceInput,
    SelectInput,
    DateField,
    BooleanInput,
    ShowButton,
    TopToolbar,
    FilterButton,
    ExportButton,
    useListContext
} from 'react-admin';
import { styled } from '@mui/material/styles';
import { callsFields, callStatus, CallStatusNames, callProviderChartType, CallProviderChartTypeNames, callService, CallServiceNames } from '../calls.model';
import DefaultPlaceholderField from '../../material/defPlaceholderField.component';
import { RESOURCE_NAME as ORGANIZATIONS_RESOURCE } from '../../organizations/organizations.dataProvider';
import PatientLinkField from './fields/patientLink.component';
import CallStatus from './fields/callStatus.component';
import CallProvChartList from './fields/callProvChartList.component';
import ProviderLinkField from './fields/providerLink.component';
import CallTimeLabel from './fields/callTimeLabel.component';
import ProviderEndReason from './fields/providerEndReason.component';
import PatientEndReason from './fields/patientEndReason.component';
import CallLinkField from './fields/callLink.component';
import YesNoField from '../../material/yesNoField.component';
import { RESOURCE_NAME as PROVIDER_ROLES_RESOURCE } from '../../roles/provider.roles.dataProvider';
import { DATE_TIME_OPTIONS } from '../../app/app.component';
import PeriodInput from "../../app/periodInput.component";

const ListActions = () => (
    <TopToolbar>
        <FilterButton />
        <ExportButton />
    </TopToolbar>
);

const choiceForCallStatus = (status) => ({ id: status, name: CallStatusNames[ status ] });
const choiceForProviderChartType = (type) => ({ id: type, name: CallProviderChartTypeNames[ type ] });
const choiceForService = (service) => ({ id: service, name: CallServiceNames[ service ] });

const callsFilters = [
    <TextInput
        key="id"
        label="Call Id"
        source={callsFields.id}
    />,
    <ReferenceInput
        key="type"
        label="Type"
        source={callsFields.groupId}
        reference={PROVIDER_ROLES_RESOURCE}
        perPage={10000}
        sort={{ field: "name", order: 'ASC' }}
        alwaysOn
    >
        <SelectInput optionText="name" />
    </ReferenceInput>,
    <SelectInput
        key="status"
        label="Status"
        source={callsFields.status}
        choices={[
            choiceForCallStatus(callStatus.REQUESTED),
            choiceForCallStatus(callStatus.ACCEPTED),
            choiceForCallStatus(callStatus.CANCELED),
            choiceForCallStatus(callStatus.COMPLETED),
            choiceForCallStatus(callStatus.MISSED),
            choiceForCallStatus(callStatus.ERROR),
        ]}
        alwaysOn
    />,
    <SelectInput
        key="lang"
        label="Language"
        source={callsFields.lang}
        choices={[
            { id: 'en', name: 'English' },
            { id: 'es', name: 'Spanish' }
        ]}
        alwaysOn
    />,
    <TextInput label="Patient Id" source={callsFields.patientId} />,
    <TextInput label="Patient First Name Contains" source={callsFields.patientFirstName} />,
    <TextInput label="Patient Last Name Contains" source={callsFields.patientLastName} />,
    <TextInput label="Provider First Name Contains" source={callsFields.providerFirstName} />,
    <TextInput label="Provider Last Name Contains" source={callsFields.providerLastName} />,
    <PeriodInput
        source="startedBetween"
        fromLabel="Call Started At From"
        toLabel="Call Started At To"
        label="Call Started At"
    />,
    <PeriodInput
        source="endedBetween"
        fromLabel="Call Ended At From"
        toLabel="Call Ended At To"
        label="Call Ended At"
    />,
    <SelectInput
        label="Service"
        source={callsFields.service}
        choices={[
            choiceForService(callService.TWILIO),
            choiceForService(callService.TWILIO_ROOM),
            choiceForService(callService.TWILIO_PHONE),
            choiceForService(callService.TWILIO_CALLBACK),
            choiceForService(callService.OOVOO),
        ]}
    />,
    <BooleanInput
        label="Exclude Twilio Callbacks"
        source={`${ callsFields.service }_neq.${ callService.TWILIO_CALLBACK }`}
        alwaysOn
    />
];

const CallsList = () => {
    return (
        <List
            title="Calls"
            sort={{ field: 'id', order: 'DESC' }}
            filters={callsFilters}
            actions={<ListActions />}
            perPage={25}
            bulkActionButtons={false}
            storeKey="calls.list"
        >
            <Datagrid
                rowClick={false}
                bulkActionButtons={false}
            >
                <CallLinkField label="Id" source={callsFields.id} sortable={true} />
                <DefaultPlaceholderField source={callsFields.groupName} sortBy={callsFields.groupId} sortable={true} wrapField={<TextField />} label="Type" />
                <DefaultPlaceholderField source={callsFields.lang} wrapField={<TextField />} label="Lang" />
                <CallStatus source={callsFields.status} label="Status" />
                <CallProvChartList label="Provider Chart" />
                <PatientLinkField label="Patient" />
                <DefaultPlaceholderField source={callsFields.patientRating} wrapField={<TextField />} label="Patient Rating" />
                <DefaultPlaceholderField source={callsFields.patientFeedback} wrapField={<TextField />} label="Patient Feedback" />
                <ProviderLinkField label="Provider" />
                <DefaultPlaceholderField source={callsFields.providerRating} wrapField={<TextField />} label="Provider Rating" />
                <DefaultPlaceholderField source={callsFields.providerFeedback} wrapField={<TextField />} label="Provider Feedback" />
                <DefaultPlaceholderField source={callsFields.callStartedAt} sortable={true} options={DATE_TIME_OPTIONS} wrapField={<DateField />} label="Started At" />
                <DefaultPlaceholderField source={callsFields.acceptedAt} sortable={true} wrapField={<DateField />} options={DATE_TIME_OPTIONS} label="Accepted At" />
                <DefaultPlaceholderField source={callsFields.endedAt} sortable={true} wrapField={<DateField />} options={DATE_TIME_OPTIONS} label="Ended At" />
                <CallTimeLabel source={callsFields.timeToAnswer} label="Time to Answer" />
                <CallTimeLabel source={callsFields.timeToCancel} label="Time to Cancel" />
                <DefaultPlaceholderField source={callsFields.service} sortable={true} wrapField={<TextField />} label="Service" />
                <ProviderEndReason label="Provider End Reason" />
                <PatientEndReason label="Patient End Reason" />
                <YesNoField source={callsFields.introCall} wrapField={<TextField />} label="Intro Call" />
                <ShowButton />
            </Datagrid>
        </List>
    );
};

export default CallsList;