import {
    GET_LIST,
    GET_MANY, GET_ONE,
} from "react-admin";

const API_URL = import.meta.env.VITE_API_URL;

export const RESOURCE_NAME = "permission-templates";
const providerRequestToHttpRequest = (requestType) => {
    switch (requestType) {
        case GET_LIST:
        case GET_MANY: {
            return {
                url: `${API_URL}/${RESOURCE_NAME}`,
            };
        }
        default:
            throw new Error(`Unsupported fetch action type ${requestType}`);
    }
};

const httpResponseToProviderData = (
  httpResponse,
  requestType,
  requestParams
) => {
    let { json } = httpResponse;
    switch (requestType) {
        case GET_LIST:
            return {
                data: json.map(template => template),
                total: json.length,
            };
        case GET_MANY:
            let templateIds = requestParams.ids;

            return {
                data: json.filter(templateId => templateIds.includes(templateId.id)),
                total: json.length,
            };
        default:
            return {
                data: json,
                total: json.length,
            };
    }
};

export default {
    resource: RESOURCE_NAME,
    providerInterface: {
        providerRequestToHttpRequest,
        httpResponseToProviderData,
    },
};
