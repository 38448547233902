import {
  downloadCSV,
} from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import moment from 'moment-timezone';
import { hotlineFields } from '../hotline.model';
import { CallStatusNames, EndCallReasonNames } from '../../calls/calls.model';
import dateUtils from '../../utils/dateUtils'

const exporter = async calls => {

  if (calls[0]['url'] != null) { // Large Export Hack 
    // console.log('The URL to download from is, ', calls[0]['url'])
    await fetch(calls[0]['url'])
      .then(response => response.json())
      .then(data => {
        calls = data;
      })
      .catch(error => {
        console.error(JSON.stringify(error));
        alert('An error occurred downloading a large export.');
      });
  }

  const callsForExport = calls.map(call => {
    // console.log("exporter hotline ::: ", call);

    let callStartedAt = call[hotlineFields.callStartedAt];
    let callStartedAtTime = dateUtils.timeStringFromTimestamp(callStartedAt);
    let callStartedAtDate = dateUtils.dateStringFromTimestamp(callStartedAt);

    let callAcceptedAt = call[hotlineFields.acceptedAt];
    let callAcceptedAtTime = dateUtils.timeStringFromTimestamp(callAcceptedAt);
    let callAcceptedAtDate = dateUtils.dateStringFromTimestamp(callAcceptedAt);

    let callEndedAt = call[hotlineFields.endedAt];
    let callEndedAtTime = dateUtils.timeStringFromTimestamp(callEndedAt);
    let callEndedAtDate = dateUtils.dateStringFromTimestamp(callEndedAt);

    let callStatusName = CallStatusNames[call[hotlineFields.status]];

    let providerEndReason = EndCallReasonNames[call[hotlineFields.providerEndReason]];
    let patientEndReason = EndCallReasonNames[call[hotlineFields.patientEndReason]];


    let providerChartFollowUp = "";
    let providerChartRecommendations = "No chart";
    let providerChartComplaint = "No chart";
    let phi = call[hotlineFields.phi];
    if (call[hotlineFields.nonClinical]) {
      providerChartRecommendations = "No clinical content";
      providerChartComplaint = "No clinical content";
    } else if (phi != null) {
      try {
        let json = JSON.parse(phi);
        let formData = json.feedbackFormData;
        if (formData) {
          providerChartFollowUp = formData.follow_up;
          providerChartRecommendations = formData.recommendations;
          providerChartComplaint = formData.complaint;
        }
      } catch (e) {
      }
    }

    call['Call id'] = call[hotlineFields.id];
    call['Patient phone'] = call[hotlineFields.phone];
    call['Call (provider) type'] = call[hotlineFields.groupName];
    call['Call started at date'] = callStartedAtDate;
    call['Call started at time'] = callStartedAtTime;
    call['Accepted at date'] = callAcceptedAtDate;
    call['Accepted at time'] = callAcceptedAtTime;
    call['Ended at date'] = callEndedAtDate;
    call['Ended at time'] = callEndedAtTime;
    call['Patient rating'] = call[hotlineFields.patientRating];
    call['Provider first name'] = call[hotlineFields.providerFirstName];
    call['Provider last name'] = call[hotlineFields.providerLastName];
    call['Provider rating'] = call[hotlineFields.providerRating];
    call['Provider call stats'] = call[hotlineFields.providerFeedback]; // from provider feedback
    call['Provider chart: follow up'] = providerChartFollowUp; // from phi
    call['Provider chart: recommendations'] = providerChartRecommendations; //from phi
    call['Provider chart: complaint'] = providerChartComplaint; //from phi
    call['Overnight call (23:00 - 7:00 est)'] = call[hotlineFields.overnightCall]; //pushEnabled
    call['Status'] = callStatusName;
    call['Provider end reason'] = providerEndReason;
    call['Patient end reason'] = patientEndReason;
    call['Call completed'] = call[hotlineFields.callCompleted];
    call['Call length'] = dateUtils.stringFromTimeDuration(call[hotlineFields.callLength], "00:00:00");
    call['Callback length'] = dateUtils.stringFromTimeDuration(call[hotlineFields.callbackLength], "00:00:00");
    call['Billable time'] = dateUtils.stringFromTimeDuration(call[hotlineFields.billableTime], "00:00:00");
    call['Time to answer'] = dateUtils.stringFromTimeDuration(call[hotlineFields.timeToAnswer], "00:00:00");
    call['Time to cancel'] = dateUtils.stringFromTimeDuration(call[hotlineFields.timeToCancel], "00:00:00");
    call['Time to callback'] = dateUtils.stringFromTimeDuration(call[hotlineFields.timeToCallback], "00:00:00");
    call['Non-clinical'] = call[hotlineFields.nonClinical];
    call['Demo call'] = call[hotlineFields.demoCall];
    call['Language'] = call[hotlineFields.lang];
    call['Service'] = call[hotlineFields.service];
    call['Referral'] = call[hotlineFields.referral];
    call['Is repeating call'] = call[hotlineFields.isRepeatCaller];
    call['Is wic'] = call[hotlineFields.isWic];
    call['Breastfeeding'] = call[hotlineFields.breastfeeding];
    call['Zip code'] = call[hotlineFields.zipCode];
    call['Relation'] = call[hotlineFields.relation];
    call['Mother age'] = call[hotlineFields.motherAge];
    call['Mother race'] = call[hotlineFields.motherRace];
    call['Baby age'] = call[hotlineFields.babyAge];
    call['Baby gestational age'] = call[hotlineFields.babyGestationalAge];
    call['Voicemail'] = call[hotlineFields.voicemail];
    return call;
  });

  const csv = convertToCSV({
    data: callsForExport,
    fields: ['Call id', 'Patient phone', 'Call (provider) type', 'Call started at date', 'Call started at time', 'Accepted at date', 'Accepted at time',
      'Ended at date', 'Ended at time', 'Patient rating', 'Provider first name', 'Provider last name', 'Provider rating', 'Provider call stats',
      'Provider chart: follow up', 'Provider chart: recommendations', 'Provider chart: complaint', 'Overnight call (23:00 - 7:00 est)', 'Status',
      'Provider end reason', 'Patient end reason', 'Call completed', 'Call length', 'Callback length', 'Billable time', 'Time to answer', 'Time to cancel',
      'Time to callback', 'Non-clinical', 'Demo call', 'Language', 'Service', 'Referral', 'Is repeating call', 'Is wic', 'Breastfeeding', 'Zip code',
      'Relation', 'Mother age', 'Mother race', 'Baby age', 'Baby gestational age', 'Voicemail']
  });
  downloadCSV(csv, 'hotline-calls-' + moment(new Date()).format('YYYY-MM-DD').toString());
};

export default exporter;