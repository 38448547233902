import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    UPDATE
} from 'react-admin';
import { codesFields } from "./codes.model";
import queryString from 'query-string';

const API_URL = import.meta.env.VITE_SDK_API_URL;

export const RESOURCE_NAME = "sdk_codes";
export const INVALIDATE_RESOURCE_NAME = "sdk/invalidate_sdk_code";

const providerRequestToHttpRequest = (requestType, requestParams) => {
    // console.log("SDK Codes providerRequestToHttpRequest ::: ", requestType);
    switch (requestType) {
        case GET_LIST: 
            // console.log("SDK Codes requestParams ::: ", requestParams);
            return composeGetCodesListRequest(requestParams);
        case GET_ONE:
            // console.log("SDK Codes GET_ONE requestParams ::: ", requestParams);
            return { url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}` };
        case GET_MANY: {
            const query = {
                filter: JSON.stringify({ id: requestParams.ids }),
            };
            return { url: `${API_URL}/${RESOURCE_NAME}?${queryString.stringify(query)}` };
        }
        case GET_MANY_REFERENCE: {
            const { page, perPage } = requestParams.pagination;
            const { field, order } = requestParams.sort;
            const query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([(page - 1) * perPage, (page * perPage) - 1]),
                filter: JSON.stringify({ ...requestParams.filter, [requestParams.target]: requestParams.id }),
            };
            return { url: `${API_URL}/${RESOURCE_NAME}?${queryString.stringify(query)}` };
        }
        case UPDATE:
        // console.log("SDK Codes UPDATE requestParams ::: ", requestParams);
            return {
                url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}`,
                options: { method: 'PUT', body: JSON.stringify(requestParams) },
            };
        default:
            throw new Error(`Unsupported fetch action type ${requestType}`);
    }
};

const composeGetCodesListRequest = (requestParams) => {
    const { page, perPage } = requestParams.pagination;
    const { field, order } = requestParams.sort;
    let prepareFilters = () => {
        let requestFilters = requestParams.filter;
        if (requestFilters === undefined) {
            return {};
        }
        let createEqFilter = (name, value) => ({ name, comparison: "eq", value });
        let createContainsFilter = (name, value) => ({ name, comparison: "contains", value });
        let createDateBetweenFromFilter = (name, value) => ({ name, comparison: "betweenFrom", value });
        let createDateBetweenToFilter = (name, value) => ({ name, comparison: "betweenTo", value });
        let createIsFilter = (name, value) => ({ name, comparison: "is", value });

        let resultFilters = [];
        // append "planId" filter
        if (requestFilters.hasOwnProperty(codesFields.planId)) {
            resultFilters.push(createEqFilter('planId', requestFilters[codesFields.planId]));
        }
        // append "codeName" filter
        if (requestFilters.hasOwnProperty(codesFields.codeName)) {
            resultFilters.push(createContainsFilter('codeName', requestFilters[codesFields.codeName]));
        }
        // append "userEmail" filter
        if (requestFilters.hasOwnProperty(codesFields.userEmail)) {
            resultFilters.push(createContainsFilter('userEmail', requestFilters[codesFields.userEmail]));
        }
        // append "enabled" filter
        if (requestFilters.hasOwnProperty(codesFields.enabled)) {
            resultFilters.push(createEqFilter('enabled', requestFilters[codesFields.enabled]));
        }
        // append "redeemed" filter
        if (requestFilters.hasOwnProperty(codesFields.activatedAt)) {
            resultFilters.push(createIsFilter('activatedAt', requestFilters[codesFields.activatedAt]));
        }
        // append "redeemed at" filter
        if (requestFilters.hasOwnProperty('redeemed')) {
            let between = requestFilters['redeemed'];
            // console.log("redeemed at from between: ", between);
            if (between.gte != null) {
                // console.log("redeemed at from between.gte: ", between.gte);
                resultFilters.push(createDateBetweenFromFilter('activatedAt', between.gte));
            }
            if (between.lte != null) {
                // console.log("redeemed at to between.lte: ",  between.lte);
                resultFilters.push(createDateBetweenToFilter('activatedAt', between.lte));
            }
        }
        // append "created at" filter
        if (requestFilters.hasOwnProperty('created')) {
            let between = requestFilters['created'];
            // console.log("created at from endedBetween: ", between);
            if (between.gte != null) {
                // console.log("created at from between.gte: ", between.gte);
                resultFilters.push(createDateBetweenFromFilter('createdAt', between.gte));
            }
            if (between.lte != null) {
                // console.log("created at to between.lte: ", between.lte);
                resultFilters.push(createDateBetweenToFilter('createdAt', between.lte));
            }
        }
        return resultFilters;
    };

    const queryParams = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage]),
        filters: JSON.stringify(prepareFilters()),
    };
    return { url: `${API_URL}/${RESOURCE_NAME}?${queryString.stringify(queryParams)}` };
}

const httpResponseToProviderData = (httpResponse, requestType, requestParams) => {
    var { headers, json } = httpResponse;
    switch (requestType) {
        case GET_LIST:
        // console.log("Sd codes GET_LIST httpResponse : ", httpResponse);
            return {
                data: json.map(x => x),
                total: parseInt(headers.get('content-range').split('/').pop()),
            };
        default:
            return { data: json };
    }
};

export default {
    resource: RESOURCE_NAME,
    providerInterface: {
        providerRequestToHttpRequest,
        httpResponseToProviderData
    },
};
