import {
    GET_LIST,
    GET_MANY,
} from 'react-admin';
import queryString from 'query-string';

const API_URL = import.meta.env.VITE_SDK_API_URL;

export const RESOURCE_NAME = "sdk/plans";

const providerRequestToHttpRequest = (requestType, requestParams) => {
    switch (requestType) {
        case GET_LIST: 
        // console.log("Codes plans GET_LIST ::: ");
            return { url: `${API_URL}/${RESOURCE_NAME}` };
        case GET_MANY: {
            // console.log("Codes plans GET_MANY ::: ");
            const query = {
                filter: JSON.stringify({ id: requestParams.ids }),
            };
            return { url: `${API_URL}/${RESOURCE_NAME}?${queryString.stringify(query)}` };
        }
        default:
            throw new Error(`Unsupported fetch action type for "SDK_CODES_PLANS" resource ${requestType}`);
    }
};

const httpResponseToProviderData = (httpResponse, requestType, requestParams) => {
    let { headers, json } = httpResponse;
    switch (requestType) {
        case GET_LIST:
        // console.log("Codes plans json.map(sdkCodePlanItem => sdkCodePlanItem) ::: ", json.map(sdkCodePlanItem => sdkCodePlanItem));
            return {
                data: json.map(sdkCodePlanItem => sdkCodePlanItem),
                total: parseInt(headers.get('content-range').split('/').pop()),
            };
        case GET_MANY:
            let sdkCodePlanIdsToGet = requestParams.ids;
            return {
                data: json.filter(sdkCodePlanItem => sdkCodePlanIdsToGet.includes(sdkCodePlanItem.id))
            };
        default:
            throw new Error(`Unsupported fetch action type ${requestType}`);
    }
};

export default {
    resource: RESOURCE_NAME,
    providerInterface: {
        providerRequestToHttpRequest,
        httpResponseToProviderData
    },
};
