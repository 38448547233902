import {
    GET_LIST,
    GET_MANY,
    GET_ONE,
    UPDATE,
    CREATE
} from 'react-admin';

import queryString from 'query-string';

export const RESOURCE_NAME = "contentLinks";
const API_URL = import.meta.env.VITE_API_URL;

const providerRequestToHttpRequest = (requestType, requestParams, all) => {
    // console.log(requestParams)
    switch(requestType){
        case GET_LIST:
            // console.log(`${API_URL}/${RESOURCE_NAME}`)
            return { url: `${API_URL}/${RESOURCE_NAME}` };        

        case GET_MANY:
            const query = {     
                all: all,                
                filters: JSON.stringify([{ name: "id", comparison: "in", value: requestParams.ids }])
            };
            // console.log(`${API_URL}/${RESOURCE_NAME}?${queryString.stringify(query)}`)
            return { url: `${API_URL}/${RESOURCE_NAME}?${queryString.stringify(query)}` };
        
        case GET_ONE:
            // console.log("Content links ", "Get_One");
            return { url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}` };
        
        case UPDATE:
            // console.log("Content links ", "Update");
            return {
                url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}`,
                options: { method: 'PUT', body: JSON.stringify(requestParams.data) },
            };

        case CREATE:
            return {
                url: `${API_URL}/${RESOURCE_NAME}`,
                options: {
                  method: "POST",
                  body: JSON.stringify(requestParams.data),
                },
            };
               
        default: 
            throw new Error(`Unsupported fetch action type ${requestType}`);
    }
};

const httpResponseToProviderData = (httpResponse, requestType, requestParams) => {
    let { headers, json } = httpResponse;
    switch (requestType){
        case GET_LIST:
            // console.log(RESOURCE_NAME, "response: ", json);
            return {
                data: json.map(linkItem => linkItem),
                total: parseInt(headers.get('content-range').split('/').pop())
            };
        case GET_MANY:
            let linkIdsToGet = requestParams.ids;
            // console.log("linkIDs to get => ", linkIdsToGet);
            // console.log(RESOURCE_NAME, "response: ", json.filter(linkItem => linkIdsToGet.includes(linkItem.id)))
            return {
                data: json.filter(linkItem => linkIdsToGet.includes(linkItem.id))
            };
        case CREATE:
            return {
                data: {
                  ...requestParams.data,
                  id: json.id,
                },
            };
        case GET_ONE:
        case UPDATE:
            return {
                data: json
            }
        default:
            throw new Error(`Unsupported fetch action type ${requestType}`);
    }
};

export default {
    resource: RESOURCE_NAME,
    providerInterface: {
        providerRequestToHttpRequest,
        httpResponseToProviderData
    },
};