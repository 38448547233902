import React from 'react';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import { codesFields } from '../../codes.model';

export default ({ record = {} }) => {
    return (
        <div>
            <CardMembershipIcon style={{verticalAlign: 'middle'}}/>
            <span style={{verticalAlign: 'middle'}}>&nbsp;{record[codesFields.code]}</span>
        </div>
    );
};
