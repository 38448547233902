import React, { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        gap: '20px'
    }
};

const AuthCallbackComponent = ({ onSuccess }) => {
    useEffect(() => {
        // console.log('[AuthCallback] Component mounted');
        // console.log('[AuthCallback] Current URL:', window.location.href);
        // console.log('[AuthCallback] Has hash:', !!window.location.hash);
        
        try {
            // console.log('[AuthCallback] Processing authentication callback');
            onSuccess();
        } catch (error) {
            console.error('[AuthCallback] Error handling authentication:', error);
        }
    }, [onSuccess]);

    return (
        <div style={styles.container}>
            <CircularProgress />
            <div>Completing authentication...</div>
        </div>
    );
};

export default AuthCallbackComponent;