import React from 'react';
import { Link, TextField, useRecordContext } from 'react-admin';
import { RESOURCE_NAME as VERSIONS_RESOURCE } from '../../versions.dataProvider';
import { versionsFields } from '../../versions.model';

const VersionLinkField = ({ source = versionsFields.id, label = 'Id', ...props }) => {
    const record = useRecordContext();
    if (!record || !record[source]) return null;

    return (
        <Link to={`/${VERSIONS_RESOURCE}/${record.id}/show`}>
            <TextField source={source} sx={{ color: 'primary.main' }} label={label} {...props}/>
        </Link>
    );
};

export default VersionLinkField;
