import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDataProvider, useRedirect, Link } from 'react-admin';
import { PATIENT_RESOURCE } from '../../users/constants';

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        padding: '16px',
    },
    headerCell: {
        fontWeight: 'bold',
    },
    clickableRow: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },
    idLink: {
        textDecoration: 'none',
        color: '#2196f3',
        '&:hover': {
            textDecoration: 'underline',
        }
    }
}));

const DashboardRecentPatients = () => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    
    useEffect(() => {
        let isMounted = true;

        const fetchPatients = async () => {
            try {
                const { data } = await dataProvider.getList(
                    PATIENT_RESOURCE,
                    {
                        pagination: { page: 1, perPage: 10 },
                        sort: { field: 'id', order: 'DESC' }
                    }
                );
                if (isMounted) {
                    setData(data);
                    setIsLoading(false);
                }
            } catch (error) {
                if (isMounted) {
                    console.error('Error fetching recent patients:', error);
                    setIsLoading(false);
                }
            }
        };

        fetchPatients();

        return () => {
            isMounted = false;
        };
    }, [dataProvider]);

    const handleRowClick = (id, e) => {
        // Don't trigger row click if clicking the ID link or its parent cell
        if (e.target.tagName.toLowerCase() === 'a' || e.target.closest('a')) {
            e.stopPropagation();
            return;
        }
        redirect('show', PATIENT_RESOURCE, id);
    };

    if (isLoading) {
        return (
            <Card>
                <CardHeader title="Recent Patients" />
                <div className={classes.tableContainer}>Loading...</div>
            </Card>
        );
    }

    if (!data || data.length === 0) {
        return (
            <Card>
                <CardHeader title="Recent Patients" />
                <div className={classes.tableContainer}>No recent patients</div>
            </Card>
        );
    }

    return (
        <Card>
            <CardHeader title="Recent Patients" />
            <TableContainer className={classes.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.headerCell}>ID</TableCell>
                            <TableCell className={classes.headerCell}>First Name</TableCell>
                            <TableCell className={classes.headerCell}>Last Name</TableCell>
                            <TableCell className={classes.headerCell}>Email</TableCell>
                            <TableCell className={classes.headerCell}>Healthie ID</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map(patient => (
                            <TableRow 
                                key={patient.id}
                                onClick={(e) => handleRowClick(patient.id, e)}
                                className={classes.clickableRow}
                                hover
                            >
                                <TableCell>
                                    <Link 
                                        to={{
                                            type: 'show',
                                            resource: PATIENT_RESOURCE,
                                            id: patient.id
                                        }}
                                        className={classes.idLink}
                                    >
                                        {patient.id}
                                    </Link>
                                </TableCell>
                                <TableCell>{patient.first_name}</TableCell>
                                <TableCell>{patient.last_name}</TableCell>
                                <TableCell>{patient.email}</TableCell>
                                <TableCell>{patient.healthie_id}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
};

export default DashboardRecentPatients; 