import { fetchUtils } from 'react-admin';
import queryString from 'query-string';
import { format, startOfDay, endOfDay } from 'date-fns';

export const NOTIFICATION_TEMPLATES_RESOURCE = 'notification-templates';
export const NOTIFICATION_DELIVERIES_RESOURCE = 'notification-deliveries';
export const NOTIFICATION_ANALYTICS_RESOURCE = 'notification-runs';

const API_URL = import.meta.env.VITE_API_URL;

/**
 * @typedef {Object} PregnancyMilestoneNotification
 * @property {string} id
 * @property {string} title
 * @property {string} body
 * @property {string} liquid_body
 * @property {string} notification_type
 * @property {string} milestone_type
 * @property {number} days_from_due_date
 * @property {string} scheduled_time
 * @property {string} deep_link
 * @property {boolean} is_active
 * @property {string} created_at
 * @property {string} updated_at
 */

const prepareFilters = (requestFilters) => {
    if (!requestFilters) return {};
    
    const filters = [];
    Object.keys(requestFilters).forEach(key => {
        let comparison = 'eq';
        let name = key;
        
        if (['title', 'body', 'deep_link'].includes(key)) {
            comparison = 'contains';
        }
        else if (['is_active'].includes(key)) {
            comparison = 'eq';
        }
        else if (['notification_type', 'milestone_type', 'push_priority', 'status'].includes(key)) {
            comparison = 'eq';
        }
        else if (key === 'scheduled_for_gte') {
            comparison = 'gte';
            name = 'scheduled_for';
        }
        else if (key === 'scheduled_for_lte') {
            comparison = 'lte';
            name = 'scheduled_for';
        }

        filters.push({
            name,
            value: requestFilters[key],
            comparison
        });
    });
    return filters;
};

export const pregnancyMilestoneNotificationsDataProvider = {
    resource: NOTIFICATION_TEMPLATES_RESOURCE,
    providerInterface: {
        providerRequestToHttpRequest: (type, params) => {
            let url = '';
            const options = {
                headers: new Headers({
                    'Content-Type': 'application/json',
                }),
            };

            const resource = params.resource || NOTIFICATION_TEMPLATES_RESOURCE;

            switch (type) {
                case 'GET_LIST': {
                    const { page, perPage } = params.pagination;
                    const { field, order } = params.sort;
                    const query = {
                        sort: JSON.stringify([field, order.toLowerCase()]),
                        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
                        filter: JSON.stringify(prepareFilters(params.filter))
                    };
                    url = `${API_URL}/${resource}?${queryString.stringify(query)}`;
                    break;
                }
                case 'GET_MANY': {
                    const query = {
                        filter: JSON.stringify([{
                            name: 'id',
                            value: params.ids,
                            comparison: 'in'
                        }])
                    };
                    url = `${API_URL}/${resource}?${queryString.stringify(query)}`;
                    break;
                }
                case 'GET_ONE':
                    url = `${API_URL}/${resource}/${params.id}`;
                    break;
                case 'CREATE':
                    url = `${API_URL}/${resource}`;
                    options.method = 'POST';
                    options.body = JSON.stringify(params.data);
                    break;
                case 'UPDATE':
                    url = `${API_URL}/${resource}/${params.id}`;
                    options.method = 'PUT';
                    options.body = JSON.stringify(params.data);
                    break;
                default:
                    throw new Error(`Unsupported fetch action type ${type}`);
            }

            // console.log(`Making ${type} request to: ${url}`);
            return { url, options };
        },

        httpResponseToProviderData: (response, type, params) => {
            const { headers, json } = response;
            
            switch (type) {
                case 'GET_LIST': {
                    const contentRange = headers.get('content-range');
                    const total = contentRange ? parseInt(contentRange.split('/').pop(), 10) : 0;
                    return {
                        data: Array.isArray(json) ? json : [],
                        total: total || (Array.isArray(json) ? json.length : 0)
                    };
                }
                case 'GET_MANY':
                    return { 
                        data: Array.isArray(json) ? json : [] 
                    };
                case 'CREATE':
                    return { data: { ...params.data, id: json.id } };
                case 'GET_ONE':
                    return { data: json };
                case 'UPDATE':
                    return { data: { ...params.data, id: params.id } };
                default:
                    return { data: json };
            }
        }
    }
};

export const pregnancyMilestoneNotificationDeliveriesDataProvider = {
    resource: NOTIFICATION_DELIVERIES_RESOURCE,
    providerInterface: {
        ...pregnancyMilestoneNotificationsDataProvider.providerInterface,
        providerRequestToHttpRequest: (type, params) => {
            return pregnancyMilestoneNotificationsDataProvider.providerInterface.providerRequestToHttpRequest(type, {
                ...params,
                resource: NOTIFICATION_DELIVERIES_RESOURCE
            });
        }
    }
};

export const pregnancyMilestoneNotificationAnalyticsDataProvider = {
    resource: NOTIFICATION_ANALYTICS_RESOURCE,
    providerInterface: {
        providerRequestToHttpRequest: (type, params) => {
            let url = '';
            const options = {
                headers: new Headers({
                    'Content-Type': 'application/json',
                }),
            };

            switch (type) {
                case 'GET_LIST': {
                    const { page, perPage } = params.pagination;
                    const { from, to } = params.filter || {};
                    
                    // Default to last 7 days if no dates provided
                    const end = new Date();
                    const start = new Date(end);
                    start.setDate(end.getDate() - 7);
                    
                    const query = {
                        limit: perPage,
                        from: from || format(startOfDay(start), 'yyyy-MM-dd'),
                        to: to || format(endOfDay(end), 'yyyy-MM-dd')
                    };
                    
                    url = `${API_URL}/${NOTIFICATION_ANALYTICS_RESOURCE}?${queryString.stringify(query)}`;
                    // console.log('Analytics URL:', url);
                    break;
                }
                case 'GET_ONE': {
                    url = `${API_URL}/${NOTIFICATION_ANALYTICS_RESOURCE}/timestamp/${params.id}`;
                    break;
                }
                default:
                    throw new Error(`Unsupported fetch action type ${type}`);
            }

            // console.log(`Making ${type} request to: ${url}`);
            return { url, options };
        },

        httpResponseToProviderData: (response, type, params) => {
            const { headers, json } = response;
            // console.log('Analytics response:', json);
            
            switch (type) {
                case 'GET_LIST':
                    const items = Array.isArray(json) ? json : [];
                    return {
                        data: items.map(run => ({
                            id: run.timestamp,
                            ...run
                        })),
                        total: items.length
                    };
                case 'GET_ONE':
                    return {
                        data: {
                            id: json.timestamp,
                            ...json
                        }
                    };
                default:
                    return { data: json };
            }
        }
    }
}; 