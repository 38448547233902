const API_URL = import.meta.env.VITE_API_URL;

export const RESOURCE_NAME = 'dashboard';

const providerRequestToHttpRequest = (requestType, requestParams) => {
    switch (requestType) {
        case 'GET_DASHBOARD_STATS':
            return {
                url: `${API_URL}/${RESOURCE_NAME}/stats`,
                options: {
                    method: 'GET'
                }
            };
        default:
            throw new Error(`Unsupported fetch action type ${requestType}`);
    }
};

const httpResponseToProviderData = (httpResponse, requestType, requestParams) => {
    const { json } = httpResponse;
    return { data: json };
};

export default {
    resource: RESOURCE_NAME,
    providerInterface: {
        providerRequestToHttpRequest,
        httpResponseToProviderData
    },
    customMethods: {
        getDashboardStats: () => ({
            type: 'GET_DASHBOARD_STATS'
        })
    }
}; 