import React from 'react';
import { Link, TextField, useRecordContext } from 'react-admin';
import { RESOURCE_NAME as USERS_RESOURCE } from '../../../users/user.dataProvider';
import { userFields } from '../../../users/user.model';

const UserLinkField = ({ addLabel = true, ...props }) => {
    const record = useRecordContext(props);
    
    if (!record || !record[userFields.id]) {
        return 'n/a';
    }
    
    return (
        <Link to={`/${USERS_RESOURCE}/${record[userFields.id]}/show`}>
            <TextField source="user_email" sx={{ color: 'primary.main' }} {...props} />
        </Link>
    );
};

export default UserLinkField; 