import React from 'react';
import { Link, TextField, useRecordContext } from 'react-admin';
import { RESOURCE_NAME as ORGANIZATIONS_RESOURCE } from '../../../organizations/organizations.dataProvider';
import { codesFields } from '../../codes.model';

const OrganizationLinkField = (props) => {
    const record = useRecordContext(props);
    
    if (!record) return null;
    
    const orgId = record[codesFields.orgId];
    const orgName = record[codesFields.orgName];
    
    if (!orgName) return 'n/a';
    if (!orgId) return orgName;

    return (
        <Link to={`/${ORGANIZATIONS_RESOURCE}/${orgId}/show`}>
            <span style={{ 
                color: 'var(--mui-palette-primary-main)',
                textDecoration: 'underline'
            }}>
                {orgName}
            </span>
        </Link>
    );
};

OrganizationLinkField.defaultProps = {
    addLabel: true
};

export default OrganizationLinkField;
