import React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    DateField,
    useRecordContext,
} from "react-admin";
import { Box, Typography } from '@mui/material';
import OrgTitle from "./fields/orgTitle.component";
import OrderedProviderRoles from "./fields/orderedProviderRoles.component";
import { DATE_TIME_OPTIONS } from "../../app/app.component";
import DefaultPlaceholderField from "../../material/defPlaceholderField.component";
import { orgsFields } from '../orgs.model';
import YesNoField from "../../material/yesNoField.component";
import MedicaidMemberListUpload from "./MedicaidMemberListUpload.component";

const OrgShow = (props) => {
    return (
        <Show {...props} title={<OrgTitle />}>
            <SimpleShowLayout>
                <DefaultPlaceholderField
                    source={orgsFields.orgName}
                    wrapField={<TextField source={orgsFields.orgName} addLabel />}
                    label="Name"
                />
                <DefaultPlaceholderField
                    source={orgsFields.clientGroupId}
                    wrapField={<TextField source={orgsFields.clientGroupId} addLabel />}
                    label="Client Group ID"
                />
                <DefaultPlaceholderField
                    source={orgsFields.payerId}
                    wrapField={<TextField source={orgsFields.payerId} addLabel />}
                    label="Payer ID"
                />
                <DefaultPlaceholderField
                    source={orgsFields.orgNursePhone}
                    wrapField={<TextField source={orgsFields.orgNursePhone} addLabel />}
                    label="Nurse phone"
                />
                <YesNoField
                    source={orgsFields.contentLibraryEnabled}
                    label="Content Library Enabled"
                    addLabel
                />
                <YesNoField
                    source={orgsFields.medicaidIdRegistrationQuestionEnabled}
                    label="Medicaid ID Registration Question Enabled"
                    addLabel
                />
                <YesNoField
                    source={orgsFields.enrollmentCodeRequired}
                    label="Enrollment Code Required"
                    addLabel
                />
                <YesNoField
                    source={orgsFields.chatEnabled}
                    label="Chat Enabled"
                    addLabel
                />
                <YesNoField
                    source={orgsFields.requiresWicId}
                    label="WIC ID Required"
                    addLabel
                />
                <DefaultPlaceholderField
                    source={orgsFields.orgCreatedAt}
                    wrapField={<DateField source={orgsFields.orgCreatedAt} addLabel options={DATE_TIME_OPTIONS} />}
                    label="Created at"
                />
                <DefaultPlaceholderField
                    source={orgsFields.orgUpdatedAt}
                    wrapField={<DateField source={orgsFields.orgUpdatedAt} addLabel options={DATE_TIME_OPTIONS} />}
                    label="Updated at"
                />
                <Box 
                    sx={{ 
                        '& .MuiFormControl-root': { 
                            marginTop: 2, 
                            marginBottom: 1 
                        } 
                    }}
                >
                    <Typography 
                        variant="body2" 
                        sx={{ 
                            mb: 1,
                            color: 'rgba(0, 0, 0, 0.6)',
                            fontSize: '.75rem',
                            fontWeight: 'bold'
                        }}
                    >
                        Supported Provider Roles
                    </Typography>
                    <OrderedProviderRoles />
                </Box>
                <MedicaidMemberListUpload />
            </SimpleShowLayout>
        </Show>
    );
};

export default OrgShow;
