import React, { useEffect } from 'react';
import auth0 from 'auth0-js';
import { useNavigate } from 'react-router-dom';
import App from '../app/app.component';
import SdkApp from '../sdk-app/app.component';

const AUTH_SDK_ADMIN_ROLE_VALUE = 8;
const AUTH_EXPIRES_AT_KEY = "AUTH_EXPIRES_AT";
const AUTH_ID_TOKEN_KEY = "AUTH_ID_TOKEN";
const AUTH_ACCESS_TOKEN_KEY = "AUTH_ACCESS_TOKEN";
const AUTH_PACIFY_ROLE_KEY = "AUTH_PACIFY_ROLE";
const AUTH_USER_PROFILE_KEY = "AUTH_USER_PROFILE";
const REFRESH_TOKEN_IF_EXPIRING_SOONER_THAN_SECONDS = 1800;
const CONTINUE_URI_QUERY_PARAM_NAME = "continueuri";

const CALLBACK_URL = import.meta.env.VITE_AUTH0_LOGIN_CALLBACK_URL;
const AUTH0_DOMAIN = import.meta.env.VITE_AUTH0_DOMAIN;//"pacify.auth0.com";
const AUTH0_CLIENT_ID = import.meta.env.VITE_AUTH0_CLIENT_ID;//"P2qSH7wmmk8Spk5o0GaCmr46jEHCLFge"

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
          /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
);


class Auth {
  accessToken;
  idToken;
  expiresAt;
  pacifyRole;
  navigate;
  renewSessionTimeout = null;

  auth0 = new auth0.WebAuth({
    domain: AUTH0_DOMAIN,
    clientID: AUTH0_CLIENT_ID,
    redirectUri: CALLBACK_URL,
    responseType: 'token id_token',
    scope: 'openid'
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.getPacifyRole = this.getPacifyRole.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.cleanup = this.cleanup.bind(this);

    this.expiresAt = localStorage.getItem(AUTH_EXPIRES_AT_KEY);
    this.accessToken = localStorage.getItem(AUTH_ACCESS_TOKEN_KEY);
    this.idToken = localStorage.getItem(AUTH_ID_TOKEN_KEY);
  }

  cleanup() {
    if (this.renewSessionTimeout) {
      clearTimeout(this.renewSessionTimeout);
      this.renewSessionTimeout = null;
    }
  }

  setNavigate(navigate) {
    this.navigate = navigate;
  }

  login() {
    const currentLocation = window.location.toString();
    const redirectUri = currentLocation.includes('/login') ? 
      CALLBACK_URL : 
      CALLBACK_URL + "?" + CONTINUE_URI_QUERY_PARAM_NAME + "=" + currentLocation;
      
    // console.log('[Auth] Initiating login with redirect URI:', redirectUri);
    this.auth0.authorize({
      scope: 'openid profile',
      redirectUri: redirectUri
    });
  }

  handleAuthentication(updateRole) {
    console.log('[Auth0] Handling authentication callback');
    // console.log('[Auth0] Current URL:', window.location.href);
    // console.log('[Auth0] Hash:', window.location.hash);
    
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        console.log('[Auth0] Auth result:', JSON.stringify(authResult));
        this.setSession(authResult);
        updateRole(this.getPacifyRole());
        
        // After successful authentication, remove the hash from the URL
        window.history.replaceState(null, '', window.location.pathname + window.location.search);
        
        // Get the original URL user was trying to access
        const urlParams = new URLSearchParams(window.location.search);
        const continueuri = urlParams.get(CONTINUE_URI_QUERY_PARAM_NAME);
        
        // console.log('[Auth0] Continue URI:', continueuri);
        
        if (continueuri && !continueuri.includes("login") && !continueuri.includes("callback")) {
          // console.log('[Auth0] Redirecting to:', continueuri);
          window.location.href = continueuri;
        } else {
          // console.log('[Auth0] Redirecting to root');
          this.navigate('/', { replace: true });
        }
      } else if (err) {
        console.error('[Auth0] Error parsing hash:', err);
        this.navigate('/', { replace: true });
        // console.log('[Auth0] Handle auth error:', err);
        alert(`Error: ${err.error}. Check the console for further details.`);
      }
    });
  }

  handleRoute() {
    if (this.getPacifyRole() === AUTH_SDK_ADMIN_ROLE_VALUE) {
      return <SdkApp />;
    } else {
      return <App />;
    }    
  }

  getAccessToken() {
    return this.accessToken;
  }

  getIdToken() {
    return this.idToken;
  }

  getPacifyRole() {
    let roleFromStorage = localStorage.getItem(AUTH_PACIFY_ROLE_KEY);
    return parseInt(roleFromStorage);
  }

  setSession(authResult) {
    /* console.log('[Auth] Setting session with auth result:', { 
      exp: authResult.idTokenPayload.exp,
      accessToken: authResult.accessToken ? 'present' : 'missing',
      idToken: authResult.idToken ? 'present' : 'missing'
    });*/
    
    let expiresAt = authResult.idTokenPayload.exp * 1000 // convert to epoch time in Milliseconds.
    this.expiresAt = expiresAt;
    localStorage.setItem(AUTH_EXPIRES_AT_KEY, this.expiresAt)

    this.accessToken = authResult.accessToken;
    localStorage.setItem(AUTH_ACCESS_TOKEN_KEY, this.accessToken);

    this.idToken = authResult.idToken;
    localStorage.setItem(AUTH_ID_TOKEN_KEY, this.idToken);

    // Store user profile information
    const userProfile = {
      userRole: authResult.idTokenPayload.userRole,
      nickname: authResult.idTokenPayload.nickname,
      name: authResult.idTokenPayload.name,
      picture: authResult.idTokenPayload.picture
    };
    localStorage.setItem(AUTH_USER_PROFILE_KEY, JSON.stringify(userProfile));

    // only for local usage, all the sdk admin users should start with sdk
    if (isLocalhost && authResult.idTokenPayload["name"]) {
      let userEmail = authResult.idTokenPayload["name"];
      // if includes sdk, then user is sdk admin, otherwise is superadmin
      this.pacifyRole = userEmail.includes("sdk") ? AUTH_SDK_ADMIN_ROLE_VALUE : 6;
    } else {
      this.pacifyRole = authResult.idTokenPayload["https://pacify.com/roleId"];
    }
    localStorage.setItem(AUTH_PACIFY_ROLE_KEY, this.pacifyRole);

    // Set up a timer to invoke renewSession even if the tab is in the background
    this.cleanup(); // Clear any existing timeout
    const tenMinutesBeforeExpiryInMS = this.expiresAt - (REFRESH_TOKEN_IF_EXPIRING_SOONER_THAN_SECONDS * 1000) - new Date().getTime();
    this.renewSessionTimeout = setTimeout(this.renewSession, tenMinutesBeforeExpiryInMS);
  }

  renewSession() {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        this.logout();
        // console.log("renew session error: ", err);
      }
    });
  }

  renewSessionIfExpiringSoon() {
    var diffInMS = this.expiresAt - new Date().getTime();
    if (diffInMS < REFRESH_TOKEN_IF_EXPIRING_SOONER_THAN_SECONDS * 1000) {
      this.renewSession();
    }
  }

  logout() {
    // Clean up the session renewal timeout
    this.cleanup();

    // Remove tokens and expiry time
    this.accessToken = null;
    localStorage.removeItem(AUTH_ACCESS_TOKEN_KEY);

    this.idToken = null;
    localStorage.removeItem(AUTH_ID_TOKEN_KEY);

    this.expiresAt = 0;
    localStorage.removeItem(AUTH_EXPIRES_AT_KEY);

    this.pacifyRole = 0;
    localStorage.removeItem(AUTH_PACIFY_ROLE_KEY);
    
    // Clear user profile
    localStorage.removeItem(AUTH_USER_PROFILE_KEY);
  }

  isAuthenticated() {
    let expiresAt = this.expiresAt;
    const isAuth = new Date().getTime() < expiresAt;
    return isAuth;
  }

  getUserProfile() {
    const profileStr = localStorage.getItem(AUTH_USER_PROFILE_KEY);
    return profileStr ? JSON.parse(profileStr) : null;
  }
}

const auth = new Auth();
export default auth;