import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    SelectInput,
    BooleanInput,
    required,
    useRecordContext,
    useNotify
} from 'react-admin';
import { versionsFields } from '../versions.model';

const VersionTitle = () => {
    const record = useRecordContext();
    return record ? `Version ${record.id}` : '';
};

const VersionEdit = () => {
    const notify = useNotify();

    const onSuccess = () => {
        notify('Version updated successfully');
    };

    return (
        <Edit 
            title={<VersionTitle />}
            mutationMode="pessimistic"
            mutationOptions={{ onSuccess }}
        >
            <SimpleForm>
                <SelectInput 
                    label="Platform" 
                    source={versionsFields.platform}
                    validate={required()}  
                    choices={[
                        { id: 2, name: "Android" },
                        { id: 1, name: "iOS" }                        
                    ]}
                    sx={{ width: 400 }}
                />
                <SelectInput 
                    label="App type" 
                    source={versionsFields.appType}
                    validate={required()}
                    choices={[
                        { id: 1, name: "Patient" },
                        { id: 2, name: "Provider" }
                    ]}
                    sx={{ width: 400 }}
                />   
                <TextInput
                    label="Version"
                    source={versionsFields.version} 
                    validate={required()} 
                    multiline
                    sx={{ width: 400 }}
                />
                <BooleanInput  
                    label="Force update" 
                    source={versionsFields.forceUpdate} 
                />
                <TextInput
                    label="Build"
                    source={versionsFields.build} 
                    validate={required()} 
                    multiline
                    sx={{ width: 400 }}
                />  
                <TextInput
                    label="Release notes"
                    source={versionsFields.releaseNotes} 
                    multiline
                    sx={{ width: 400 }}
                />
            </SimpleForm>
        </Edit>
    );
};

export default VersionEdit;
