import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    DateField,
    Filter,
    DateInput,
    TopToolbar,
    ExportButton,
    useRecordContext,
    useListContext,
    Loading,
    useRedirect,
} from 'react-admin';
import { Button } from '@mui/material';
import { format } from 'date-fns';

// Custom field to display a summary of statistics
const StatisticsSummaryField = () => {
    const record = useRecordContext();
    if (!record || !record.milestoneTypeCounts) return null;
    
    return (
        <div>
            <div>Prenatal: {record.milestoneTypeCounts.prenatal.total}</div>
            <div>Postnatal: {record.milestoneTypeCounts.postnatal.total}</div>
        </div>
    );
};

const ViewDetailsButton = () => {
    const redirect = useRedirect();
    const record = useRecordContext();

    const handleClick = () => {
        redirect('show', 'notification-runs', record.timestamp, undefined, {
            state: { record },
            replace: true
        });
    };

    return (
        <Button color="primary" onClick={handleClick}>
            View Details
        </Button>
    );
};

const RunFilter = props => (
    <Filter {...props}>
        <DateInput 
            source="from" 
            label="From Date"
            alwaysOn
            parse={value => value ? format(new Date(value), 'yyyy-MM-dd') : undefined}
        />
        <DateInput 
            source="to" 
            label="To Date"
            alwaysOn
            parse={value => value ? format(new Date(value), 'yyyy-MM-dd') : undefined}
        />
    </Filter>
);

const ListActions = ({
    basePath,
    currentSort,
    displayedFilters,
    filters,
    filterValues,
    resource,
    showFilter,
    total
}) => (
    <TopToolbar>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
        />
    </TopToolbar>
);

const AnalyticsListContent = () => {
    const { isLoading } = useListContext();
    
    if (isLoading) {
        return <Loading loadingPrimary="Loading analytics..." loadingSecondary="Please wait while we fetch the data" />;
    }

    return (
        <Datagrid>
            <DateField source="timestamp" showTime />
            <NumberField source="totalUsersProcessed" />
            <NumberField source="totalTemplatesUsed" />
            <StatisticsSummaryField label="Milestone Counts" />
            <ViewDetailsButton />
        </Datagrid>
    );
};

export const PregnancyMilestoneNotificationAnalyticsList = props => {
    return (
        <List 
            {...props}
            filters={<RunFilter />}
            actions={<ListActions />}
            bulkActionButtons={false}
            sort={{ field: 'timestamp', order: 'DESC' }}
        >
            <AnalyticsListContent />
        </List>
    );
}; 