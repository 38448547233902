import React from 'react';
import { Link, useRecordContext } from 'react-admin';
import { RESOURCE_NAME as PATIENTS_RESOURCE_NAME } from '../../../users/patient.dataProvider';
import PatientFullName from './patientFullName.component';
import { callsFields } from '../../calls.model';

const PatientLinkField = ({ label = 'Patient' }) => {
    const record = useRecordContext();
    if (!record || !record[callsFields.patientId]) return null;

    return (
        <Link to={`/${PATIENTS_RESOURCE_NAME}/${record[callsFields.patientId]}/show`}>
            <PatientFullName />
        </Link>
    );
};

export default PatientLinkField;
