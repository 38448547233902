import React from 'react';
import { TextField, useRecordContext } from 'react-admin';
import { callsFields } from '../../calls.model';

const PatientFullName = ({ label = 'Patient' }) => {
    const record = useRecordContext();
    if (!record) return null;

    const firstName = record[callsFields.patientFirstName];
    const lastName = record[callsFields.patientLastName];
    const fullName = (firstName || lastName) ? `${firstName || ''} ${lastName || ''}`.trim() : null;
    if (!fullName) return null;

    return (
        <TextField 
            source="fullName" 
            record={{ fullName }} 
            sx={{ color: 'primary.main' }} 
        />
    );
};

export default PatientFullName;
