import React from 'react';
import { Link, useRecordContext } from 'react-admin';
import { userFields, userGiftFields } from '../../user.model';

const placeholderStyle = { color: '#666', fontStyle: 'italic' };

const GiftLinkField = ({ label }) => {
    const record = useRecordContext();

    if (!record) {
        return null;
    }

    const gifts = record[userFields.gifts];
    if (!gifts || gifts.length === 0) {
        return <span style={placeholderStyle}>n/a</span>;
    }

    return (
        <div>
            {gifts.map((gift, index) => (
                <React.Fragment key={gift[userGiftFields.id]}>
                    <Link to={`/gifts/${gift[userGiftFields.id]}/show`}>
                        {gift[userGiftFields.code]}
                    </Link>
                    {index < gifts.length - 1 && ', '}
                </React.Fragment>
            ))}
        </div>
    );
};

export default GiftLinkField;