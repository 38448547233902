import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    BooleanInput,
    SelectInput,
    useRecordContext,
    useNotify,
    useRedirect,
    required,
    TopToolbar,
    Button,
    useDataProvider
} from 'react-admin';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { callsFields } from '../calls.model';
import { POST_UPDATE_CHART } from '../calls.dataProvider';

const API_URL = import.meta.env.VITE_API_URL;

const useStyles = makeStyles({
    form: {
        width: '100%',
        '& .MuiTextField-root': {
            margin: '0.5rem',
            width: '100%',
        },
    },
    table: {
        width: '100%',
        marginTop: '1rem',
    },
    fieldGroup: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        marginBottom: '2rem',
    }
});

const validationRules = {
    "doula": {
        required: ['findings', 'recommendation'],
    },
    "postpartum_doula": {
        required: ['findings', 'recommendation'],
    },
    "default": {
        required: ['complaint', 'recommendation'],
    },
};

const yesNoChoices = [
    { id: 'yes', name: 'Yes' },
    { id: 'no', name: 'No' },
];

const yesNoSomeNAChoices = [
    { id: 'yes', name: 'Yes' },
    { id: 'some', name: 'Some' },
    { id: 'no', name: 'No' },
    { id: 'n/a', name: 'N/A' },
];

const yesNoNAChoices = [
    { id: 'yes', name: 'Yes' },
    { id: 'no', name: 'No' },
    { id: 'n/a', name: 'N/A' },
];

const DefaultChartFields = () => (
    <>
        <TextInput
            source="complaint"
            label="Chief Complaint/History"
            multiline
            validate={required()}
            sx={{ width: 400 }}
        />
        <TextInput
            source="recommendation"
            label="Recommendations/Plan"
            multiline
            validate={required()}
            sx={{ width: 400 }}
        />
        <BooleanInput
            source="followUp"
            label="Immediate Follow-Up Required"
            sx={{ width: 400 }}
        />
    </>
);

const DoulaChartFields = ({ disabled }) => (
    <>
        <SelectInput
            source="birthPlan"
            label="Birth Wish List"
            choices={yesNoSomeNAChoices}
            disabled={disabled}
            sx={{ width: 400 }}
        />
        <SelectInput
            source="birthPartner"
            label="Birth Partner"
            choices={yesNoNAChoices}
            disabled={disabled}
            sx={{ width: 400 }}
        />
        <SelectInput
            source="prenatalVisits"
            label="Prenatal Visits"
            choices={yesNoSomeNAChoices}
            disabled={disabled}
            sx={{ width: 400 }}
        />
        <SelectInput
            source="inLabor"
            label="IN LABOR"
            choices={yesNoChoices}
            sx={{ width: 400 }}
        />
        <TextInput
            source="findings"
            label="Findings"
            multiline
            validate={required()}
            sx={{ width: 400 }}
        />
        <TextInput
            source="recommendation"
            label="Recommendations/Plan"
            multiline
            validate={required()}
            sx={{ width: 400 }}
        />
        <BooleanInput
            source="followUp"
            label="Immediate Follow-Up Required"
            sx={{ width: 400 }}
        />
    </>
);

const PostpartumDoulaChartFields = () => (
    <>
        <SelectInput
            source="postpartumVisits"
            label="Postpartum Visits"
            choices={yesNoSomeNAChoices}
            sx={{ width: 400 }}
        />
        <SelectInput
            source="pediatricianVisits"
            label="Pediatrician Visits"
            choices={yesNoSomeNAChoices}
            sx={{ width: 400 }}
        />
        <SelectInput
            source="phq2"
            label="PHQ-2"
            choices={yesNoNAChoices}
            sx={{ width: 400 }}
        />
        <BooleanInput
            source="phq2Referral"
            label="Referred for PHQ-2"
            sx={{ width: 400 }}
        />
        <TextInput
            source="findings"
            label="Findings"
            multiline
            validate={required()}
            sx={{ width: 400 }}
        />
        <TextInput
            source="recommendation"
            label="Recommendations/Plan"
            multiline
            validate={required()}
            sx={{ width: 400 }}
        />
        <BooleanInput
            source="followUp"
            label="Immediate Follow-Up Required"
            sx={{ width: 400 }}
        />
    </>
);

const EditActions = () => {
    const record = useRecordContext();
    const redirect = useRedirect();
    
    return (
        <TopToolbar>
            <Button
                label="Show"
                onClick={() => redirect(`/calls/${record.id}/show`)}
            />
        </TopToolbar>
    );
};

const ChartTitle = () => {
    const record = useRecordContext();
    return record ? `Chart ${record.id}` : '';
};

const EditCallChart = () => {
    const classes = useStyles();
    const notify = useNotify();
    const redirect = useRedirect();
    const { id } = useParams();
    const dataProvider = useDataProvider();
    const [chartType, setChartType] = useState('default');
    const [inLabor, setInLabor] = useState('no');
    const [initialValues, setInitialValues] = useState(null);

    const { mutate, isPending } = useMutation({
        mutationFn: (values) => {
            const transformedData = transform(values);
            // console.log('Transformed data:', transformedData);
            return dataProvider.updateChart('calls', {
                data: transformedData
            });
        },
        onSuccess: () => {
            notify('Chart updated successfully');
            redirect('list', 'calls');
        },
        onError: (error) => {
            console.error('Error updating chart:', error);
            notify(error?.message || 'Error updating chart', { type: 'error' });
        }
    });

    const save = async (values) => {
        // console.log('Saving values:', values);
        mutate(values);
    };

    useEffect(() => {
        dataProvider.getOne('calls', { id })
            .then(({ data }) => {
                if (data.phi) {
                    const phi = JSON.parse(data.phi);
                    const feedbackFormData = phi?.feedbackFormData || {};
                    setChartType(phi?.type || 'default');
                    setInLabor(feedbackFormData?.in_labor || 'no');

                    // Set initial values based on chart type
                    const baseValues = {
                        id: data.id,
                        followUp: feedbackFormData.follow_up === 'yes',
                        recommendation: feedbackFormData.recommendations || '',
                    };

                    switch (phi?.type) {
                        case 'doula':
                            setInitialValues({
                                ...baseValues,
                                birthPlan: feedbackFormData.birth_plan || 'n/a',
                                birthPartner: feedbackFormData.birth_partner || 'n/a',
                                prenatalVisits: feedbackFormData.prenatal_visit || 'n/a',
                                inLabor: feedbackFormData.in_labor || 'no',
                                findings: feedbackFormData.findings || '',
                            });
                            break;
                        case 'postpartum_doula':
                            setInitialValues({
                                ...baseValues,
                                postpartumVisits: feedbackFormData.postpartum_visits || 'n/a',
                                pediatricianVisits: feedbackFormData.pediatrician_visits || 'n/a',
                                phq2: feedbackFormData.phq2 || 'n/a',
                                phq2Referral: feedbackFormData.phq2_referral === 'yes',
                                findings: feedbackFormData.findings || '',
                            });
                            break;
                        default:
                            setInitialValues({
                                ...baseValues,
                                complaint: feedbackFormData.complaint || '',
                            });
                            break;
                    }
                }
            })
            .catch(error => {
                notify('Error loading chart data', { type: 'error' });
            });
    }, [dataProvider, id, notify]);

    const transform = (data) => {
        const baseData = {
            id: data.id,
            chartType,
        };

        switch (chartType) {
            case 'doula':
                return {
                    ...baseData,
                    birthPlan: data.birthPlan || 'n/a',
                    birthPartner: data.birthPartner || 'n/a',
                    prenatalVisits: data.prenatalVisits || 'n/a',
                    inLabor: data.inLabor || 'no',
                    findings: data.findings,
                    recommendation: data.recommendation,
                    followUp: data.followUp ? 'yes' : 'no'
                };
            case 'postpartum_doula':
                return {
                    ...baseData,
                    postpartumVisits: data.postpartumVisits || 'n/a',
                    pediatricianVisits: data.pediatricianVisits || 'n/a',
                    phq2: data.phq2 || 'n/a',
                    phq2Referral: data.phq2Referral ? 'yes' : 'no',
                    findings: data.findings,
                    recommendation: data.recommendation,
                    followUp: data.followUp ? 'yes' : 'no'
                };
            default:
                return {
                    ...baseData,
                    complaint: data.complaint,
                    recommendation: data.recommendation,
                    followUp: data.followUp ? 'yes' : 'no'
                };
        }
    };

    const validate = (values) => {
        const errors = {};
        const rules = validationRules[chartType];

        rules.required.forEach(field => {
            if (!values[field]) {
                errors[field] = 'Required';
            }
        });

        return errors;
    };

    if (!initialValues) {
        return null; // Don't render the form until we have initial values
    }

    return (
        <Edit
            title={<ChartTitle />}
            actions={<EditActions />}
            resource="calls"
            id={id}
            mutationMode="pessimistic"
        >
            <SimpleForm
                className={classes.form}
                validate={validate}
                onSubmit={save}
                defaultValues={initialValues}
            >
                {chartType === 'default' && <DefaultChartFields />}
                {chartType === 'doula' && <DoulaChartFields disabled={inLabor === 'yes'} />}
                {chartType === 'postpartum_doula' && <PostpartumDoulaChartFields />}
            </SimpleForm>
        </Edit>
    );
};

export default EditCallChart;