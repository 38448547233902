import React, { useRef } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDroppable } from '@dnd-kit/core';
import PropTypes from 'prop-types';

const FileDropzone = ({ 
    onFileSelect,
    selectedFile,
    accept = '*/*',
    dropzoneText = 'Drag or click to upload file',
    fileLabel = 'File to upload:',
    icon: Icon = CloudUploadIcon,
    onValidateType,
}) => {
    const fileInputRef = useRef(null);
    const { setNodeRef, isOver } = useDroppable({
        id: 'file-dropzone',
        data: {
            accepts: ['file']
        }
    });

    const handleFileSelect = (file) => {
        // If type validation is provided, use it
        if (onValidateType) {
            const isValid = onValidateType(file);
            if (!isValid) return;
        }
        // Otherwise do basic MIME type check
        else {
            const isValidType = accept.split(',').some(type => 
                file.type === type || 
                type.endsWith('/*') && file.type.startsWith(type.replace('/*', '/'))
            );
            if (!isValidType) {
                return;
            }
        }

        onFileSelect(file);
    };

    const handleInputChange = (e) => {
        const files = e.target.files;
        if (files.length) {
            handleFileSelect(files[0]);
        }
        // Reset the input value so the same file can be selected again
        e.target.value = '';
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length) {
            handleFileSelect(files[0]);
        }
    };

    const handleRemoveFile = (e) => {
        e.stopPropagation(); // Prevent triggering file selection
        onFileSelect(null);
    };

    return (
        <Box
            ref={setNodeRef}
            sx={{
                border: '1px dashed grey',
                borderRadius: 1,
                p: 3,
                mb: 2,
                textAlign: 'center',
                cursor: 'pointer',
                backgroundColor: isOver ? 'action.hover' : 'transparent',
                transition: 'background-color 200ms ease',
                '&:hover': {
                    bgcolor: 'action.hover'
                }
            }}
            onClick={() => fileInputRef.current?.click()}
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
        >
            <input
                type="file"
                accept={accept}
                onChange={handleInputChange}
                style={{ display: 'none' }}
                ref={fileInputRef}
            />
            {!selectedFile ? (
                <>
                    <Icon sx={{ fontSize: 40, color: 'text.secondary', mb: 1 }} />
                    <Typography color="primary">
                        {dropzoneText}
                    </Typography>
                </>
            ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                    <Typography color="text.primary">
                        {fileLabel} {selectedFile.name}
                    </Typography>
                    <IconButton 
                        size="small" 
                        onClick={handleRemoveFile}
                        sx={{ ml: 1 }}
                    >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Box>
            )}
        </Box>
    );
};

FileDropzone.propTypes = {
    onFileSelect: PropTypes.func.isRequired,
    selectedFile: PropTypes.object,
    accept: PropTypes.string,
    dropzoneText: PropTypes.string,
    fileLabel: PropTypes.string,
    icon: PropTypes.elementType,
    onValidateType: PropTypes.func,
};

export default FileDropzone; 