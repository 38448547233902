import React from 'react';
import { useNotify, useDataProvider } from 'react-admin';
import { useMutation } from '@tanstack/react-query';
import { Box, Typography, Button } from '@mui/material';
import { DndContext } from '@dnd-kit/core';
import CsvUploader from '../../components/CsvUploader';
import { usePinpointState, FILE_STATUS } from '../hooks/usePinpointState';
import { RESOURCE_NAME as PINPOINT_RESOURCE } from "../pinpoint.dataProvider";

const notificationOpts = {
    type: 'error',
    autoHideDuration: 60000,
    messageArgs: { _: { persistent: true } }
};

const PinpointCsvUploader = () => {
    const { state, setCsvFile, setFileStatus } = usePinpointState();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const { mutateAsync } = useMutation({
        mutationFn: async (csvText) => {
            // console.log('Sending CSV data:', { csvText });
            const response = await dataProvider.uploadToPinpoint(PINPOINT_RESOURCE, { 
                data: { csv: csvText }
            });
            // console.log('Server response:', response);
            return response;
        },
        onError: (error) => {
            console.error('Mutation error:', error);
        }
    });

    const handleFileSelect = (fileInput) => {
        const file = fileInput?.file || fileInput;
        
        if (!file) {
            setFileStatus(FILE_STATUS.REMOVED);
            setCsvFile(null);
            return;
        }

        if (file.type !== 'text/csv') {
            setFileStatus(FILE_STATUS.REJECTED_FILE_TYPE);
            notify('Invalid file type. Please upload a CSV file.', notificationOpts);
            return;
        }

        setFileStatus(FILE_STATUS.PREPARING);
        setCsvFile(file);
    };

    const uploadPinpointCSV = async () => {
        if (!state.csvFile) {
            notify('Please attach a csv file', notificationOpts);
            return;
        }

        try {
            // console.log('Starting file upload...');
            const csvText = await state.csvFile.text();
            const response = await mutateAsync(csvText);
            // console.log('Upload response:', response);

            if (response.data?.reason) {
                notify(response.data.reason, notificationOpts);
                setFileStatus(FILE_STATUS.REMOVED);
                setCsvFile(null);
                return;
            }

            notify('Successfully uploaded!', { 
                ...notificationOpts, 
                type: 'success' 
            });
            setCsvFile(null);
            setFileStatus(FILE_STATUS.REMOVED);
        } catch (error) {
            console.error('Upload error:', error);
            notify(`Upload failed: ${error.message || 'Unknown error'}`, notificationOpts);
            setFileStatus(FILE_STATUS.REMOVED);
            setCsvFile(null);
        }
    };

    return (
        <DndContext>
            <Box>
                <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                    Pinpoint Campaign CSV Upload
                </Typography>
                <CsvUploader 
                    onFileSelect={handleFileSelect}
                    selectedFile={state.csvFile}
                    dropzoneText="Upload Pinpoint campaigns csv file. Drag or click to choose."
                />
                <Box sx={{ mt: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={uploadPinpointCSV}
                        disabled={!state.csvFile}
                    >
                        Upload
                    </Button>
                </Box>
            </Box>
        </DndContext>
    );
};

export default PinpointCsvUploader; 