import React from 'react';
import { Link, TextField, useRecordContext } from 'react-admin';
import { RESOURCE_NAME as CODES_RESOURCE } from '../../codes.dataProvider';
import { codesFields } from '../../codes.model';

const CodeLinkField = (props) => {
    const record = useRecordContext(props);
    
    if (!record) return null;

    // Debug logging
    // console.log('CodeLinkField record:', record);
    
    const id = record[codesFields.id];
    const name = record[codesFields.codeName];
    
    if (!id) return 'n/a';

    return (
        <Link to={`/${CODES_RESOURCE}/${id}/show`}>
            <span style={{ 
                color: 'var(--mui-palette-primary-main)',
                textDecoration: 'underline'
            }}>
                {id}
            </span>
        </Link>
    );
};

CodeLinkField.defaultProps = {
    addLabel: true
};

export default CodeLinkField;
