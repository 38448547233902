import auth from './auth0';

const authProvider = {
    // called when the user attempts to log in
    login: () => {
        // console.log('[AuthProvider] Login attempted - this should never be called');
        throw Object.assign(
            new Error("this should never be called"), 
            { code: 402 }
        );
    },

    // called when the user clicks on the logout button
    logout: () => {
        // console.log('[AuthProvider] Logout called');
        auth.logout();
        return Promise.resolve();
    },

    // called when the API returns an error
    checkError: ({ status }) => {
        // console.log('[AuthProvider] Checking error status:', status);
        if (status === 401 || status === 403) {
            // console.log('[AuthProvider] Unauthorized/Forbidden - logging out');
            auth.logout();
            return Promise.reject();
        }
        return Promise.resolve();
    },

    // called when the user navigates to a new location
    checkAuth: (params) => {
        console.log('[AuthProvider] Checking auth for location:', params?.location);

        if (params?.location === '/callback') {
            console.log('[AuthProvider] On callback page, allowing through');
            return Promise.resolve(); // Currently trying to validate authenticity. Function will be called again after redirect after authentication succeeds or fails.
        }
        else if (auth.isAuthenticated()) {
            console.log('[AuthProvider] User is authenticated');
            auth.renewSessionIfExpiringSoon();
            return Promise.resolve();
        }
        else {
            // When we reject the promise here, react-admin redirects the user to the login page, and we lose 
            // the info of where they were trying to go. By clearing auth state and reloading instead, the user
            // gets to log in and continue on to their location.
            // console.log('[AuthProvider] Not authenticated, clearing auth and reloading');
            auth.logout();
            window.location.reload();
            return;
        }
    },

    // called to get the user's permissions
    getPermissions: () => {
        // console.log('[AuthProvider] Getting permissions');
        const role = auth.getPacifyRole();
        return Promise.resolve(role);
    },

    // called to get the user's identity
    getIdentity: () => {
        // console.log('[AuthProvider] Getting identity');
        const userProfile = auth.getUserProfile();
        return Promise.resolve({
            id: userProfile?.name || 'admin',
            fullName: userProfile?.nickname || 'Administrator',
            avatar: userProfile?.picture || null,
            email: userProfile?.name,
            displayName: userProfile?.nickname || 'Administrator',
            role: auth.getPacifyRole(),
            userRole: userProfile?.userRole,
            // Additional fields for React-Admin UI
            title: userProfile?.userRole || 'User',
            subtitle: userProfile?.name || '',
            avatarProps: { 
                sx: { width: 30, height: 30 },
                src: userProfile?.picture || null
            }
        });
    }
};

export default authProvider;

