import React from 'react';
import { useRecordContext, useGetMany } from 'react-admin';
import { Box, Chip } from '@mui/material';
import { RESOURCE_NAME as PROVIDER_ROLES_RESOURCE } from "../../../roles/provider.roles.dataProvider";
import { rolesFields } from '../../../roles/roles.model';
import { orgsFields } from '../../orgs.model';

const OrderedProviderRoles = () => {
    const record = useRecordContext();
    if (!record || !record[orgsFields.roleIds]) return null;

    const roleIds = record[orgsFields.roleIds];
    const { data: roles = [], isLoading } = useGetMany(
        PROVIDER_ROLES_RESOURCE,
        { ids: roleIds }
    );

    if (isLoading) return null;

    // Create a map of roles by ID for quick lookup
    const rolesMap = roles.reduce((acc, role) => {
        acc[role.id] = role;
        return acc;
    }, {});

    // Use the original roleIds order to render the chips
    return (
        <Box>
            {roleIds.map((roleId) => {
                const role = rolesMap[roleId];
                if (!role) return null;
                return (
                    <Chip 
                        key={roleId}
                        label={role[rolesFields.roleName]}
                        sx={{ mr: 1, mb: 1 }}
                    />
                );
            })}
        </Box>
    );
};

export default OrderedProviderRoles; 