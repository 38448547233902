import {
    SET_CSV_FILE,
    SET_DATA_MONTH,
    SET_DATA_YEAR,
    TOGGLE_DATA_DIALOG,
    SET_FILE_STATUS,
    SET_VALIDATION_ERRORS,
    SET_UPLOAD_PROGRESS,
    FILE_STATUS
} from './orgShowTypes';

export const reducer = (state, action) => {
    switch (action.type) {
        case SET_CSV_FILE:
            return {
                ...state,
                csvFile: action.payload,
                fileStatus: action.payload ? FILE_STATUS.PREPARING : FILE_STATUS.INITIAL,
                validationErrors: []
            };
        case SET_DATA_MONTH:
            return {
                ...state,
                dataMonth: action.payload
            };
        case SET_DATA_YEAR:
            return {
                ...state,
                dataYear: action.payload
            };
        case TOGGLE_DATA_DIALOG:
            return {
                ...state,
                isDataDialogOpen: !state.isDataDialogOpen
            };
        case SET_FILE_STATUS:
            return {
                ...state,
                fileStatus: action.payload
            };
        case SET_VALIDATION_ERRORS:
            return {
                ...state,
                validationErrors: action.payload,
                fileStatus: FILE_STATUS.VALIDATION_ERROR
            };
        case SET_UPLOAD_PROGRESS:
            return {
                ...state,
                uploadProgress: action.payload
            };
        default:
            return state;
    }
}; 