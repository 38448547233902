import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  TopToolbar,
  FilterButton,
  ExportButton,
  useListContext, useNotify,
} from 'react-admin';
import { queuesFields } from '../queues.model';
import QueueLinkField from './fields/queueLinkField.component';
import PatientLinkField from './fields/patientLinkField.component';
import DefaultPlaceholderField from '../../material/defPlaceholderField.component';
import {DATE_TIME_OPTIONS} from "@/app/app.component";
import ProviderLinkField from "./fields/providerLinkField.component";
import { QueueFilters } from './utils/queueFilters';
import { queueExporter } from "./utils/queueExport";
import OrganizationLinkField from "@/queues/component/fields/organizationField.component";
import {DEFAULT_LIST_PERPAGE} from "utils/constants";

const ListActions = () => {
  const { total } = useListContext();
  const notify = useNotify();

  return (
    <TopToolbar>
      <FilterButton />
      <ExportButton
        disabled={total === 0}
        exporter={(queues) => queueExporter(queues, notify)}
      />
    </TopToolbar>
  );
};

const QueueList = () => (
  <List
    filters={QueueFilters}
    exporter={queueExporter}
    actions={<ListActions />}
    perPage={DEFAULT_LIST_PERPAGE}
    sort={{ field: queuesFields.id, order: 'DESC' }}
    filterDefaultValues={{
      id: '',
    }}
    displayedFilters={{
      id: true,
    }}
  >
    <Datagrid rowClick={false} bulkActionButtons={false}>
      <QueueLinkField source={queuesFields.id} label="Id" addLabel />
      <DefaultPlaceholderField
        source={queuesFields.type}
        label="Type"
        wrapField={<TextField source={queuesFields.type} />}
      />
      <DefaultPlaceholderField
        source={queuesFields.lang}
        label="Language"
        wrapField={<TextField source={queuesFields.lang} />}
      />
      <DefaultPlaceholderField
        source={queuesFields.status}
        label="Status"
        wrapField={<TextField source={queuesFields.status} />}
      />
      <DefaultPlaceholderField
        source={queuesFields.consultation}
        label="Consultation"
        wrapField={<TextField source={queuesFields.consultation} />}
      />
      <DefaultPlaceholderField
        source={queuesFields.service}
        label="Service"
        wrapField={<TextField source={queuesFields.service} />}
      />
      <DefaultPlaceholderField
        source={queuesFields.roleName}
        label="Role"
        wrapField={<TextField source={queuesFields.roleName} />}
      />
      <PatientLinkField source={queuesFields.patientId} label="Patient" addLabel />
      <DefaultPlaceholderField
        source={queuesFields.patientFeedback}
        label="Patient Feedback"
        wrapField={<TextField source={queuesFields.patientFeedback} />}
      />
      <DefaultPlaceholderField
        source={queuesFields.patientRating}
        label="Patient Rating"
        wrapField={<TextField source={queuesFields.patientRating} />}
      />
      <ProviderLinkField source={queuesFields.providerId} label="Provider" addLabel />
      <OrganizationLinkField source={queuesFields.organizations} label="Organization" />
      <DefaultPlaceholderField
        source={queuesFields.startedAt}
        options={DATE_TIME_OPTIONS}
        wrapField={<DateField/>}
        label="Started At"
      />
      <DefaultPlaceholderField
        source={queuesFields.acceptedAt}
        options={DATE_TIME_OPTIONS}
        wrapField={<DateField/>}
        label="Accepted At"
      />
      <DefaultPlaceholderField
        source={queuesFields.endedAt}
        options={DATE_TIME_OPTIONS}
        wrapField={<DateField/>}
        label="Ended At"
      />
    </Datagrid>
  </List>
);

export default QueueList;
