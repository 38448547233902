import React from 'react';
import BookIcon from '@mui/icons-material/Book';
import { useRecordContext } from 'react-admin';
import { versionsFields } from '../../versions.model';

const VersionTitle = () => {
    const record = useRecordContext();
    if (!record) return null;

    return (
        <div sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 1
        }}>
            <BookIcon sx={{ verticalAlign: 'middle' }}/>
            <span>{record[versionsFields.version]}</span>
        </div>
    );
};

export default VersionTitle;
