import Papa from "papaparse";

export const REQUIRED_HEADERS = [
    "Plan",
    "Medicaid ID",
    "Firstname",
    "Lastname",
    "Email",
    "Phone",
    "Dateofbirth",
    "Race",
    "Language",
    "Address",
    "Address 2",
    "City",
    "State",
    "Zipcode",
    "Nicu",
    "Highrisk",
    "Due date/Delivery date",
];

export function checkForDuplicates(rows, key) {
    const values = rows
        .map((row) => row[key].trim())
        .filter((value) => value !== "");
    return values.filter(
        (value) => values.indexOf(value) !== values.lastIndexOf(value)
    );
}

export function validateHeaders(headers) {
    return REQUIRED_HEADERS.filter(header => !headers.includes(header));
}

export function isValidDate(date) {
    const dateRegex = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}/;
    return dateRegex.test(date);
}

export function isValidEmailOrPhone(value) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;
    return emailRegex.test(value) || phoneRegex.test(value);
}

export function validateRow(row, rowIndex) {
    const hasMedicaidID = row["Medicaid ID"] && row["Medicaid ID"].trim() !== "";
    const hasValidEmailOrPhone =
        (row.Email && isValidEmailOrPhone(row.Email)) ||
        (row.Phone && isValidEmailOrPhone(row.Phone));

    if (!hasMedicaidID && !hasValidEmailOrPhone) {
        return `Row ${rowIndex + 1}: At least one of Medicaid ID, Email or Phone must be present and valid`;
    }

    if (row.Email && !isValidEmailOrPhone(row.Email)) {
        return `Row ${rowIndex + 1}: Invalid Email format`;
    }

    if (row.Phone && !isValidEmailOrPhone(row.Phone)) {
        return `Row ${rowIndex + 1}: Invalid Phone format`;
    }

    if (!isValidDate(row.Dateofbirth)) {
        return `Row ${rowIndex + 1}: Invalid Dateofbirth format`;
    }

    if (!isValidDate(row["Due date/Delivery date"])) {
        return `Row ${rowIndex + 1}: Invalid Due date/Delivery date format`;
    }

    return null;
}

export function parseAndValidateCSV(csvText) {
    const parsedCSV = Papa.parse(csvText, {
        header: true,
        skipEmptyLines: true,
    });

    return {
        data: parsedCSV.data,
        headers: parsedCSV.meta.fields,
        missingHeaders: validateHeaders(parsedCSV.meta.fields),
        duplicates: {
            emails: checkForDuplicates(parsedCSV.data, "Email"),
            phones: checkForDuplicates(parsedCSV.data, "Phone"),
            medicaidIDs: checkForDuplicates(parsedCSV.data, "Medicaid ID")
        }
    };
} 