import React from 'react';
import CodeIcon from '@mui/icons-material/Code';
import { codesFields } from '../../../sdk-codes/codes.model';

export default ({ record = {} }) => {
    return (
        <div>
            <CodeIcon style={{verticalAlign: 'middle'}}/>
            <span style={{verticalAlign: 'middle'}}>&nbsp;{record[codesFields.code]}</span>
        </div>
    );
};
