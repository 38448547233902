import {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { Title } from 'react-admin';
import { Grid, Typography, Card, CardContent, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const AnalyticsTitle = ({ record }) => {
    if (!record) return null;
    return <Title title={`Analytics for ${new Date(record.timestamp).toLocaleDateString()}`} />;
};

const MilestoneTypeChart = ({ data }) => {
    if (!data?.milestoneTypeCounts) return null;

    const chartData = [
        { name: 'Prenatal', value: data.milestoneTypeCounts.prenatal.total },
        { name: 'Postnatal', value: data.milestoneTypeCounts.postnatal.total }
    ];

    return (
        <Card>
            <CardContent>
                <Typography variant="h6">Milestone Type Distribution</Typography>
                <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer>
                        <PieChart>
                            <Pie
                                data={chartData}
                                cx="50%"
                                cy="50%"
                                labelLine={true}
                                label={({ name, value }) => `${name}: ${value}`}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                            >
                                {chartData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                            <Legend />
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );
};

const NotificationTypeChart = ({ data }) => {
    if (!data?.notificationTypeCounts) return null;

    const chartData = Object.entries(data.notificationTypeCounts).map(([type, count]) => ({
        name: type,
        count: count
    }));

    return (
        <Card>
            <CardContent>
                <Typography variant="h6">Notification Type Distribution</Typography>
                <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer>
                        <BarChart data={chartData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="count" name="Count" fill="#8884d8" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );
};

const DaysDistributionChart = ({ data }) => {
    if (!data?.milestoneTypeCounts) return null;

    // Get all unique days and sort them
    const allDays = new Set([
        ...Object.keys(data.milestoneTypeCounts.prenatal.byDaysFromDueDate),
        ...Object.keys(data.milestoneTypeCounts.postnatal.byDaysFromDueDate)
    ]);
    
    const chartData = Array.from(allDays)
        .map(day => ({
            day: parseInt(day),
            prenatal: data.milestoneTypeCounts.prenatal.byDaysFromDueDate[day] || 0,
            postnatal: data.milestoneTypeCounts.postnatal.byDaysFromDueDate[day] || 0
        }))
        .sort((a, b) => a.day - b.day);

    return (
        <Card>
            <CardContent>
                <Typography variant="h6">Days from Due Date Distribution</Typography>
                <div style={{ width: '100%', height: 400 }}>
                    <ResponsiveContainer>
                        <BarChart 
                            data={chartData}
                            margin={{ top: 20, right: 30, left: 20, bottom: 70 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis 
                                dataKey="day" 
                                angle={-45}
                                textAnchor="end"
                                height={70}
                                interval={0}
                                tick={{ fontSize: 12 }}
                            />
                            <YAxis 
                                label={{ 
                                    value: 'Count', 
                                    angle: -90, 
                                    position: 'insideLeft',
                                    offset: -5
                                }} 
                            />
                            <Tooltip 
                                formatter={(value, name) => [value, name === 'prenatal' ? 'Prenatal' : 'Postnatal']}
                                labelFormatter={(value) => `Day ${value}`}
                            />
                            <Legend />
                            <Bar 
                                dataKey="prenatal" 
                                name="Prenatal" 
                                fill="#0088FE" 
                                stackId="stack"
                            />
                            <Bar 
                                dataKey="postnatal" 
                                name="Postnatal" 
                                fill="#00C49F" 
                                stackId="stack"
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );
};

const MilestoneTypeBreakdown = ({ data }) => {
    if (!data?.milestoneTypeCounts) return null;

    return (
        <Card>
            <CardContent>
                <Typography variant="h6">Milestone Type Breakdown</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography>
                            Prenatal: {data.milestoneTypeCounts.prenatal.total}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>
                            Postnatal: {data.milestoneTypeCounts.postnatal.total}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

const NotificationTypeBreakdown = ({ data }) => {
    if (!data?.notificationTypeCounts) return null;

    return (
        <Card>
            <CardContent>
                <Typography variant="h6">Notification Type Breakdown</Typography>
                <Grid container spacing={2}>
                    {Object.entries(data.notificationTypeCounts).map(([type, count]) => (
                        <Grid item xs={6} key={type}>
                            <Typography>
                                {type}: {count}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>
    );
};

const DayBreakdown = ({ data }) => {
    if (!data?.milestoneTypeCounts) return null;

    // Get all unique days and sort them
    const allDays = new Set([
        ...Object.keys(data.milestoneTypeCounts.prenatal.byDaysFromDueDate),
        ...Object.keys(data.milestoneTypeCounts.postnatal.byDaysFromDueDate)
    ]);
    const sortedDays = Array.from(allDays)
        .map(day => parseInt(day))
        .sort((a, b) => a - b);

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>Days from Due Date Breakdown</Typography>
                <div style={{ overflowX: 'auto' }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Days from Due Date</TableCell>
                                <TableCell align="right">Prenatal Count</TableCell>
                                <TableCell align="right">Postnatal Count</TableCell>
                                <TableCell align="right">Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedDays.map(day => {
                                const prenatalCount = data.milestoneTypeCounts.prenatal.byDaysFromDueDate[day] || 0;
                                const postnatalCount = data.milestoneTypeCounts.postnatal.byDaysFromDueDate[day] || 0;
                                return (
                                    <TableRow key={day}>
                                        <TableCell component="th" scope="row">{day}</TableCell>
                                        <TableCell align="right">{prenatalCount}</TableCell>
                                        <TableCell align="right">{postnatalCount}</TableCell>
                                        <TableCell align="right">{prenatalCount + postnatalCount}</TableCell>
                                    </TableRow>
                                );
                            })}
                            <TableRow>
                                <TableCell component="th" scope="row"><strong>Total</strong></TableCell>
                                <TableCell align="right"><strong>{data.milestoneTypeCounts.prenatal.total}</strong></TableCell>
                                <TableCell align="right"><strong>{data.milestoneTypeCounts.postnatal.total}</strong></TableCell>
                                <TableCell align="right"><strong>{data.milestoneTypeCounts.prenatal.total + data.milestoneTypeCounts.postnatal.total}</strong></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </CardContent>
        </Card>
    );
};

export const PregnancyMilestoneNotificationAnalyticsShow = props => {
    const location = useLocation();
    const [analyticsRecord, setAnalyticsRecord] = useState(null);
    
    useEffect(() => {
        // If we receive a record in location state, store it
        if (location.state?.state?.record) {
            setAnalyticsRecord(location.state.state.record);
        }
    }, [location]);

    // If no record available, show error
    if (!analyticsRecord) {
        return (
            <Card>
                <CardContent>
                    <Typography color="error">
                        No analytics data available. Please return to the list view and try again.
                    </Typography>
                </CardContent>
            </Card>
        );
    }

    return (
        <div style={{ padding: '1em' }}>
            <AnalyticsTitle record={analyticsRecord} />
            <Grid container spacing={3}>
                {/* Summary Section */}
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Summary</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="subtitle2">Run Time</Typography>
                                    <Typography>
                                        {new Date(analyticsRecord.timestamp).toLocaleString()}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="subtitle2">Total Users Processed</Typography>
                                    <Typography>
                                        {analyticsRecord.totalUsersProcessed}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="subtitle2">Total Templates Used</Typography>
                                    <Typography>
                                        {analyticsRecord.totalTemplatesUsed}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Charts Section */}
                <Grid item xs={12} md={6}>
                    <MilestoneTypeChart data={analyticsRecord} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <NotificationTypeChart data={analyticsRecord} />
                </Grid>
                <Grid item xs={12}>
                    <DaysDistributionChart data={analyticsRecord} />
                </Grid>

                {/* Breakdowns Section */}
                <Grid item xs={12} md={6}>
                    <MilestoneTypeBreakdown data={analyticsRecord} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <NotificationTypeBreakdown data={analyticsRecord} />
                </Grid>
                <Grid item xs={12}>
                    <DayBreakdown data={analyticsRecord} />
                </Grid>
            </Grid>
        </div>
    );
}; 