import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE
} from 'react-admin';
import queryString from 'query-string';
import { callsFields } from "./calls.model";

const API_URL = import.meta.env.VITE_API_URL;

export const RESOURCE_NAME = "calls";
export const POST_UPDATE_CHART = "POST_UPDATE_CHART";

const providerRequestToHttpRequest = (requestType, requestParams) => {
    switch (requestType) {
        case GET_LIST: 
        // console.log("requestParams: ", requestParams);                    
            return composeGetCallsListRequest(requestParams);
        case GET_ONE:
            return { url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}` };
        case GET_MANY: {
            const query = {
                filter: JSON.stringify({ id: requestParams.ids })              
            };
            return { url: `${API_URL}/${RESOURCE_NAME}?${queryString.stringify(query)}` };
        }
        case GET_MANY_REFERENCE: {
            const { page, perPage } = requestParams.pagination;
            const { field, order } = requestParams.sort;
            const query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([(page - 1) * perPage, (page * perPage) - 1]),
                filter: JSON.stringify({ ...requestParams.filter, [requestParams.target]: requestParams.id }),
            };
            return { url: `${API_URL}/${RESOURCE_NAME}?${queryString.stringify(query)}` };
        }
        case UPDATE:
            return {
                url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}`,
                options: { method: 'PUT', body: JSON.stringify(requestParams.data) },
            };
        case CREATE:
            return {
                url: `${API_URL}/${RESOURCE_NAME}`,
                options: { method: 'POST', body: JSON.stringify(requestParams.data) },
            };
        case POST_UPDATE_CHART:
          return {
              url: `${API_URL}/${RESOURCE_NAME}/updatechart`,
              options: {
                  method: 'POST',
                  body: JSON.stringify(requestParams) // not 'requestParams' at all, but body for a post.
              },
          };
        default:
            throw new Error(`Unsupported fetch action type ${requestType}`);
    }
};

const composeGetCallsListRequest = (requestParams) => {
    const { page, perPage } = requestParams.pagination;
    const { field, order } = requestParams.sort;
    let prepareFilters = () => {
        let requestFilters = requestParams.filter;
        if (requestFilters === undefined) {
            return {};
        }
        let createEqFilter = (name, value) => ({ name, comparison: "eq", value });
        let createNeqFilter = (name, value) => ({ name, comparison: "neq", value });
        let createContainsFilter = (name, value) => ({ name, comparison: "contains", value });
        let createDateBetweenFromFilter = (name, value) => ({ name, comparison: "betweenFrom", value });
        let createDateBetweenToFilter = (name, value) => ({ name, comparison: "betweenTo", value });

        // console.log("requestParams: " + JSON.stringify(requestParams));

        let resultFilters = [];
        // append "call id" filter
        if (requestFilters.hasOwnProperty(callsFields.id)) {
            resultFilters.push(createContainsFilter('id', requestFilters[callsFields.id]));
        }
        // append "patient id" filter
        if (requestFilters.hasOwnProperty(callsFields.patientId)) {
            resultFilters.push(createContainsFilter('patientId', requestFilters[callsFields.patientId]));
        }
        // append "patient first name" contains
        if (requestFilters.hasOwnProperty(callsFields.patientFirstName)) {
            resultFilters.push(createContainsFilter('patientFirstName', requestFilters[callsFields.patientFirstName]));
        }
        // append "patient last name" contains
        if (requestFilters.hasOwnProperty(callsFields.patientLastName)) {
            resultFilters.push(createContainsFilter('patientLastName', requestFilters[callsFields.patientLastName]));
        }
        // append "provider first name" contains
        if (requestFilters.hasOwnProperty(callsFields.providerFirstName)) {
            resultFilters.push(createContainsFilter('providerFirstName', requestFilters[callsFields.providerFirstName]));
        }
        // append "provider last name" contains
        if (requestFilters.hasOwnProperty(callsFields.providerLastName)) {
            resultFilters.push(createContainsFilter('providerLastName', requestFilters[callsFields.providerLastName]));
        }
        // append "orgId" filter
        if (requestFilters.hasOwnProperty(callsFields.patientOrgId)) {
            resultFilters.push(createEqFilter('patientOrgId', requestFilters[callsFields.patientOrgId]));
        }
        // append "groupId" filter
        if (requestFilters.hasOwnProperty(callsFields.groupId)) {
            resultFilters.push(createEqFilter('groupId', requestFilters[callsFields.groupId]));
        }
        // append "call status" filter
        if (requestFilters.hasOwnProperty(callsFields.status)) {
            resultFilters.push(createEqFilter('status', requestFilters[callsFields.status]));
        }
        // append "provider chart" filter
        if (requestFilters.hasOwnProperty(callsFields.providerChartType)) {
            resultFilters.push(createEqFilter('chartType', requestFilters[callsFields.providerChartType]));
        }
        // append "created at" filter
        if (requestFilters.hasOwnProperty('startedBetween')) {
            let between = requestFilters['startedBetween'];
            // console.log("started at from startedBetween: ", between)
            if (between.gte != null) {
                // console.log("started at from startedBetween.gte: ", between.gte)
                resultFilters.push(createDateBetweenFromFilter('startedAtFrom', between.gte));
            }
            if (between.lte != null) {
                // console.log("started at to startedBetween.lte: ",  between.lte)
                resultFilters.push(createDateBetweenToFilter('startedAtTo', between.lte));
            }
        }
        // append "ended at" filter
        if (requestFilters.hasOwnProperty('endedBetween')) {
            let between = requestFilters['endedBetween'];
            // console.log("ended at from endedBetween: ", between)
            if (between.gte != null) {
                // console.log("ended at from between.gte: ", between.gte)
                resultFilters.push(createDateBetweenFromFilter('endedAtFrom', between.gte));
            }
            if (between.lte != null) {
                // console.log("ended at to between.lte: ", between.lte)
                resultFilters.push(createDateBetweenToFilter('endedAtTo', between.lte));
            }
        }
        // append "service" filter
        if (requestFilters.hasOwnProperty(callsFields.service)) {
            resultFilters.push(createEqFilter(callsFields.service, requestFilters[callsFields.service]));
        }
        if (requestFilters.hasOwnProperty('service_neq')) {
            const valueToExclude = Object.keys(requestFilters['service_neq'])[0];
            if (requestFilters['service_neq'][valueToExclude]) {
                resultFilters.push(createNeqFilter(callsFields.service, valueToExclude));
            }
        }
        return resultFilters;
    };

    const queryParams = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage]),
        filters: JSON.stringify(prepareFilters()),
        export: requestParams.export
    };

    return { 
        url: `${API_URL}/${RESOURCE_NAME}-get`,
        options: { method: 'POST', body: JSON.stringify(queryParams) }
    };
}

const httpResponseToProviderData = (httpResponse, requestType, requestParams) => {
    // console.log("calls response: ", httpResponse.json)
    var { headers, json } = httpResponse;
    switch (requestType) {
        case GET_LIST:
            if (json['url'] != null) { // Large Export Hack -- a url is returned to a presigned s3 file for download, handled downstream.
              return { data: [{ id: 9999999, url: json['url']}], total: 9999999 }; // Have to use this format as part of the hack to slip through react-admin validation
            }
            return {
                data: json.map(x => x),
                total: parseInt(headers.get('content-range').split('/').pop()),
            };
        case CREATE:
            return { data: { ...requestParams.data, id: json.id } };
        default:
            return { data: json };
    }
};

export default {
    resource: RESOURCE_NAME,
    providerInterface: {
        providerRequestToHttpRequest,
        httpResponseToProviderData
    },
};
