import React from 'react';
import { Link, useRecordContext } from 'react-admin';
import { queuesFields } from "../../queues.model";
import { RESOURCE_NAME as PATIENT_RESOURCE_NAME} from "../../../users/patient.dataProvider";
import EmptyField from "@/material/emptyField.component";

const PatientLinkField = ({ source, addLabel = true }) => {
    const record = useRecordContext();

    if (!record || !source) {
        return null;
    }

    const value = record[source];
    if (!value) {
        return <EmptyField />;
    }

    const label = `${record[queuesFields.patientFirstName]} ${record[queuesFields.patientLastName]}`

    return (
        <Link to={`/${PATIENT_RESOURCE_NAME}/${value}/show`}>
            {label}
        </Link>
    );
};

export default PatientLinkField;
