import React from 'react';
import { useRecordContext } from 'react-admin';
import { versionsFields } from '../../versions.model';

const VersionPlatform = ({ label = 'Platform' }) => {
    const record = useRecordContext();
    if (!record) return null;

    let platform;
    switch (record[versionsFields.platform]) {
        case 1:
            platform = "iOS";
            break;
        case 2:
            platform = "Android";
            break;     
        default:
            platform = "Not Defined";
            break;
    }

    return (
        <div sx={{ 
            display: 'flex',
            alignItems: 'center',
            fontSize: '0.875rem'
        }}>
            {platform}
        </div>
    );
};

export default VersionPlatform;
