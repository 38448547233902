import React from 'react';
import { useRecordContext, TextField } from 'react-admin';
import { callsFields, EndCallReasonNames } from '../../calls.model';
import DefaultPlaceholderField from '../../../material/defPlaceholderField.component';

const PatientEndReason = ({ label = 'Patient End Reason' }) => {
    const record = useRecordContext();
    if (!record) return null;

    // Convert empty or invalid end reason to null
    const rawEndReason = record[callsFields.patientEndReason];
    const endReason = rawEndReason && EndCallReasonNames[rawEndReason] ? EndCallReasonNames[rawEndReason] : null;

    return (
        <DefaultPlaceholderField 
            source={callsFields.patientEndReason}
            record={{ [callsFields.patientEndReason]: endReason }}
            label={label}
            wrapField={<TextField />}
        />
    );
};

export default PatientEndReason;
