import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDataProvider, useRedirect, Link } from 'react-admin';
import { PATIENT_RESOURCE } from '../../users/constants';

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        padding: '16px',
    },
    headerCell: {
        fontWeight: 'bold',
    },
    clickableRow: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },
    idLink: {
        textDecoration: 'none',
        color: '#2196f3',
        '&:hover': {
            textDecoration: 'underline',
        }
    },
    statusCell: {
        fontWeight: 'bold',
        '&.completed': {
            color: '#4caf50',
        },
        '&.canceled': {
            color: '#f44336',
        },
        '&.missed': {
            color: '#ff9800',
        }
    }
}));

const DashboardRecentCalls = () => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    
    useEffect(() => {
        let isMounted = true;

        const fetchCalls = async () => {
            try {
                // Using the dashboard provider to get recent calls
                const { data: dashboardData } = await dataProvider.getDashboardStats();
                
                if (isMounted && dashboardData && dashboardData.recentCalls) {
                    setData(dashboardData.recentCalls);
                    setIsLoading(false);
                }
            } catch (error) {
                if (isMounted) {
                    console.error('Error fetching recent calls:', error);
                    setIsLoading(false);
                }
            }
        };

        fetchCalls();

        return () => {
            isMounted = false;
        };
    }, [dataProvider]);

    const handleRowClick = (id, e) => {
        // Don't trigger row click if clicking the ID link or its parent cell
        if (e.target.tagName.toLowerCase() === 'a' || e.target.closest('a')) {
            e.stopPropagation();
            return;
        }
        redirect('show', 'queues', id);
    };

    const getStatusClass = (status) => {
        status = status.toLowerCase();
        if (status === 'completed') return 'completed';
        if (status === 'canceled') return 'canceled';
        if (status === 'missed') return 'missed';
        return '';
    };

    if (isLoading) {
        return (
            <Card>
                <CardHeader title="Recent Calls" />
                <div className={classes.tableContainer}>Loading...</div>
            </Card>
        );
    }

    if (!data || data.length === 0) {
        return (
            <Card>
                <CardHeader title="Recent Calls" />
                <div className={classes.tableContainer}>No recent calls</div>
            </Card>
        );
    }

    return (
        <Card>
            <CardHeader title="Recent Calls" />
            <TableContainer className={classes.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.headerCell}>ID</TableCell>
                            <TableCell className={classes.headerCell}>Patient</TableCell>
                            <TableCell className={classes.headerCell}>Provider</TableCell>
                            <TableCell className={classes.headerCell}>Started At</TableCell>
                            <TableCell className={classes.headerCell}>Duration</TableCell>
                            <TableCell className={classes.headerCell}>Status</TableCell>
                            <TableCell className={classes.headerCell}>Language</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map(call => {
                            // Calculate duration if both started_at and ended_at exist
                            let duration = '';
                            if (call.started_at && call.ended_at) {
                                const start = new Date(call.started_at);
                                const end = new Date(call.ended_at);
                                const durationMs = end - start;
                                const minutes = Math.floor(durationMs / 60000);
                                const seconds = Math.floor((durationMs % 60000) / 1000);
                                duration = `${minutes}m ${seconds}s`;
                            }

                            return (
                                <TableRow 
                                    key={call.id}
                                    onClick={(e) => handleRowClick(call.id, e)}
                                    className={classes.clickableRow}
                                    hover
                                >
                                    <TableCell>
                                        <Link 
                                            to={`/queues/${call.id}/show`}
                                            className={classes.idLink}
                                        >
                                            {call.id}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        <Link 
                                            to={`/${PATIENT_RESOURCE}/${call.patient_id}/show`}
                                            className={classes.idLink}
                                        >
                                            {call.patient_id}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        {call.provider_id ? (
                                            <Link 
                                                to={`/providers/${call.provider_id}/show`}
                                                className={classes.idLink}
                                            >
                                                {call.provider_id}
                                            </Link>
                                        ) : (
                                            'N/A'
                                        )}
                                    </TableCell>
                                    <TableCell>{new Date(call.started_at).toLocaleString()}</TableCell>
                                    <TableCell>{duration || 'N/A'}</TableCell>
                                    <TableCell className={`${classes.statusCell} ${getStatusClass(call.status)}`}>
                                        {call.status}
                                    </TableCell>
                                    <TableCell>{call.lang}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
};

export default DashboardRecentCalls; 