import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    EditButton,
    DeleteButton,
    TopToolbar,
    FilterButton,
    CreateButton,
    ExportButton,
    TextInput,
    SelectInput
} from 'react-admin';
import { versionsFields } from '../versions.model';
import DefaultPlaceholderField from '../../material/defPlaceholderField.component';
import YesNoField from '../../material/yesNoField.component';
import VersionPlatform from './fields/versionPlatform.component';
import VersionAppType from './fields/versionAppType.component';
import VersionLinkField from './fields/versionLink.component';

const ListActions = () => (
    <TopToolbar>
        <FilterButton />
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

const versionFilters = [
    <TextInput source={versionsFields.version} label="Version" alwaysOn />,
    <TextInput source={versionsFields.build} label="Build" />,
    <SelectInput 
        source={versionsFields.platform} 
        label="Platform"
        choices={[
            { id: 1, name: "iOS" },
            { id: 2, name: "Android" }
        ]}
    />,
    <SelectInput 
        source={versionsFields.appType} 
        label="App Type"
        choices={[
            { id: 1, name: "Patient" },
            { id: 2, name: "Provider" }
        ]}
    />
];

const VersionsList = () => {
    return (
        <List 
            title="App Versions" 
            sort={{ field: 'id', order: 'DESC' }} 
            actions={<ListActions />}
            filters={versionFilters}
            perPage={25}
        >
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <VersionLinkField label="Id" source={versionsFields.id}/>
                <VersionPlatform label="Platform" />
                <VersionAppType label="App Type" />
                <DefaultPlaceholderField source={versionsFields.version} wrapField={<TextField/>} label="Version" />
                <YesNoField source={versionsFields.forceUpdate} wrapField={<TextField/>} label="Force Update" />
                <DefaultPlaceholderField source={versionsFields.build} wrapField={<TextField/>} label="Build" />   
                <DefaultPlaceholderField source={versionsFields.releaseNotes} wrapField={<TextField/>} label="Release Notes" />
                <ShowButton />
                <EditButton />
                <DeleteButton mutationMode="pessimistic"/>
            </Datagrid>
        </List>
    );
};

export default VersionsList;
