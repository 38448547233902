// import React from 'react';
// import {
//     Labeled,
//     TextField
// } from 'react-admin';
// import moment from 'moment';

// const UserSubscriptionField = (props) => {
//     let { source, record, ...rest } = props;
//     if (record.recurly_uuid === null || !record.subscription) {
//         return null;
//     }
//     // console.log("### date from BE " + record.subscription.current_period_ends_at);
//     let endsString = record.subscription.current_period_ends_at > new Date() ? ", ends on " : ", ended on "
//     let subscriptionDescription = record.subscription.plan && record.subscription.plan.name ? record.subscription.plan.name 
//                                     + endsString 
//                                     + moment(record.current_period_ends_at).format('MMMM DD, YYYY hh:mm:ss Z')
//                                     : "loading ...";
    
//     let subscroptionDescriptionKey = 'subscrDesc';
//     let recordCopy = {
//         ...record,
//         [subscroptionDescriptionKey]: subscriptionDescription,
//     };
//     return (<Labeled label="Subscription">
//                 <TextField source={subscroptionDescriptionKey} 
//                             record={recordCopy} 
//                             {...rest} />
//             </Labeled>)
// };

// export default UserSubscriptionField;

import React from 'react';
import {
    Labeled,
    TextField
} from 'react-admin';
import moment from 'moment';

const UserSubscriptionField = (props) => {
    let { source, record, ...rest } = props;
    if (record.recurly_uuid === null) {
        return null;
    }
    // console.log("### date from BE " + record.subscription_period_ends);
    // console.log("### date" + moment(record.subscription_period_ends).format('MMMM DD, YYYY hh:mm:ss Z'));
    let endsString = moment(record.subscription_period_ends) > moment(new Date()) ? ", ends on " : ", ended on "
    let subscriptionDescription = record.subscription_plan_name? record.subscription_plan_name 
                                    + endsString 
                                    + moment(record.subscription_period_ends).format('MMMM DD, YYYY hh:mm:ss Z')
                                    : "loading ...";
    
    let subscroptionDescriptionKey = 'subscrDesc';
    let recordCopy = {
        ...record,
        [subscroptionDescriptionKey]: subscriptionDescription,
    };
    return (<Labeled label="Subscription">
                <TextField source={subscroptionDescriptionKey} 
                            record={recordCopy} 
                            {...rest} />
            </Labeled>)
};

export default UserSubscriptionField;