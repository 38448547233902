import React from 'react';
import { useRecordContext, TextField } from 'react-admin';
import DefaultPlaceholderField from '../../../material/defPlaceholderField.component';
import { secondsToDurationString } from "../../../utils/dateFormat";

const QueueTimeLabel = ({ source, addPlaceholder = false, label = 'Time' }) => {
    const record = useRecordContext();
    if (!record) return null;

    const timeString = secondsToDurationString(record[source]);

    if (!timeString) {
        if (addPlaceholder) {
            return (
                <TextField 
                    record={{ text: '00:00:00' }}
                    source="text"
                    label={label}
                />
            );
        }
        return <DefaultPlaceholderField source={source} label={label} wrapField={<TextField />} />;
    }

    return (
        <TextField 
            record={{ text: timeString }}
            source="text"
            label={label}
        />
    );
};

export default QueueTimeLabel;