import { parseISO, format, differenceInDays, isBefore, fromUnixTime } from 'date-fns';
import { userFields } from '../../user.model';
import { useNotify } from 'react-admin';

export const userExporter = async (users, notify = useNotify()) => {
    // Handle large export download
    if (users[0]?.url) {
        notify('Downloading user data...', { type: 'info' });
        try {
            const response = await fetch(users[0].url);
            users = await response.json();
            notify('User data downloaded successfully', { type: 'info' });
        } catch (error) {
            console.error('Error downloading large export:', error);
            notify('Error downloading user data', { type: 'error' });
            return;
        }
    }

    notify('Preparing CSV export...', { type: 'info' });

    const formatDate = (dateStr) => {
        if (!dateStr) return '';
        try {
            const date = parseISO(dateStr);
            return isNaN(date.getTime()) ? '' : format(date, 'yyyy-MM-dd');
        } catch (e) {
            return '';
        }
    };

    const formatTimestamp = (timestamp) => {
        if (!timestamp) return '';
        try {
            const date = fromUnixTime(timestamp/1000);
            return isNaN(date.getTime()) ? '' : format(date, 'HH:mm:ss');
        } catch (e) {
            return '';
        }
    };

    const usersForExport = users.map(user => {
        // Sort relatives by DOB to find youngest child
        const sortedRelatives = (user.relatives || []).sort((a, b) => {
            try {
                const dateA = a.dob ? parseISO(a.dob) : new Date(0);
                const dateB = b.dob ? parseISO(b.dob) : new Date(0);
                return dateA.getTime() - dateB.getTime();
            } catch (e) {
                return 0;
            }
        });
        const youngestChildDOB = sortedRelatives[sortedRelatives.length - 1]?.dob;
        
        // Format family members string
        const familyMembers = sortedRelatives
            .filter(relative => relative && relative.dob)
            .map(relative => {
                const formattedDate = formatDate(relative.dob);
                return formattedDate ? `${relative.name}, ${formattedDate}` : relative.name;
            })
            .join('. ');

        // Handle due date logic
        let isDueDateBeforeCreation = false;
        try {
            const createdAtDate = fromUnixTime(user[userFields.createdAt]/1000);
            const dueDate = user[userFields.dueDate] ? parseISO(user[userFields.dueDate]) : null;
            isDueDateBeforeCreation = dueDate && !isNaN(dueDate.getTime()) && 
                                    !isNaN(createdAtDate.getTime()) && 
                                    isBefore(dueDate, createdAtDate);
        } catch (e) {
            console.warn('Error processing due date:', e);
        }
        
        const createdAtTimestamp = user[userFields.createdAt];
        let daysSinceCreation = '';
        try {
            if (createdAtTimestamp) {
                const createdDate = fromUnixTime(createdAtTimestamp/1000);
                if (!isNaN(createdDate.getTime())) {
                    daysSinceCreation = differenceInDays(new Date(), createdDate);
                }
            }
        } catch (e) {
            console.warn('Error calculating days since creation:', e);
        }
        
        return {
            'Id': user[userFields.id],
            'Email': user[userFields.email],
            'First name': user[userFields.firstName],
            'Last name': user[userFields.lastName],
            'Phone': user[userFields.phoneNumber],
            'Role': user[userFields.roleName],
            'Dob': formatDate(user[userFields.dob]),
            'Due date': isDueDateBeforeCreation ? '' : formatDate(user[userFields.dueDate]),
            'Youngest child dob': isDueDateBeforeCreation ? formatDate(user[userFields.dueDate]) : formatDate(youngestChildDOB),
            'Lang': user[userFields.lang],
            'Recurly active': user[userFields.recurlyActive],
            'Is active': user[userFields.isActive],
            'Organization': user[userFields.orgName],
            'Organization code': user.orgCodes?.map(code => code.name).join(),
            'Gift': user.gifts?.map(code => code.code).join(),
            'Created at date': createdAtTimestamp ? format(fromUnixTime(createdAtTimestamp/1000), 'yyyy-MM-dd') : '',
            'Created at time': createdAtTimestamp ? format(fromUnixTime(createdAtTimestamp/1000), 'HH:mm:ss') : '',
            'Days since account creation': daysSinceCreation,
            'State': user[userFields.usState],
            'Zip': user[userFields.zip],
            'Race': user[userFields.race],
            'Birth Wish List': user[userFields.birthWish],
            'Birth Partner': user[userFields.birthPartner],
            'Prenatal Visits': user[userFields.prenatalVisit],
            'Infant Feeding Plan': user[userFields.feedingPlan],
            'Push enabled': user[userFields.pushEnabled],
            'Share feedback': user[userFields.shareFeedback],
            'Family members': familyMembers,
            'Is deleted': user[userFields.isDeleted]
        };
    });

    const fields = [
        'Id', 'Email', 'First name', 'Last name', 'Phone', 'Role', 'Dob', 'Due date', 'Youngest child dob',
        'Lang', 'Recurly active', 'Is active', 'Organization', 'Organization code', 'Gift', 
        'Created at date', 'Created at time', 'Days since account creation', 'State', 'Zip', 'Race',
        'Birth Wish List', 'Birth Partner', 'Prenatal Visits', 'Infant Feeding Plan',
        'Push enabled', 'Share feedback', 'Family members', 'Is deleted'
    ];

    const csvData = fields.join(',') + '\n' + 
        usersForExport.map(user => 
            fields.map(field => {
                const value = user[field];
                return value === null || value === undefined ? '' : `"${String(value).replace(/"/g, '""')}"`;
            }).join(',')
        ).join('\n');

    const timestamp = format(new Date(), 'yyyy-MM-dd-HHmmss');
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `users-${timestamp}.csv`;
    link.click();
    URL.revokeObjectURL(link.href);

    notify('CSV export completed successfully', { type: 'success' });
}; 