import { 
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    DELETE
} from 'react-admin';
import userDataProvider, { userLifecycleCallbacks } from "./user.dataProvider"
import { USER_ROLES } from "../roles/constants";
import { PATIENT_RESOURCE } from './constants';

export const RESOURCE_NAME = PATIENT_RESOURCE;

/**
 * Wraps the user data provider to filter for patient users only
 */
const wrapUserDataProvider = (method, params) => {
    // Add role_name filter for all list/reference operations
    if ([GET_LIST, GET_MANY, GET_MANY_REFERENCE].includes(method)) {
        return {
            ...params,
            filter: {
                ...params?.filter,
                role_name: USER_ROLES.PATIENT
            }
        };
    }
    
    // For create operations, set the role
    if (method === CREATE) {
        return {
            ...params,
            data: {
                ...params.data,
                role_name: USER_ROLES.PATIENT,
                type: "patient"
            }
        };
    }
    
    return params;
};

const providerRequestToHttpRequest = (method, params) => {
    const wrappedParams = wrapUserDataProvider(method, params);
    return userDataProvider.providerInterface.providerRequestToHttpRequest(method, wrappedParams);
};

const httpResponseToProviderData = userDataProvider.providerInterface.httpResponseToProviderData

export default {
  resource: RESOURCE_NAME,
  providerInterface: {
    providerRequestToHttpRequest,
    httpResponseToProviderData
  },
  lifecycleCallbacks: {
    ...userLifecycleCallbacks,
    resource: RESOURCE_NAME
  }
};