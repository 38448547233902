import * as React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    BooleanInput,
    required,
    useNotify,
    useRedirect
} from 'react-admin';
import { versionsFields } from '../versions.model';

const VersionCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify('Version created successfully');
        redirect('list', 'versions');
    };

    return (
        <Create mutationOptions={{ onSuccess }}>
            <SimpleForm>
                <SelectInput 
                    label="Platform" 
                    source={versionsFields.platform}
                    validate={required()}
                    choices={[
                        { id: 1, name: "iOS" },
                        { id: 2, name: "Android" }
                    ]}
                    sx={{ width: 400 }}
                />
                <SelectInput 
                    label="App type" 
                    source={versionsFields.appType}
                    validate={required()}
                    choices={[
                        { id: 1, name: "Patient" },
                        { id: 2, name: "Provider" }
                    ]}
                    sx={{ width: 400 }}
                />
                <TextInput
                    label="Version"
                    source={versionsFields.version} 
                    validate={required()} 
                    multiline
                    sx={{ width: 400 }}
                />
                <BooleanInput  
                    label="Force update" 
                    source={versionsFields.forceUpdate} 
                />
                <TextInput
                    label="Build"
                    source={versionsFields.build} 
                    validate={required()} 
                    multiline
                    sx={{ width: 400 }}
                />
                <TextInput
                    label="Release notes"
                    source={versionsFields.releaseNotes} 
                    multiline
                    sx={{ width: 400 }}
                />
            </SimpleForm>
        </Create>
    );
};

export default VersionCreate;
