import { TextInput, ReferenceInput, SelectInput } from 'react-admin';
import { queuesFields, queueStatus } from '../../queues.model';
import { ALL_RESOURCE_NAME as ORGANIZATIONS_RESOURCE } from '../../../organizations/organizations.dataProvider';
import React from "react";
import PeriodInput from "../../../app/periodInput.component";

const choiceForQueueStatus = (status) => ({
    id: status,
    name: status
});

const queueTypes = [
    { id: 'chat', name: 'Chat' },
    { id: 'audio', name: 'Audio' },
    { id: 'txhotline', name: 'TX Hotline' },
    { id: 'tnhotline', name: 'TN Hotline' },
    { id: 'video', name: 'Video' }
];

export const QueueFilters = [
    <TextInput source={queuesFields.id} label="ID" alwaysOn />,
    <TextInput source={queuesFields.patientId} label="Patient Id" />,
    <TextInput source={queuesFields.patientFirstName} label="Patient First Name" />,
    <TextInput source={queuesFields.patientLastName} label="Patient Last Name" />,
    <TextInput source={queuesFields.providerId} label="Provider Id" />,
    <TextInput source={queuesFields.providerFirstName} label="Provider First Name" />,
    <TextInput source={queuesFields.providerLastName} label="Provider Last Name" />,
    <ReferenceInput
        source={queuesFields.orgId}
        reference={ORGANIZATIONS_RESOURCE}
        label="Organization"
    >
        <SelectInput optionText="name" />
    </ReferenceInput>,
    <SelectInput
        key="status"
        label="Status"
        source={queuesFields.status}
        choices={[
            choiceForQueueStatus(queueStatus.REQUESTED),
            choiceForQueueStatus(queueStatus.ACCEPTED),
            choiceForQueueStatus(queueStatus.CANCELED),
            choiceForQueueStatus(queueStatus.COMPLETED),
            choiceForQueueStatus(queueStatus.MISSED),
            choiceForQueueStatus(queueStatus.ERROR),
        ]}
        alwaysOn
    />,
    <SelectInput
        key="type"
        label="Type"
        source={queuesFields.type}
        choices={queueTypes}
        alwaysOn
    />,
    <PeriodInput
        source="startedBetween"
        fromLabel="Call Started At From"
        toLabel="Call Started At To"
        label="Call Started At"
    />,
    <PeriodInput
        source="endedBetween"
        fromLabel="Call Ended At From"
        toLabel="Call Ended At To"
        label="Call Ended At"
    />,
];