import React from 'react';
import { Link, TextField, useRecordContext } from 'react-admin';
import { rolesFields } from '../../roles.model';
import EmptyField from "@/material/emptyField.component";

const RoleLinkField = props => {
    const record = useRecordContext();
    
    if (!record || !record[rolesFields.roleId]) {
        return <EmptyField />;
    }

    return (
        <Link to={`${record[rolesFields.roleId]}/show`}>
            <TextField source={rolesFields.roleId} style={{ color: 'blue' }} {...props}/>
        </Link>
    );
};

export default RoleLinkField;
