import { AppBar, TitlePortal } from 'react-admin';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CustomUserMenu } from './customUserMenu';

const useStyles = makeStyles((theme) => ({
    appBar: {
        '& .RaAppBar-toolbar': {
            paddingRight: 24
        },
        '& .RaAppBar-title': {
            flex: 1,
            fontSize: '1.25rem',
            lineHeight: '48px',
            margin: 0,
            fontWeight: 500,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            height: 48,
            display: 'inline-flex',
            alignItems: 'center'
        }
    },
    title: {
        flex: 1,
        color: 'white !important',
        fontSize: '1.25rem',
        lineHeight: '54px',
        height: 54,
        display: 'inline-flex',
        marginBottom: '0 !important',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 0,
        fontWeight: 500,
        whiteSpace: 'nowrap'
    }
}));

export const CustomAppBar = (props) => {
    const classes = useStyles();
    
    return (
        <AppBar
            {...props}
            className={classes.appBar}
            userMenu={<CustomUserMenu />}
        >
            <TitlePortal className='RaAppBar-title' />
            <Typography variant="h5" className={classes.title}>
                Pacify Admin Panel
            </Typography>
            <Box flex={1} />
        </AppBar>
    );
}; 