import React from 'react';
import { TextField, useRecordContext } from 'react-admin';
import { callsFields } from '../../calls.model';

const ProviderFullName = ({ label = 'Provider Name', addLabel, ...props }) => {
    const record = useRecordContext();
    if (!record) return null;

    const firstName = record[callsFields.providerFirstName];
    const lastName = record[callsFields.providerLastName];
    const fullName = (firstName || lastName) ? `${firstName || ''} ${lastName || ''}`.trim() : null;
    if (!fullName) return null;

    return (
        <TextField 
            source="fullName" 
            record={{ fullName }} 
            sx={{ color: 'primary.main' }} 
            label={label}
            {...props} 
        />
    );
};

export default ProviderFullName;
