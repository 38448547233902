import React from 'react';
import { UPDATE, useNotify, useRefresh, Button } from 'react-admin';
import dataProvider from '../../../../sdk-app/dataProvider';
import { RESOURCE_NAME as SDK_RESOURCE_NAME } from '../../../users/sdk.user.dataProvider';
import moment from 'moment';

const CancelSubscriptionButton = ({ record }) => {
    const notify = useNotify();
    const refresh = useRefresh();

    const handleClick = () => {
        // console.log("### USER RECORD", record);
        const userForUpdate = {
            id: record.id,
            recurlyUuid: record.recurly_uuid
        };
        
        dataProvider(UPDATE, SDK_RESOURCE_NAME, { data: userForUpdate })
            .then(() => {
                refresh();
                notify('Subscription was cancelled.');
            })
            .catch((e) => {
                notify('Error: subscription was not cancelled.', 'warning');
            });
    };

    return (
        <Button
            variant="contained"
            color="primary"
            label="Cancel and Refund Subscription"
            disabled={moment(new Date()) > moment(record.subscription_period_ends)}
            onClick={handleClick}
        />
    );
};

export default CancelSubscriptionButton;
