import React from 'react';
import { Card, CardHeader } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Loading } from 'react-admin';

const useStyles = makeStyles((theme) => ({
    statsCard: {
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    statValue: {
        fontSize: '2rem',
        fontWeight: 'bold',
        marginBottom: '8px',
    },
    loadingWrapper: {
        minHeight: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

const DashboardStatsCard = ({ title, value, loading }) => {
    const classes = useStyles();
    return (
        <Card className={classes.statsCard}>
            <CardHeader title={title} />
            <div className={classes.statValue}>
                {loading ? (
                    <div className={classes.loadingWrapper}>
                        <Loading />
                    </div>
                ) : value}
            </div>
        </Card>
    );
};

export default DashboardStatsCard; 