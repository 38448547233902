import React, { useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { useDataProvider } from 'react-admin';
import DashboardStatsCard from './DashboardStatsCard';

const DashboardStatsGrid = () => {
    const [stats, setStats] = useState({
        users: { total: 0, loading: true },
        patients: { total: 0, newToday: 0, loading: true },
        providers: { total: 0, newToday: 0, loading: true },
        admins: { total: 0, loading: true },
        organizations: { total: 0, loading: true },
        calls: { total: 0, newToday: 0, loading: true }
    });
    const dataProvider = useDataProvider();

    useEffect(() => {
        let isMounted = true;

        const fetchStats = async () => {
            try {
                console.log('Fetching dashboard stats...');
                const response = await dataProvider.getDashboardStats();
                
                console.log('Dashboard stats response:', response);

                if (isMounted && response.data) {
                    setStats({
                        users: { 
                            total: response.data.totalUsers,
                            loading: false 
                        },
                        patients: { 
                            total: response.data.totalPatients, 
                            newToday: response.data.newPatientsToday,
                            loading: false 
                        },
                        providers: { 
                            total: response.data.totalProviders, 
                            newToday: response.data.newProvidersToday,
                            loading: false 
                        },
                        admins: {
                            total: response.data.totalAdmins,
                            loading: false
                        },
                        organizations: {
                            total: response.data.totalOrganizations,
                            loading: false
                        },
                        calls: { 
                            total: response.data.totalCalls, 
                            newToday: response.data.newCallsToday,
                            loading: false 
                        }
                    });
                }
            } catch (error) {
                console.error('Error fetching dashboard stats:', error);
                if (isMounted) {
                    setStats(prev => ({
                        users: { ...prev.users, loading: false },
                        patients: { ...prev.patients, loading: false },
                        providers: { ...prev.providers, loading: false },
                        admins: { ...prev.admins, loading: false },
                        organizations: { ...prev.organizations, loading: false },
                        calls: { ...prev.calls, loading: false }
                    }));
                }
            }
        };

        fetchStats();

        return () => {
            isMounted = false;
        };
    }, []); 

    return (
        <Box mt={3}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <DashboardStatsCard
                        title="Total Users"
                        value={stats.users.total}
                        loading={stats.users.loading}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <DashboardStatsCard
                        title="Total Organizations"
                        value={stats.organizations.total}
                        loading={stats.organizations.loading}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <DashboardStatsCard
                        title="Total Calls"
                        value={stats.calls.total}
                        loading={stats.calls.loading}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <DashboardStatsCard
                        title="Total Patients"
                        value={stats.patients.total}
                        loading={stats.patients.loading}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <DashboardStatsCard
                        title="Total Providers"
                        value={stats.providers.total}
                        loading={stats.providers.loading}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <DashboardStatsCard
                        title="Total Admins"
                        value={stats.admins.total}
                        loading={stats.admins.loading}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <DashboardStatsCard
                        title="New Patients (Today)"
                        value={stats.patients.newToday}
                        loading={stats.patients.loading}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <DashboardStatsCard
                        title="New Providers (Today)"
                        value={stats.providers.newToday}
                        loading={stats.providers.loading}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <DashboardStatsCard
                        title="New Calls (Today)"
                        value={stats.calls.newToday}
                        loading={stats.calls.loading}
                    />
                </Grid>
               
            </Grid>
        </Box>
    );
};

export default DashboardStatsGrid; 