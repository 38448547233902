import React from 'react';
import DomainIcon from '@mui/icons-material/Domain';
import { useRecordContext, TextField } from 'react-admin';
import { orgsFields } from '../../orgs.model';

const OrgTitle = (props) => {
    const record = useRecordContext(props);
    if (!record) return null;

    return (
        <div>
            <DomainIcon style={{verticalAlign: 'middle'}}/>
            <TextField source={orgsFields.orgName} style={{verticalAlign: 'middle', marginLeft: '8px'}} />
        </div>
    );
};

export default OrgTitle;
