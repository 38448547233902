import React from 'react';
import { useRecordContext } from 'react-admin';
import { userFields } from '../../user.model';

const UserTitle = () => {
    const record = useRecordContext();

    if (!record) {
        return null;
    }

    const email = record[userFields.email];
    if (!email) {
        return <span>User</span>;
    }

    return <span>User {email}</span>;
};

export default UserTitle;
