import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    EmailField,
    DateField,
    EditButton,
    SingleFieldList,
    ArrayField,
    Datagrid,
    Link,
    useRecordContext,
    TopToolbar,
    Button
} from 'react-admin';
import { CardActions, Stack, Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import UserTitle from './fields/userTitle.component';
import YesNoField from '../../material/yesNoField.component';
import ConditionalYesNoField from './fields/ConditionalYesNoField.component';
import DefaultPlaceholderField from '../../material/defPlaceholderField.component';
import { DATE_OPTIONS, DATE_TIME_OPTIONS } from '../../app/app.component';
import { userFields, userRelativeFields } from '../user.model';
import OrganizationLinkField from './fields/OrganizationLinkField.component';
import OrganizationCodeLinkField from './fields/OrganizationCodeLinkField.component';
import GiftsField from './fields/GiftLinkField.component';
import UserRoleField from './fields/userRoleField.component';
import { RESOURCE_NAME as PATIENT_RESOURCE } from "../patient.dataProvider";
import { RESOURCE_NAME as PROVIDER_RESOURCE } from "../provider.dataProvider";
import { RESOURCE_NAME as ADMIN_RESOURCE } from "../admin.dataProvider";

const StyledCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(2)
}));

const UserShowActions = () => {
    const record = useRecordContext();
    const navigate = useNavigate();
    if (!record) return null;

    const resourcePath = getResourcePath(record);
    const isNotificationDisabled =
        (typeof record.device_type !== "undefined" &&
            record.device_type === null) ||
        (typeof record.is_notification_endpoint_available !== "undefined" &&
            record.is_notification_endpoint_available === "false");

    return (
        <TopToolbar>
            <EditButton />
            <Button
                label="Change Password"
                onClick={() => navigate(`/${resourcePath}/${record.id}/changePassword`)}
            />
            <Button
                label="Manual Push Notification"
                onClick={() => navigate(`/${resourcePath}/${record.id}/notify`)}
                disabled={isNotificationDisabled}
            />
        </TopToolbar>
    );
};

const getResourcePath = (record) => {
    if (record.isPatient) return PATIENT_RESOURCE;
    if (record.isProvider) return PROVIDER_RESOURCE;
    if (record.isAdmin) return ADMIN_RESOURCE;
    return PATIENT_RESOURCE; // default to patient resource since all users should have a type
};

const StyledLink = styled(Link)({
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'none'
    }
});

const UserShow = (props) => {
    window.scrollTo(0, 0);
    return (
        <Show title={<UserTitle style={{verticalAlign: 'middle'}}/>} actions={<UserShowActions />} {...props}>
            <TabbedShowLayout>
                <Tab label="User Details">
                    <DefaultPlaceholderField source={userFields.id} wrapField={<TextField/>} label="Id" addLabel />
                    <DefaultPlaceholderField source={userFields.email} wrapField={<EmailField/>} label="Email" addLabel />
                    <DefaultPlaceholderField source={userFields.firstName} wrapField={<TextField/>} label="First Name" addLabel />
                    <DefaultPlaceholderField source={userFields.lastName} wrapField={<TextField/>} label="Last Name" addLabel />
                    <DefaultPlaceholderField source={userFields.wicId} wrapField={<TextField/>} label="WIC ID" addLabel />
                    <UserRoleField label="Role" addLabel/>
                    <DefaultPlaceholderField source={userFields.permissionTemplateName} wrapField={<TextField/>} label="Permissions template" addLabel />
                    <DefaultPlaceholderField source={userFields.providerBio} wrapField={<TextField />} label="Provider Bio" addLabel />
                    <DefaultPlaceholderField source={userFields.providerBioEs} wrapField={<TextField/>} label="Provider Bio Es" addLabel />
                    <DefaultPlaceholderField source={userFields.dob} options={DATE_OPTIONS} wrapField={<DateField/>} label="DOB" addLabel />
                    <DefaultPlaceholderField source={userFields.dueDate} options={DATE_OPTIONS} wrapField={<DateField/>} label="Due Date" addLabel />
                    <DefaultPlaceholderField source={userFields.phoneNumber} wrapField={<TextField/>} label="Phone" addLabel />
                    <DefaultPlaceholderField source={userFields.lang} wrapField={<TextField/>} label="Language" addLabel />
                    <OrganizationLinkField label="Organization" addLabel/>
                    <ConditionalYesNoField source={userFields.shareFeedback} showIfFieldExists={userFields.orgId} label="Share Feedback" addLabel />
                    <YesNoField source={userFields.recurlyActive} label="Recurly Active" addLabel />
                    <YesNoField source={userFields.isActive} label="Is Active" addLabel />
                    <DefaultPlaceholderField source={userFields.usState} wrapField={<TextField/>} label="State" addLabel />
                    <DefaultPlaceholderField source={userFields.zipCode} wrapField={<TextField/>} label="Zip Code" addLabel />
                    <DefaultPlaceholderField source={userFields.createdAt} options={DATE_TIME_OPTIONS} wrapField={<DateField/>} label="Created At" addLabel />
                    <DefaultPlaceholderField source={userFields.updatedAt} options={DATE_TIME_OPTIONS} wrapField={<DateField/>} label="Updated At" addLabel />
                    <YesNoField source={userFields.isMfaRequired} label="Dashboard MFA Required" addLabel />
                    <DefaultPlaceholderField source={userFields.gifts} wrapField={<GiftsField />} label="Gifts Redeemed" addLabel />
                    <DefaultPlaceholderField source={userFields.orgCodes} label="Organization Codes" addLabel wrapField={
                        <OrganizationCodeLinkField />
                    } />
                    <DefaultPlaceholderField source={userFields.birthWish} wrapField={<TextField/>} label="Birth Wish List" addLabel />
                    <DefaultPlaceholderField source={userFields.birthPartner} wrapField={<TextField/>} label="Birth Partner" addLabel />
                    <DefaultPlaceholderField source={userFields.prenatalVisit} wrapField={<TextField/>} label="Prenatal Visits" addLabel />
                    <DefaultPlaceholderField source={userFields.feedingPlan} wrapField={<TextField/>} label="Infant Feeding Plan" addLabel />
                    <DefaultPlaceholderField source={userFields.healthieId} wrapField={<TextField/>} label="Healthie ID" addLabel />
                </Tab>
                <Tab label="Relatives">
                    <ArrayField source={userFields.relatives} label="">
                        <Datagrid>
                            <TextField
                                label="Name"
                                source={userRelativeFields.name}
                            />
                            <DateField
                                label="Dob"
                                options={DATE_OPTIONS}
                                source={userRelativeFields.dob}
                            />
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export default UserShow;