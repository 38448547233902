import {
    fetchUtils
} from 'react-admin';
import queryString from 'query-string';
import { codesFields } from "./codes.model";

const API_URL = import.meta.env.VITE_API_URL;

export const RESOURCE_NAME = "codes";

const prepareFilters = (requestFilters) => {
    if (requestFilters === undefined) {
        return {};
    }
    let createEqFilter = (name, value) => ({ name, comparison: "eq", value });
    let createContainsFilter = (name, value) => ({ name, comparison: "contains", value });

    let resultFilters = [];
    // append "name" filter
    if (requestFilters.hasOwnProperty(codesFields.codeName)) {
        resultFilters.push(createContainsFilter('codeName', requestFilters[codesFields.codeName]));
    }
    // append "orgId" filter
    if (requestFilters.hasOwnProperty(codesFields.orgId)) {
        resultFilters.push(createEqFilter('orgId', requestFilters[codesFields.orgId]));
    }
    // append "redeemed" filter
    if (requestFilters.hasOwnProperty(codesFields.redeemed)) {
        resultFilters.push(createEqFilter('redeemed', requestFilters[codesFields.redeemed]));
    }

    return resultFilters;
};

const providerInterface = {
    providerRequestToHttpRequest: (type, params) => {
        switch (type) {
            case 'GET_LIST': {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                
                const queryParams = {
                    sort: JSON.stringify([field, order]),
                    range: JSON.stringify([(page - 1) * perPage, page * perPage]),
                    filters: JSON.stringify(prepareFilters(params.filter)),
                    orgId: 8
                };

                return { 
                    url: `${API_URL}/${RESOURCE_NAME}?${queryString.stringify(queryParams)}` 
                };
            }

            case 'GET_ONE':
                return { 
                    url: `${API_URL}/${RESOURCE_NAME}/${params.id}` 
                };

            case 'GET_MANY': {
                const query = {
                    filter: JSON.stringify({ id: params.ids }),
                };
                return { 
                    url: `${API_URL}/${RESOURCE_NAME}?${queryString.stringify(query)}` 
                };
            }

            case 'GET_MANY_REFERENCE': {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                const query = {
                    sort: JSON.stringify([field, order]),
                    range: JSON.stringify([(page - 1) * perPage, (page * perPage) - 1]),
                    filter: JSON.stringify({
                        ...params.filter,
                        [params.target]: params.id,
                    }),
                };
                return { 
                    url: `${API_URL}/${RESOURCE_NAME}?${queryString.stringify(query)}` 
                };
            }

            case 'CREATE':
                return {
                    url: `${API_URL}/${RESOURCE_NAME}`,
                    options: { 
                        method: 'POST',
                        body: JSON.stringify(params.data)
                    },
                };

            case 'UPDATE':
                return {
                    url: `${API_URL}/${RESOURCE_NAME}/${params.id}`,
                    options: { 
                        method: 'PUT',
                        body: JSON.stringify(params.data)
                    },
                };

            case 'DELETE':
                return {
                    url: `${API_URL}/${RESOURCE_NAME}/${params.id}`,
                    options: { method: 'DELETE' },
                };

            case 'DELETE_MANY': {
                const query = {
                    filter: JSON.stringify({ id: params.ids}),
                };
                return {
                    url: `${API_URL}/${RESOURCE_NAME}?${queryString.stringify(query)}`,
                    options: { method: 'DELETE' },
                };
            }

            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
    },

    httpResponseToProviderData: (response, type, params) => {
        const { headers, json } = response;
        
        switch (type) {
            case 'GET_LIST':
                return {
                    data: json,
                    total: parseInt(headers.get('content-range').split('/').pop()),
                };

            case 'CREATE':
                return { data: { ...params.data, id: json.id } };

            case 'UPDATE':
                // Extract the relevant fields and ensure id is present
                const { codeId, ...rest } = json;
                return { 
                    data: { 
                        id: codeId,
                        ...rest,
                        // Ensure these fields are properly formatted
                        role_ids: json.roleIds,
                        role_orders: json.roleOrders
                    } 
                };

            default:
                return { data: json };
        }
    },
};

export default {
    resource: RESOURCE_NAME,
    providerInterface
};
