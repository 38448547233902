import { BooleanInput, DateInput, ReferenceArrayInput, ReferenceInput, SelectArrayInput, SelectInput, TextInput } from 'react-admin';
import { RESOURCE_NAME as NON_PROVIDER_ROLES_RESOURCE } from '../../../roles/non.provider.roles.dataProvider';
import { RESOURCE_NAME as PROVIDER_ROLES_RESOURCE } from '../../../roles/provider.roles.dataProvider';
import { rolesFields } from '../../../roles/roles.model';
import { userFields } from '../../user.model';

export const UserFilters = [
    <TextInput source={userFields.id} label="ID" alwaysOn />,
    <TextInput source={userFields.email} label="Email" alwaysOn />,
    <TextInput source={userFields.healthieId} label="Healthie ID" alwaysOn />,
    <TextInput source={userFields.firstName} label="First Name" />,
    <TextInput source={userFields.lastName} label="Last Name" />,
    <TextInput source={userFields.wicId} label="WIC ID" />,
    <ReferenceInput
        source={userFields.roleId}
        reference={NON_PROVIDER_ROLES_RESOURCE}
    >
        <SelectInput optionText="name" />
    </ReferenceInput>,
    <ReferenceArrayInput
        source={userFields.providerRoleIds}
        reference={PROVIDER_ROLES_RESOURCE}
        label="Provider Roles"
    >
        <SelectArrayInput optionText={rolesFields.roleName} />
    </ReferenceArrayInput>,
    <BooleanInput source={userFields.isDeleted} label="Is Deleted" />,
    <BooleanInput source={userFields.recurlyActive} label="Recurly Active" />,
    <DateInput source={`${userFields.createdAt}_gte`} label="Created After" />,
    <DateInput source={`${userFields.createdAt}_lte`} label="Created Before" />
]; 