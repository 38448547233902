import { createTheme } from '@mui/material/styles';
import { merge } from 'lodash-es';
import '@fontsource/josefin-sans/300.css';  // Light
import '@fontsource/josefin-sans/400.css';  // Regular
import '@fontsource/josefin-sans/500.css';  // Medium
import '@fontsource/josefin-sans/600.css';  // Semi-bold
import '@fontsource/josefin-sans/700.css';  // Bold

// Color palette extracted from Pacify's website
const pacifyColors = {
    primary: {
        main: '#6F59A5',      // Pacify's signature purple
        light: '#8B77B9',     // Lighter shade of signature purple
        dark: '#574480',      // Darker shade of signature purple
        contrastText: '#fff'
    },
    secondary: {
        main: '#00C49F',      // Secondary teal color from charts/UI elements
        light: '#33D4B5',
        dark: '#009B7D',
        contrastText: '#fff'
    },
    accent: {
        orange: '#FF8042',    // From the chart colors
        yellow: '#FFBB28',    // From the chart colors
    },
    background: {
        default: '#ffffff',
        paper: '#fafafa',
    },
    text: {
        primary: '#2C3E50',   // Dark blue-gray for main text
        secondary: '#666666',  // For secondary text
    }
};

// Theme configuration
const theme = createTheme(merge({}, {
    palette: {
        mode: 'light',
        ...pacifyColors,
    },
    typography: {
        fontFamily: '"Josefin Sans", sans-serif',
        h1: {
            fontFamily: '"Josefin Sans", sans-serif',
            fontSize: '2.5rem',
            fontWeight: 700,
            color: pacifyColors.text.primary,
            marginBottom: '1rem'
        },
        h2: {
            fontFamily: '"Josefin Sans", sans-serif',
            fontSize: '2rem',
            fontWeight: 600,
            color: pacifyColors.text.primary,
            marginBottom: '0.875rem'
        },
        h3: {
            fontFamily: '"Josefin Sans", sans-serif',
            fontSize: '1.75rem',
            fontWeight: 600,
            color: pacifyColors.text.primary,
            marginBottom: '0.75rem'
        },
        h4: {
            fontFamily: '"Josefin Sans", sans-serif',
            fontSize: '1.5rem',
            fontWeight: 600,
            color: pacifyColors.text.primary,
            marginBottom: '0.625rem'
        },
        h5: {
            fontFamily: '"Josefin Sans", sans-serif',
            fontSize: '1.25rem',
            fontWeight: 600,
            color: pacifyColors.text.primary,
            marginBottom: '0.5rem'
        },
        h6: {
            fontFamily: '"Josefin Sans", sans-serif',
            fontSize: '1rem',
            fontWeight: 600,
            color: pacifyColors.text.primary,
            marginBottom: '0.5rem'
        },
        body1: {
            fontFamily: '"Josefin Sans", sans-serif',
            fontSize: '1rem',
            lineHeight: 1.6,
            color: pacifyColors.text.primary
        },
        body2: {
            fontFamily: '"Josefin Sans", sans-serif',
            fontSize: '0.875rem',
            lineHeight: 1.6,
            color: pacifyColors.text.secondary
        },
        button: {
            fontFamily: '"Josefin Sans", sans-serif',
            textTransform: 'none',
            fontWeight: 500
        }
    },
    components: {
        RaAppBar: {
            styleOverrides: {
                root: {
                    '& .RaAppBar-title': {
                        flex: 1,
                        fontSize: '1.25rem',
                        lineHeight: '48px',
                        margin: 0,
                        fontWeight: 500,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        '& span': {
                            display: 'inline-flex',
                            alignItems: 'center',
                            height: '48px'
                        }
                    }
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: pacifyColors.primary.main,
                    color: pacifyColors.primary.contrastText,
                }
            }
        },
        RaSidebar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                    borderRight: '1px solid rgba(0,0,0,0.08)',
                }
            }
        },
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    color: pacifyColors.text.primary,
                    '&.RaMenuItemLink-active': {
                        color: pacifyColors.primary.main,
                        backgroundColor: 'rgba(111,89,165,0.08)',
                    },
                    '&:hover': {
                        color: pacifyColors.primary.main,
                        backgroundColor: 'rgba(111,89,165,0.04)',
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    padding: '8px 24px',
                    fontSize: '1rem',
                    fontWeight: 500,
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    }
                },
                contained: {
                    backgroundColor: pacifyColors.primary.main,
                    '&:hover': {
                        backgroundColor: pacifyColors.primary.dark,
                    }
                },
                outlined: {
                    borderColor: pacifyColors.primary.main,
                    color: pacifyColors.primary.main,
                    '&:hover': {
                        borderColor: pacifyColors.primary.dark,
                        backgroundColor: 'rgba(111,89,165,0.04)',
                    }
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: '12px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
                    '&:hover': {
                        boxShadow: '0 4px 12px rgba(0,0,0,0.12)',
                    }
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    '&.MuiTableCell-head.RaDatagrid-headerCell': {
                        backgroundColor: pacifyColors.background.paper,
                        fontWeight: 600,
                        color: pacifyColors.text.primary,
                    },
                    '&.MuiTableCell-head': {
                        position: 'sticky',
                        top: 0,
                        backgroundColor: pacifyColors.background.paper,
                        zIndex: 2,
                    }
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:nth-of-type(odd)': {
                        backgroundColor: pacifyColors.background.paper,
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(111,89,165,0.04)',
                    }
                },
            },
        },
        RaDatagridHeaderCell: {
            styleOverrides: {
                root: {
                    backgroundColor: `${pacifyColors.background.paper} !important`,
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: '16px',
                    fontWeight: 500,
                },
                filled: {
                    backgroundColor: pacifyColors.primary.light,
                    color: '#fff',
                }
            }
        },
        RaShow: {
            styleOverrides: {
                root: {
                    '& .RaLabeled-label': {
                        fontSize: '1em',
                        color: '#757575',
                        fontWeight: 'bold',
                        marginBottom: '8px'
                    },
                    '& .RaLabeled-value': {
                        fontSize: '.875rem',
                        color: '#000000',
                        marginBottom: '32px',
                        fontWeight: 400
                    }
                }
            }
        }
    },
}));

export default theme; 