import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Title } from 'react-admin';
import DashboardStatsGrid from './components/DashboardStatsGrid';
import DashboardCarousel from './components/DashboardCarousel';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        padding: '16px',
        position: 'relative',
        minHeight: 'calc(100vh - 48px)', // Account for top bar
    }
}));

const Dashboard = () => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <Title title="Dashboard" />
            <DashboardStatsGrid />
            <Box mt={3}>
                <DashboardCarousel />
            </Box>
        </div>
    );
};

export default Dashboard;
