import * as React from 'react';
import {
    Create,
    TabbedForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    ArrayInput,
    SelectArrayInput,
    SimpleFormIterator,
    ReferenceArrayInput,
    BooleanInput,
    required,
    email,
    SaveButton,
    TopToolbar,
    useNotify,
    useRedirect,
    useResourceContext
} from 'react-admin';
import { RESOURCE_NAME as PROVIDER_ROLES_RESOURCE } from '../../roles/provider.roles.dataProvider';
import { RESOURCE_NAME as NON_PROVIDER_ROLES_RESOURCE } from '../../roles/non.provider.roles.dataProvider';
import { ALL_RESOURCE_NAME as ORGANIZATIONS_RESOURCE } from '../../organizations/organizations.dataProvider';
import { RESOURCE_NAME as US_STATES_RESOURCE } from '../../usStates/usStates.dataProvider';
import { RESOURCE_NAME as LANGUAGES_RESOURCE } from '../../languages/languages.dataProvider';
import { RESOURCE_NAME as PERMISSION_TEMPLATE_RESOURCE } from "../../permissionTemplates/permission.template.dataProvider";
import { userFields } from '../user.model';
import { USER_ROLE_RESOURCE_MAPPER } from '../constants';
import { rolesFields } from '../../roles/roles.model';
import { userRelativeFields } from '../userRelative.model';
import { validatePhone } from '../../utils/validation';
import { optionalField } from '../../utils/constants';
import CustomDatePicker from '../../app/customDatePicker';

const CreateActions = () => (
    <TopToolbar>
        <SaveButton />
    </TopToolbar>
);

const validateEmail = [ required(), email() ];
const validateRequired = [ required() ];

const UserCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const resource = useResourceContext();

    // Determine user type based on resource
    const isProvider = resource === 'providers';
    const isPatient = resource === 'patients';
    const isAdmin = resource === 'admins';

    const onSuccess = () => {
        notify('User created successfully');
        redirect('list', resource);
    };

    const onError = (error) => {
        notify(error?.reason || error?.message || 'Error creating user', { type: 'error' });
    };

    return (
        <Create mutationOptions={{ onSuccess, onError }}>
            <TabbedForm>
                <TabbedForm.Tab
                    label="User Details"
                    path=""
                    value="details"
                >
                    <ReferenceInput
                        source={userFields.roleId}
                        reference={NON_PROVIDER_ROLES_RESOURCE}
                        filter={{ name: USER_ROLE_RESOURCE_MAPPER[resource] }}
                        sx={{ width: 400 }}
                    >
                        <SelectInput optionText="name" validate={validateRequired} sx={{ width: 400 }} />
                    </ReferenceInput>
                    <TextInput
                        source={userFields.firstName}
                        validate={validateRequired}
                        sx={{ width: 400 }}
                    />
                    <TextInput
                        source={userFields.lastName}
                        validate={validateRequired}
                        sx={{ width: 400 }}
                    />
                    <TextInput
                        source={userFields.email}
                        validate={validateEmail}
                        sx={{ width: 400 }}
                    />
                    <TextInput
                        source={userFields.phoneNumber}
                        validate={[ isProvider ? required() : optionalField, validatePhone() ]}
                        sx={{ width: 400 }}
                    />

                    {!(isProvider || isAdmin) && (
                        <>
                            <CustomDatePicker
                                source={userFields.dob}
                                label="DOB"
                                sx={{ width: 400 }}
                            />
                            <CustomDatePicker
                                source={userFields.dueDate}
                                label="Due date"
                                sx={{ width: 400 }}
                            />
                        </>
                    )}

                    <ReferenceInput
                        source={userFields.lang}

                        reference={LANGUAGES_RESOURCE}
                        sx={{ width: 400 }}
                    >
                        <SelectInput optionText="name" label="Language" validate={required()} sx={{ width: 400 }} />
                    </ReferenceInput>

                    {isProvider && (
                        <>
                            <ReferenceInput
                                source={userFields.permissionTemplateId}
                                reference={PERMISSION_TEMPLATE_RESOURCE}
                                sx={{ width: 400 }}
                            >
                                <SelectInput optionText="name" label="Permission Template (from Healthie)" validate={required()} sx={{ width: 400 }} />
                            </ReferenceInput>
                            <ReferenceArrayInput
                                source={userFields.providerRoleIds}
                                reference={PROVIDER_ROLES_RESOURCE}
                                sx={{ width: 400 }}
                            >
                                <SelectArrayInput optionText={rolesFields.roleName} sx={{ width: 400 }} />
                            </ReferenceArrayInput>

                        </>
                    )}

                    {!(isProvider || isAdmin) && (
                        <>
                            <ReferenceInput
                                source={userFields.orgId}
                                reference={ORGANIZATIONS_RESOURCE}
                                sx={{ width: 400 }}
                            >
                                <SelectInput optionText="name" sx={{ width: 400 }} />
                            </ReferenceInput>
                            <TextInput
                                source={userFields.wicId}
                                label="WIC ID"
                                sx={{ width: 400 }}
                            />
                        </>
                    )}

                    {!isAdmin && (
                        <ReferenceInput
                            source={userFields.usState}
                            reference={US_STATES_RESOURCE}
                            sx={{ width: 400 }}
                        >
                            <SelectInput
                                optionText="name"
                                label="State"
                                validate={validateRequired}
                                sx={{ width: 400 }}
                            />
                        </ReferenceInput>
                    )}

                    {isProvider && (
                        <>
                            <TextInput
                                source={userFields.zipCode}
                                label="ZIP code"
                                sx={{ width: 400 }}
                            />
                            <TextInput
                                source={userFields.providerBio}
                                multiline
                                rows={5}
                                resettable
                                sx={{ width: 600 }}
                            />
                            <TextInput
                                source={userFields.providerBioEs}
                                label="Provider Bio (Spanish)"
                                multiline
                                rows={5}
                                resettable
                                sx={{ width: 600 }}
                            />
                        </>
                    )}

                    <BooleanInput
                        source={userFields.isActive}
                        defaultValue={true}
                        label="Active"
                    />
                    {!isPatient && (
                        <BooleanInput
                            source={userFields.isMfaRequired}
                            label="MFA Required"
                        />
                    )}
                </TabbedForm.Tab>
                {isPatient && (
                    <TabbedForm.Tab
                        label="User Relative"
                        path="relatives"
                        value="relatives"
                    >
                        <ArrayInput source={userFields.relatives}>
                            <SimpleFormIterator>
                                <TextInput
                                    label="Name"
                                    validate={validateRequired}
                                    source={userRelativeFields.name}
                                    sx={{ width: 400 }}
                                />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </TabbedForm.Tab>
                )}
            </TabbedForm>
        </Create>
    );
};

export default UserCreate;
