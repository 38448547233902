import { useReducer, useCallback } from 'react';

export const FILE_STATUS = {
    REMOVED: 'REMOVED',
    REJECTED_FILE_TYPE: 'REJECTED_FILE_TYPE',
    PREPARING: 'PREPARING',
    DONE: 'DONE'
};

const initialState = {
    csvFile: null,
    fileStatus: FILE_STATUS.REMOVED,
    snackbar: {
        open: false,
        severity: 'error',
        message: ''
    }
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_CSV_FILE':
            return {
                ...state,
                csvFile: action.payload
            };
        case 'SET_FILE_STATUS':
            return {
                ...state,
                fileStatus: action.payload
            };
        case 'SET_SNACKBAR':
            return {
                ...state,
                snackbar: action.payload
            };
        default:
            return state;
    }
};

export const usePinpointState = () => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const setCsvFile = useCallback((file) => {
        dispatch({ type: 'SET_CSV_FILE', payload: file });
    }, []);

    const setFileStatus = useCallback((status) => {
        dispatch({ type: 'SET_FILE_STATUS', payload: status });
    }, []);

    const setSnackbar = useCallback((snackbar) => {
        dispatch({ type: 'SET_SNACKBAR', payload: snackbar });
    }, []);

    return {
        state,
        setCsvFile,
        setFileStatus,
        setSnackbar
    };
}; 