import * as React from 'react';
import {
    Create,
    TabbedForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    required,
    AutocompleteInput,
    SaveButton,
    TopToolbar,
    useNotify,
    useRedirect,
    useRefresh
} from 'react-admin';
import { codesFields, CodeTypeChoices } from '../codes.model';
import { orgsFields } from '../../organizations/orgs.model';
import { ALL_RESOURCE_NAME as ORGANIZATIONS_RESOURCE } from '../../organizations/organizations.dataProvider';
import ProviderRoleOrderedList from '../../components/ProviderRoleOrderedList';

const validateCodeName = [required('Code name is required')];
const validateOrganization = [required('Organization is required')];
const validateCodeType = [required('Code type is required')];

const CodeCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onSuccess = () => {
        notify('Code created successfully', { type: 'success' });
        refresh();
        redirect('list', 'codes');
    };

    return (
        <Create
            title="Create Membership Code"
            mutationOptions={{ onSuccess }}
            sx={{
                '& .RaCreate-main': {
                    margin: '0.5em'
                }
            }}
        >
            <TabbedForm
                mode="onBlur"
                warnWhenUnsavedChanges
                toolbar={
                    <TopToolbar>
                        <SaveButton 
                            label="Create Code"
                            sx={{ marginLeft: 2 }}
                        />
                    </TopToolbar>
                }
            >
                <TabbedForm.Tab
                    label="Code Details"
                    sx={{
                        '& .RaFormInput-input': {
                            width: '100%',
                            maxWidth: 400
                        }
                    }}
                >
                    <TextInput 
                        source={codesFields.codeName} 
                        label="Code Name" 
                        validate={validateCodeName}
                        autoFocus
                    />
                    
                    <ReferenceInput 
                        source={codesFields.orgId}
                        reference={ORGANIZATIONS_RESOURCE}
                        label="Organization"
                    >
                        <AutocompleteInput 
                            optionText={orgsFields.orgName}
                            fullWidth
                            validate={validateOrganization}
                        />
                    </ReferenceInput>
                    
                    <SelectInput 
                        label="Code Type"
                        source={codesFields.codeType}
                        choices={CodeTypeChoices}
                        validate={validateCodeType}
                    />
                    <ProviderRoleOrderedList 
                        rolesFieldName={codesFields.roleIds}
                    />
                </TabbedForm.Tab>
            </TabbedForm>
        </Create>
    );
};

export default CodeCreate;
