import React from 'react';
import { Link, useRecordContext, TextField } from 'react-admin';
import { userFields } from '../../user.model';
import DefaultPlaceholderField from '../../../material/defPlaceholderField.component';

const OrganizationLinkField = ({ label }) => {
    const record = useRecordContext();

    if (!record) {
        return null;
    }

    const orgId = record[userFields.orgId];
    const orgName = record[userFields.orgName];

    if (!orgId || !orgName) {
        return <DefaultPlaceholderField source={userFields.orgName} wrapField={<TextField/>} />;
    }

    return (
        <Link to={`/organizations/${orgId}/show`}>
            <TextField record={record} source={userFields.orgName} />
        </Link>
    );
};

export default OrganizationLinkField;
