import React from 'react';
import { TextField, Link } from 'react-admin';
import { RESOURCE_NAME as USERS_RESOURCE } from '../../../../users/user.dataProvider';
import UserFullName from './userFullName.component';
import { discountCodesFields } from '../../discountCodes.model';

const wrapperCodeName = "n/a"

export default (props) => {
	// console.log(JSON.stringify(props));
    let { record = {}, ...rest } = props;
    if (record[discountCodesFields.userId]) {
        return ( 
        	<Link to={`/${USERS_RESOURCE}/${props.record.userId}/show`}>
        	<UserFullName {...props} />
    		</Link>
    		);
    } else {
        let name = wrapperCodeName;
        let fullNameKey = 'fullName';
        let recordCopy = {
        ...record,
        [fullNameKey]: name,
        };
        return (<TextField source={fullNameKey} record={recordCopy} {...rest} />);
    }
};

