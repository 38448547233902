import * as React from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Button, Snackbar, Card, CardContent, Typography } from '@mui/material';
import {
    SimpleForm,
    SaveButton,
    TopToolbar,
    PasswordInput,
    required,
    useNotify,
    useRedirect,
    Title,
    useGetOne
} from 'react-admin';
import dataProvider from '../../app/dataProvider';
import { RESOURCE_NAME as USERS_RESOURCE } from '../../users/user.dataProvider';
import { RESOURCE_NAME as PATIENT_RESOURCE } from '../patient.dataProvider';
import { RESOURCE_NAME as PROVIDER_RESOURCE } from '../provider.dataProvider';
import { RESOURCE_NAME as ADMIN_RESOURCE } from '../admin.dataProvider';

const validate = (values) => {
    const errors = {};

    if (values.password) {
        const passwordErrors = [];
        if (values.password.length < 14) {
            passwordErrors.push("be at least 14 symbols");
        }
        if (!/[A-Z]+/.test(values.password)) {
            passwordErrors.push("contain at least 1 uppercase letter");
        }
        if (!/[a-z]+/.test(values.password)) {
            passwordErrors.push("contain at least 1 lowercase letter");
        }
        if (!/[0-9]+/.test(values.password)) {
            passwordErrors.push("contain at least 1 number");
        }
        if (!/[!@#$%^&*]+/.test(values.password)) {
            passwordErrors.push("contain at least 1 special character");
        }
        if (passwordErrors.length > 0) {
            errors.password = `Must ${passwordErrors.join(", ")}.`;
        }
    }

    if (values.password !== values.passwordConfirmation) {
        errors.passwordConfirmation = 'Passwords should be the same';
    }
    return errors;
};

const EditActions = () => (
    <TopToolbar>
        <SaveButton />
    </TopToolbar>
);

const getResourcePath = (record) => {
    if (record.isPatient) return PATIENT_RESOURCE;
    if (record.isProvider) return PROVIDER_RESOURCE;
    if (record.isAdmin) return ADMIN_RESOURCE;
    return PATIENT_RESOURCE; // default to patient resource since all users should have a type
};

const ChangePassword = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const { id } = useParams();
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [severity, setSeverity] = React.useState('success');
    
    // Fetch user data to determine the type
    const { data: record } = useGetOne(USERS_RESOURCE, { id });

    const handleClose = () => {
        setOpen(false);
    };

    const onSuccess = () => {
        notify('Password changed successfully');
        const resourcePath = getResourcePath(record);
        redirect('show', resourcePath, id);
    };

    const handleSubmit = async (values) => {
        try {
            const response = await dataProvider.changePassword(USERS_RESOURCE, {
                data: {
                    userId: id,
                    password: values.password
                }
            });
            
            if (response.data.message === "Success") {
                onSuccess();
            } else {
                throw new Error(response.data.message || 'Error changing password');
            }
        } catch (error) {
            setMessage(error.message || 'Error changing password');
            setSeverity('error');
            setOpen(true);
        }
    };

    return (
        <Card>
            <Title title="Change Password" />
            <CardContent>
                <SimpleForm validate={validate} onSubmit={handleSubmit}>
                    <PasswordInput
                        source="password"
                        label="New Password"
                        validate={required()}
                    />
                    <PasswordInput
                        source="passwordConfirmation"
                        label="Confirm Password"
                        validate={required()}
                    />
                </SimpleForm>
            </CardContent>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </Card>
    );
};

export default ChangePassword;