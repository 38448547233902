import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    EditButton,
    DeleteButton,
    TextInput,
    useRecordContext,
    TopToolbar,
    FilterButton,
    CreateButton
} from 'react-admin';
import { rolesFields } from '../roles.model';
import DefaultPlaceholderField from '../../material/defPlaceholderField.component';
import RoleLinkField from './fields/roleLink.component';
import { DEFAULT_LIST_PERPAGE } from '../../utils/constants';

const filters = [
    <TextInput label="Name" source={rolesFields.roleName} alwaysOn />
];

const ProtectedDeleteButton = () => {
    const record = useRecordContext();
    if (!record) return null;
    return (
        <DeleteButton
            mutationMode="pessimistic"
            disabled={record.is_protected}
        />
    );
};

const ProtectedEditButton = () => {
    const record = useRecordContext();
    if (!record) return null;
    return (
        <EditButton disabled={record.is_protected} />
    );
};

const ListActions = () => (
    <TopToolbar>
        <FilterButton />
        <CreateButton />
    </TopToolbar>
);

const RolesList = () => {
    return (
        <List
            title="Roles"
            sort={{ field: 'id', order: 'DESC' }}
            filters={filters}
            bulkActionButtons={false}
            exporter={false}
            actions={<ListActions />}
            perPage={DEFAULT_LIST_PERPAGE}
        >
            <Datagrid rowClick={false}  bulkActionButtons={false}>
                <RoleLinkField label="Id" source={rolesFields.roleId} />
                <DefaultPlaceholderField source={rolesFields.roleName} wrapField={<TextField/>} label="Name" />
                <DefaultPlaceholderField source={rolesFields.lineType} wrapField={<TextField/>} label="Line Type" sortable={false} />
                <DefaultPlaceholderField source={rolesFields.phoneNumber} wrapField={<TextField/>} label="Phone Number" sortable={false} />
                <DefaultPlaceholderField source={rolesFields.chartName} wrapField={<TextField/>} label="Chart Type" sortable={false} />
                <ShowButton />
                <ProtectedEditButton />
                <ProtectedDeleteButton />
            </Datagrid>
        </List>
    );
};

export default RolesList;
