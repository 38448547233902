import * as React from 'react';
import {
  Button,
  Select,
  Dialog,
  MenuItem,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  DialogActions,
  Box
} from '@mui/material';

const MONTHS = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' }
];

const currentYear = new Date().getFullYear();
const YEARS = Array.from({length: 3}, (_, i) => currentYear - i).sort((a, b) => a - b);

const DataDialog = ({
  open,
  onClose,
  onSubmit,
  dataMonth,
  dataYear,
  onChangeDataMonth,
  onChangeDataYear
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Choose Month and Year of Loading Data</DialogTitle>
      <DialogContent>
        <Box sx={{ 
          display: 'flex', 
          gap: 2,
          pt: 2,
          '& .MuiFormControl-root': {
            minWidth: 120
          }
        }}>
          <FormControl variant="outlined">
            <InputLabel id="dialog-select-month-label">Month</InputLabel>
            <Select
              labelId="dialog-select-month-label"
              id="dialog-select-month"
              value={dataMonth || ''}
              label="Month"
              onChange={(e) => onChangeDataMonth(e.target.value)}
              sx={{ width: 120 }}
            >
              {MONTHS.map(({value, label}) => (
                <MenuItem value={value} key={value}>{label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel id="dialog-select-year-label">Year</InputLabel>
            <Select
              labelId="dialog-select-year-label"
              id="dialog-select-year"
              value={dataYear || currentYear}
              label="Year"
              onChange={(e) => onChangeDataYear(e.target.value)}
              sx={{ width: 120 }}
            >
              {YEARS.map((item) => (
                <MenuItem value={item} key={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary" variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DataDialog;