import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Box } from '@mui/material';

const SortableItem = ({ id, value, onRemove }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box
      ref={setNodeRef}
      style={style}
      {...attributes}
      sx={{
        backgroundColor: "#ccddee",
        padding: 0.6,
        margin: 0.6,
        zIndex: 1000,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        cursor: 'grab',
        touchAction: 'none'
      }}
    >
      <div {...listeners} style={{ flex: 1 }}>
        <span>{value.name}</span>
      </div>
      <Button 
        variant="contained" 
        color={value.isDeleted ? "inherit" : "secondary"} 
        onClick={(e) => {
          e.stopPropagation();
          onRemove(value);
        }} 
        name="deleteButton"
        sx={{ ml: 2 }}
      >
        Remove
      </Button>
    </Box>
  );
};

export default SortableItem; 