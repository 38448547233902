export const SET_CSV_FILE = 'SET_CSV_FILE';
export const SET_DATA_MONTH = 'SET_DATA_MONTH';
export const SET_DATA_YEAR = 'SET_DATA_YEAR';
export const TOGGLE_DATA_DIALOG = 'TOGGLE_DATA_DIALOG';
export const TOGGLE_DISABLE_ORG_DIALOG = 'TOGGLE_DISABLE_ORG_DIALOG';
export const SET_FILE_STATUS = 'SET_FILE_STATUS';
export const SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS';
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';

export const FILE_STATUS = {
    REMOVED: 'removed',
    PREPARING: 'preparing',
    REJECTED_FILE_TYPE: 'rejected_file_type',
    DONE: 'done'
};

export const initialState = {
    csvFile: null,
    dataMonth: new Date().getMonth() + 1,
    dataYear: new Date().getFullYear(),
    isDataDialogOpen: false,
    isDisableOrgDialogOpen: false,
    fileStatus: FILE_STATUS.REMOVED,
    validationErrors: [],
    uploadProgress: 0,
}; 