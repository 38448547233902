const API_URL = import.meta.env.VITE_API_URL;

export const RESOURCE_NAME = "pinpoint";

const PUT_PINPOINT = "PUT_PINPOINT";

const providerRequestToHttpRequest = (requestType, requestParams) => {
    // console.log("simple params " + requestParams);
    switch (requestType) {
        case PUT_PINPOINT:
            // console.log(JSON.stringify(requestParams));
            return {
                url: `${API_URL}/${RESOURCE_NAME}`,
                options: {
                    method: 'PUT',
                    body: JSON.stringify(requestParams)
            }
        };
        default:
            throw new Error(`Unsupported fetch action type ${requestType}`);
    }
}

const httpResponseToProviderData = (httpResponse, requestType, requestParams) => {
    var {
        json
    } = httpResponse;
    switch (requestType) {
        default:
            // console.log("Default format for pinpoint upload returned");
            // console.log(json)
            return {
                data: json
            };
    }
};

export default {
    resource: RESOURCE_NAME,
    providerInterface: {
        providerRequestToHttpRequest,
        httpResponseToProviderData
    },
};