import * as React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    ReferenceInput,
    required,
    SaveButton,
    TopToolbar,
    useNotify,
    useRedirect,
    useRefresh
} from 'react-admin';
import { makeStyles } from '@mui/styles';
import { rolesFields, CallLineType } from '../roles.model';
import { validatePhone, validateHexColor, validateUrl } from '../../utils/validation.js';
import { RESOURCE_NAME as CHARTS_RESOURCE } from '../../charts/charts.dataProvider';

const useStyles = makeStyles({
    roleName: { display: 'inline-block' },
    lineType: { display: 'inline-block', marginLeft: 32 }
});

const CreateActions = () => (
    <TopToolbar>
        <SaveButton />
    </TopToolbar>
);

const validate = (values) => {
    const errors = {};
    
    const lineType = values[rolesFields.lineType];
    const isPhoneType = lineType === CallLineType.PhoneToPacifyProvider.id || lineType === CallLineType.PhoneNumber.id;
    const phoneNumber = values[rolesFields.phoneNumber];

    const validators = isPhoneType ? [required(), validatePhone()] : [validatePhone(false)];
    for (const validator of validators) {
        const result = validator(phoneNumber);
        if (result !== undefined) {
            errors[rolesFields.phoneNumber] = result;
            break;
        }
    }

    return errors;
};

const RolesCreate = () => {
    const classes = useStyles();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onSuccess = () => {
        notify('Role created successfully');
        refresh();
        redirect('list', 'roles');
    };

    return (
        <Create mutationOptions={{ onSuccess }}>
            <SimpleForm validate={validate}>
                <TextInput
                    label="Name"
                    source={rolesFields.roleName}
                    className={classes.roleName}
                    validate={required()}
                />
                <SelectInput 
                    label="Line Type" 
                    source={rolesFields.lineType}
                    className={classes.lineType}
                    validate={required()}
                    choices={[
                        CallLineType.VideoChat,
                        CallLineType.PhoneNumber,
                        CallLineType.PhoneToPacifyProvider
                    ]}
                />
                <TextInput
                    type="phone"
                    label="Phone Number"
                    source={rolesFields.phoneNumber}
                />
                <TextInput
                    label="Button Background Color"
                    source={rolesFields.buttonBackgroundColor}
                    validate={[required(), validateHexColor()]}
                />
                <TextInput
                    label="Button Image URL"
                    source={rolesFields.buttonImageURL}
                    fullWidth
                    validate={[required(), validateUrl()]}
                />
                <TextInput
                    label="Info Popup Content -- English"
                    source={rolesFields.infoPopupContentEnglish}
                    multiline
                    fullWidth
                    validate={required()}
                />
                <TextInput
                    label="Info Popup Content -- Spanish"
                    source={rolesFields.infoPopupContentSpanish}
                    multiline
                    fullWidth
                    validate={required()}
                />
                <TextInput
                    label="Title -- English"
                    source={rolesFields.titleEnglish}
                    multiline
                    fullWidth
                    validate={required()}
                />
                <TextInput
                    label="Title -- Spanish"
                    source={rolesFields.titleSpanish}
                    multiline
                    fullWidth
                    validate={required()}
                />
                <ReferenceInput 
                    source={rolesFields.chartId}
                    reference={CHARTS_RESOURCE}
                    sort={{ field: "name", order: 'ASC' }}
                    label="Chart Type"
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};

export default RolesCreate;
