import React from 'react';
import { useRecordContext, TextField } from 'react-admin';
import { callsFields, CallStatusNames } from '../../calls.model';

const CallStatus = ({ label = 'Status' }) => {
    const record = useRecordContext();
    if (!record) return null;

    const statusName = CallStatusNames[record[callsFields.status]];
    if (!statusName) return null;

    return (
        <TextField 
            record={{ text: statusName }}
            source="text"
            label={label}
        />
    );
};

export default CallStatus;