import React from 'react';
import { Link, useRecordContext, useResourceContext } from 'react-admin';
import EmptyField from "@/material/emptyField.component";

const QueueLinkField = ({ source, label, addLabel = true }) => {
    const record = useRecordContext();
    const resource = useResourceContext();

    if (!record || !source) {
        return null;
    }

    const value = record[source];
    if (!value) {
        return <EmptyField />;
    }

    return (
        <Link to={`/${resource}/${value}/show`}>
            {value}
        </Link>
    );
};

export default QueueLinkField;
