import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    EditButton,
    TextInput,
    ReferenceInput,
    SelectInput,
    useListContext,
    TopToolbar,
    FilterButton,
    CreateButton,
    ExportButton,
    useNotify
} from 'react-admin';
import { codesFields } from '../codes.model';
import CodeType from './fields/codeType.component';
import DefaultPlaceholderField from '../../material/defPlaceholderField.component';
import { RESOURCE_NAME as ORGANIZATIONS_RESOURCE } from '../../organizations/organizations.dataProvider';
import OrganizationLinkField from './fields/codeOrganizationLink.component';
import CodeLinkField from './fields/codeLink.component';
import CodeRedeemed from './fields/codeRedeemed.component';
import { codeExporter } from "@/codes/component/utils/codeExport";

const codeFilters = [
    <TextInput 
        key="name"
        label="Name" 
        source={codesFields.codeName} 
        alwaysOn 
    />,
    <ReferenceInput 
        key="organization"
        label="Organization" 
        source={codesFields.orgId} 
        reference={ORGANIZATIONS_RESOURCE}
    >
        <SelectInput optionText="name" />
    </ReferenceInput>,
    <SelectInput
        key="redeemed"
        label="Redeemed"
        source={codesFields.redeemed}
        choices={[
            { id: true, name: 'Yes' },
            { id: false, name: 'No' },
            { id: "n/a", name: "n/a" }
        ]}
    />
];

const ListActions = () => {
    const { total } = useListContext();
    const notify = useNotify();

    return (
        <TopToolbar>
            <FilterButton />
            <CreateButton />
            <ExportButton
                disabled={total === 0}
                exporter={(codes) => codeExporter(codes, notify)}
            />
        </TopToolbar>
    );
};

const CodesList = () => (
    <List
        title="Membership Codes"
        exporter={codeExporter}
        sort={{ field: codesFields.id, order: 'DESC' }}
        filters={codeFilters}
        actions={<ListActions />}
        perPage={25}
        sx={{
            '& .RaList-main': { 
                margin: '0.5em'
            }
        }}
    >
        <Datagrid
            rowClick="show"
            bulkActionButtons={false}
            sx={{
                '& .column-id': { width: '5%' },
                '& .column-organization': { width: '20%' },
                '& .column-code_name': { width: '25%' },
                '& .column-code_type': { width: '20%' },
                '& .column-redeemed': { width: '10%' },
                '& .column-actions': { width: '20%', textAlign: 'right' }
            }}
        >
            <CodeLinkField 
                source={codesFields.id} 
                label="Id" 
            />
            <OrganizationLinkField 
                label="Organization" 
            />
            <DefaultPlaceholderField 
                source={codesFields.codeName} 
                wrapField={<TextField/>}  
                label="Code Name" 
            />
            <DefaultPlaceholderField 
                source={codesFields.codeType} 
                wrapField={<CodeType/>}  
                label="Code Type" 
            />
            <CodeRedeemed 
                source={codesFields.redeemed} 
                sortable={false} 
                label="Redeemed" 
            />                
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);

export default CodesList;
