import React from 'react';
import { UPDATE, useNotify, useRefresh } from 'react-admin';
import { Button } from '@mui/material';
import dataProvider from '../../../sdk-app/dataProvider';
import { RESOURCE_NAME as SDK_CODE_RESOURCE } from '../codes.dataProvider';

const InvalidateButton = ({ record }) => {
    const notify = useNotify();
    const refresh = useRefresh();

    const handleClick = () => {
        const codeForUpdate = {
            id: record.id,
            userId: record.user_id
        };
        
        dataProvider(UPDATE, SDK_CODE_RESOURCE, { data: codeForUpdate })
            .then(() => {
                refresh();
                notify('Code was invalidated.');
            })
            .catch((e) => {
                notify('Error: sdk code was not invalidated.', 'warning');
            });
    };

    return (
        <Button 
            variant="contained"
            color="primary"
            disabled={record.enabled === 0}
            onClick={handleClick}
        >
            Invalidate
        </Button>
    );
};

export default InvalidateButton;