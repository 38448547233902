import * as React from 'react';
import {
    Show,
    TabbedShowLayout,
    TextField,
    DateField,
    TopToolbar,
    EditButton,
    DeleteButton,
    useRecordContext
} from 'react-admin';
import { codesFields } from '../codes.model';
import CodeType from './fields/codeType.component';
import DefaultPlaceholderField from '../../material/defPlaceholderField.component';
import OrganizationLinkField from './fields/codeOrganizationLink.component';
import CodeRedeemed from './fields/codeRedeemed.component';
import YesNoField from '../../material/yesNoField.component';
import UserInfoField from './fields/codeUserInfo.component';
import SupportedRoles from './fields/codeSupportedRoles.component';
import { DATE_TIME_OPTIONS } from '../../app/app.component';

const ShowActions = () => (
    <TopToolbar>
        <EditButton />
    </TopToolbar>
);

const CodeTitle = () => {
    const record = useRecordContext();
    return record ? `Code: ${record[codesFields.codeName]}` : '';
};

const CodeShow = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Show
            title={<CodeTitle />}
            actions={<ShowActions />}
            sx={{
                '& .RaShow-main': {
                    margin: '0.5em'
                }
            }}
        >
            <TabbedShowLayout>
                <TabbedShowLayout.Tab
                    label="Code Details"
                >
                    <DefaultPlaceholderField 
                        source={codesFields.id} 
                        wrapField={<TextField/>} 
                        label="Id" 
                    />
                    <OrganizationLinkField 
                        label="Organization" 
                    />
                    <DefaultPlaceholderField 
                        source={codesFields.codeName} 
                        wrapField={<TextField/>}  
                        label="Code Name" 
                    />
                    <DefaultPlaceholderField 
                        source={codesFields.codeType} 
                        wrapField={<CodeType/>}  
                        label="Code Type" 
                    />
                    <YesNoField 
                        source={codesFields.invalidated} 
                        label="Invalidated" 
                    />
                    <CodeRedeemed 
                        source={codesFields.redeemed} 
                        label="Redeemed" 
                    />
                    <DefaultPlaceholderField 
                        source={codesFields.planName} 
                        wrapField={<TextField/>} 
                        label="Subscription Plan" 
                    />
                    <DefaultPlaceholderField 
                        source={codesFields.createdAt} 
                        options={DATE_TIME_OPTIONS} 
                        wrapField={<DateField/>} 
                        label="Created At" 
                    />
                    <SupportedRoles 
                        label="Supported Roles" 
                    />
                    <UserInfoField 
                        source={codesFields.userId} 
                        label="User" 
                    />
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>
        </Show>
    );
};

export default CodeShow;
