import { format, parseISO } from 'date-fns';
import { useNotify } from 'react-admin';
import { orgsFields } from "@/organizations/orgs.model";

export const organizationExporter = async (organizations, notify = useNotify()) => {
    if (organizations[0]?.url) {
        notify('Downloading organization data...', { type: 'info' });
        try {
            const response = await fetch(organizations[0].url);
            organizations = await response.json();
            notify('User data downloaded successfully', { type: 'info' });
        } catch (error) {
            console.error('Error downloading large export:', error);
            notify('Error downloading organization data', { type: 'error' });
            return;
        }
    }

    notify('Preparing CSV export...', { type: 'info' });

    const codesForExport = organizations.map(organization => {
        const createdAtTimestamp = organization[orgsFields.orgCreatedAt];

        return {
            'Id': organization[orgsFields.id],
            'Name': organization[orgsFields.orgName],
            'Healthie Client Id Group': organization[orgsFields.clientGroupId],
            'Nurse Phone': organization[orgsFields.orgNursePhone],
            'Payer Id': organization[orgsFields.payerId],
            'Created at date': createdAtTimestamp ? format(parseISO(createdAtTimestamp), 'yyyy-MM-dd') : '',
            'Created at time': createdAtTimestamp ? format(parseISO(createdAtTimestamp), 'HH:mm:ss') : '',
        };
    });

    const fields = [
        'Id',
        'Name',
        'Healthie Client Id Group',
        'Nurse Phone',
        'Payer Id',
        'Created at date',
        'Created at time',
    ];

    const csvData = fields.join(',') + '\n' +
        codesForExport.map(organization =>
            fields.map(field => {
                const value = organization[field];
                return value === null || value === undefined ? '' : `"${String(value).replace(/"/g, '""')}"`;
            }).join(',')
        ).join('\n');

    const timestamp = format(new Date(), 'yyyy-MM-dd-HHmmss');
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `organizations-${timestamp}.csv`;
    link.click();
    URL.revokeObjectURL(link.href);

    notify('CSV export completed successfully', { type: 'success' });
}; 