import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    BooleanField,
    SelectField,
    EditButton,
    TextInput,
    SelectInput,
    BooleanInput,
    CreateButton,
    ExportButton,
    TopToolbar,
    useListContext,
    useRecordContext,
} from 'react-admin';

const notificationTypeChoices = [
    { id: 'DOULA', name: 'Doula' },
    { id: 'LC', name: 'LC' },
];

const milestoneTypeChoices = [
    { id: 'prenatal', name: 'Prenatal' },
    { id: 'postnatal', name: 'Postnatal' },
];

const styles = {
    filter: { minWidth: "200px" },
    bodyField: { 
        maxWidth: '400px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
};

const TruncatedTextField = () => {
    const record = useRecordContext();
    if (!record || !record.body) return null;
    return (
        <div style={styles.bodyField} title={record.body}>
            {record.body}
        </div>
    );
};

const pregnancyMilestoneFilters = [
    <TextInput 
        label="Search Title" 
        source="title" 
        alwaysOn 
        style={styles.filter}
    />,
    <TextInput 
        label="Search Body" 
        source="body" 
        style={styles.filter}
    />,
    <SelectInput 
        source="notification_type" 
        choices={notificationTypeChoices}
        style={styles.filter}
    />,
    <SelectInput 
        source="milestone_type" 
        choices={milestoneTypeChoices}
        style={styles.filter}
    />,
    <BooleanInput 
        source="is_active" 
        style={styles.filter}
    />,
    <TextInput 
        label="Deep Link" 
        source="deep_link" 
        style={styles.filter}
    />
];

const ListActions = () => {
    const { total } = useListContext();
    return (
        <TopToolbar>
            <CreateButton />
            <ExportButton disabled={total === 0} />
        </TopToolbar>
    );
};

const NotificationDatagrid = () => (
    <Datagrid>
        <TextField source="title" />
        <TruncatedTextField source="body" label="Message Body" />
        <TruncatedTextField source="liquid_body" label="Liquid Body" />
        <SelectField 
            source="notification_type" 
            choices={notificationTypeChoices} 
        />
        <SelectField 
            source="milestone_type" 
            choices={milestoneTypeChoices} 
        />
        <TextField source="days_from_due_date" />
        <TextField source="scheduled_time" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
        <EditButton />
    </Datagrid>
);

export const PregnancyMilestoneNotificationList = props => {
    const { options } = props;
    const pageTitle = options?.pageLabel || options?.label || 'Pregnancy Milestone Notifications';
    
    return (
        <List 
            {...props} 
            title={pageTitle}
            filters={pregnancyMilestoneFilters}
            actions={<ListActions />}
            bulkActionButtons={false}
        >
            <NotificationDatagrid />
        </List>
    );
}; 