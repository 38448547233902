import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { useNotify } from 'react-admin';
import { RESOURCE_NAME as USERS_RESOURCE } from '../user.dataProvider';
import dataProvider from '../../app/dataProvider';

const UserNotifyDialog = ({ open, handleClose, userId }) => {
    const [message, setMessage] = React.useState('');
    const notify = useNotify();

    const handleSubmit = async () => {
        if (!message.trim()) {
            notify('Please enter a message', { type: 'warning' });
            return;
        }

        try {
            await dataProvider.update(USERS_RESOURCE, {
                id: userId,
                data: { message }
            });
            notify('Notification sent successfully', { type: 'success' });
            handleClose();
        } catch (error) {
            notify('Error sending notification', { type: 'error' });
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Send Push Notification</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Message"
                    fullWidth
                    multiline
                    rows={4}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserNotifyDialog;