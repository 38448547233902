import React from 'react';
import VideocamIcon from '@mui/icons-material/Videocam';
import { useRecordContext } from 'react-admin';
import { queuesFields } from '../../queues.model';

const QueueTitle = () => {
    const record = useRecordContext();
    if (!record) return null;

    return (
        <div
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
            <VideocamIcon sx={{ verticalAlign: 'middle' }}/>
            <span>{record[queuesFields.id]}</span>
        </div>
    );
};

export default QueueTitle;
