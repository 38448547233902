import React from 'react';
import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom';
import theme from './theme';
import { QueryClient } from '@tanstack/react-query';

// Icons
import UserIcon from '@mui/icons-material/Group';
import RoleIcon from '@mui/icons-material/TagFaces';
import VersionIcon from '@mui/icons-material/Book';
import CallsIcon from '@mui/icons-material/Call';
import VideocamIcon from '@mui/icons-material/Videocam';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import CodeIcon from '@mui/icons-material/Code';
import OrgIcon from '@mui/icons-material/Domain';
import LinkIcon from '@mui/icons-material/Link';
import GiftIcon from '@mui/icons-material/CardGiftcard';
import PinpointIcon from '@mui/icons-material/FileCopyRounded';
import ChatIcon from '@mui/icons-material/Chat';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import BarChartIcon from '@mui/icons-material/BarChart';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import PersonIcon from '@mui/icons-material/Person';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SettingsIcon from '@mui/icons-material/Settings';

// Components
import Dashboard from '../dashboard/dashboard.component';
import UserList from '../users/component/userList.component';
import UserEdit from '../users/component/userEdit.component';
import UserCreate from '../users/component/userCreate.component';
import UserShow from '../users/component/userShow.component';
import VersionsList from '../versions/component/versionsList.component';
import VersionShow from '../versions/component/versionShow.component';
import VersionEdit from '../versions/component/versionEdit.component';
import VersionCreate from '../versions/component/versionCreate.component';
import CodesList from '../codes/component/codesList.component';
import CodeShow from '../codes/component/codeShow.component';
import CodeEdit from '../codes/component/codeEdit.component';
import CodeCreate from '../codes/component/codeCreate.component';
import { OrgsList } from '../organizations/component/orgsList.component';
import OrgShow from '../organizations/component/orgShow.component';
import OrgEdit from '../organizations/component/orgEdit.component';
import OrgCreate from '../organizations/component/orgCreate.component';
import RolesList from '../roles/component/rolesList.component';
import RoleShow from '../roles/component/roleShow.component';
import RolesEdit from '../roles/component/rolesEdit.component';
import RolesCreate from '../roles/component/rolesCreate.component';
import CallsList from '../calls/component/callsList.component';
import CallsShow from '../calls/component/callsShow.component';
import EditCallChart from '../calls/component/editCallChart.component';
import ChatList from '../chats/component/chatList.component';
import ChatShow from '../chats/component/chatShow.component';
import PinpointEdit from '../pinpoint/component/pinpointEdit.component';
import HotlineList from '../hotline/component/hotlineList.component';
import { PregnancyMilestoneNotificationList } from '../notifications/components/PregnancyMilestoneNotificationList';
import { PregnancyMilestoneNotificationEdit } from '../notifications/components/PregnancyMilestoneNotificationEdit';
import { PregnancyMilestoneNotificationCreate } from '../notifications/components/PregnancyMilestoneNotificationCreate';
import { PregnancyMilestoneNotificationDeliveryList } from '../notifications/components/PregnancyMilestoneNotificationDeliveryList';
import { PregnancyMilestoneNotificationAnalyticsList } from '../notifications/components/PregnancyMilestoneNotificationAnalyticsList';
import { PregnancyMilestoneNotificationAnalyticsShow } from '../notifications/components/PregnancyMilestoneNotificationAnalyticsShow';
import AuthCallbackComponent from '../auth/authCallback.component';
import UserNotify from '../users/component/userNotify.component';
import ChangePassword from '../users/component/changePassword.component';
import QueueList from "../queues/component/queuesList.component";
import QueueShow from "../queues/component/queuesShow.component";

// Providers and Config
import CustomLayout from './customLayout';
import authProvider from '../auth/authProvider';
import dataProvider from './dataProvider';
import LoginComponent from '../auth/login.component';

// Resource Names
import { RESOURCE_NAME as USERS_RESOURCE } from '../users/user.dataProvider';
import { RESOURCE_NAME as ROLES_RESOURCE } from '../roles/roles.dataProvider';
import { RESOURCE_NAME as PROVIDER_ROLES_RESOURCE } from '../roles/provider.roles.dataProvider';
import { RESOURCE_NAME as NON_PROVIDER_ROLES_RESOURCE } from '../roles/non.provider.roles.dataProvider';
import { RESOURCE_NAME as VERSIONS_RESOURCE } from '../versions/versions.dataProvider';
import { RESOURCE_NAME as CALLS_RESOURCE } from '../calls/calls.dataProvider';
import { RESOURCE_NAME as CODES_RESOURCE } from '../codes/codes.dataProvider';
import { RESOURCE_NAME as ORGANIZATIONS_RESOURCE } from '../organizations/organizations.dataProvider';
import { ALL_RESOURCE_NAME as ALL_ORGANIZATIONS_RESOURCE } from '../organizations/organizations.dataProvider';
import { RESOURCE_NAME as US_STATES_RESOURCE } from '../usStates/usStates.dataProvider';
import { RESOURCE_NAME as LANGUAGES_RESOURCE } from '../languages/languages.dataProvider';
import { RESOURCE_NAME as FEEDING_PLANS_RESOURCE } from '../prenatalInfo/feedingPlans.dataProvider';
import { RESOURCE_NAME as PINPOINT_RESOURCE } from '../pinpoint/pinpoint.dataProvider';
import { RESOURCE_NAME as HOTLINE_RESOURCE } from '../hotline/hotline.dataProvider';
import { RESOURCE_NAME as CHARTS_RESOURCE } from '../charts/charts.dataProvider';
import { RESOURCE_NAME as PERMISSION_TEMPLATE_RESOURCE } from '../permissionTemplates/permission.template.dataProvider';
import { RESOURCE_NAME as PATIENT_RESOURCE } from "../users/patient.dataProvider";
import { RESOURCE_NAME as PROVIDER_RESOURCE } from "../users/provider.dataProvider";
import { RESOURCE_NAME as ADMIN_RESOURCE } from "../users/admin.dataProvider";
import { RESOURCE_NAME as QUEUES_RESOURCE } from "../queues/queues.dataProvider";
import { NOTIFICATION_TEMPLATES_RESOURCE, NOTIFICATION_DELIVERIES_RESOURCE, NOTIFICATION_ANALYTICS_RESOURCE } from '../notifications/pregnancyMilestoneNotifications.dataProvider';

// Date formatting options
export const DATE_TIME_OPTIONS = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'America/Toronto'
};

export const DATE_OPTIONS = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5 * 60 * 1000, // 5 minutes
            cacheTime: 10 * 60 * 1000, // 10 minutes
            retry: false,
        },
    },
});

const App = () => (
    <Admin
        loginPage={LoginComponent}
        dataProvider={dataProvider}
        authProvider={authProvider}
        dashboard={Dashboard}
        layout={CustomLayout}
        requireAuth
        basename=""
        theme={theme}
        queryClient={queryClient}
    >
        <Route path="/callback" element={<AuthCallbackComponent />} />
        <Resource
            name={PATIENT_RESOURCE}
            options={{
                label: 'Patients',
                menuLabel: 'Patients'
            }}
            icon={PersonIcon}
            list={UserList}
            show={UserShow}
            edit={UserEdit}
            create={UserCreate}
        >
            <Route path=":id/notify" element={<UserNotify />} />
            <Route path=":id/changePassword" element={<ChangePassword />} />
        </Resource>
        <Resource
            name={PROVIDER_RESOURCE}
            options={{
                label: 'Providers',
                menuLabel: 'Providers'
            }}
            icon={LocalHospitalIcon}
            list={UserList}
            show={UserShow}
            edit={UserEdit}
            create={UserCreate}
        >
            <Route path=":id/notify" element={<UserNotify />} />
            <Route path=":id/changePassword" element={<ChangePassword />} />
        </Resource>
        <Resource
            name={ADMIN_RESOURCE}
            options={{
                label: 'Admins',
                menuLabel: 'Admins'
            }}
            icon={AdminPanelSettingsIcon}
            list={UserList}
            show={UserShow}
            edit={UserEdit}
            create={UserCreate}
        >
            <Route path=":id/notify" element={<UserNotify />} />
            <Route path=":id/changePassword" element={<ChangePassword />} />
        </Resource>
        <Resource
            name={QUEUES_RESOURCE}
            icon={VideocamIcon}
            options={{ label: 'Queues' }}
            list={QueueList}
            show={QueueShow}
        />
        <Resource
            name={ROLES_RESOURCE}
            options={{
                label: 'Roles',
            }}
            icon={RoleIcon}
            list={RolesList}
            show={RoleShow}
            edit={RolesEdit}
            create={RolesCreate}
        />
        <Resource
            name={CODES_RESOURCE} 
            icon={CodeIcon} 
            options={{ label: 'Codes' }}
            list={CodesList} 
            show={CodeShow} 
            edit={CodeEdit} 
            create={CodeCreate} 
        />
        <Resource 
            name={ORGANIZATIONS_RESOURCE} 
            icon={OrgIcon} 
            options={{ label: 'Organizations' }}
            list={OrgsList} 
            show={OrgShow} 
            edit={OrgEdit} 
            create={OrgCreate} 
        />
        <Resource
            name={NOTIFICATION_TEMPLATES_RESOURCE}
            options={{
                label: 'Milestones',
                menuLabel: 'Milestones',
                pageLabel: 'Pregnancy Milestones Notifications',
                subMenu: true
            }}
            icon={NotificationsIcon}
            list={PregnancyMilestoneNotificationList}
            edit={PregnancyMilestoneNotificationEdit}
            create={PregnancyMilestoneNotificationCreate}
        />
        <Resource 
            name={PINPOINT_RESOURCE} 
            icon={PinpointIcon} 
            options={{ label: 'Pinpoint' }}
            list={PinpointEdit}
        />
        <Resource 
            name={NOTIFICATION_DELIVERIES_RESOURCE}
            options={{
                label: 'Deliveries',
                parent: 'Milestones',
                menuLabel: 'Deliveries'
            }}
            icon={LocalPostOfficeIcon}
            list={PregnancyMilestoneNotificationDeliveryList}
        />
        <Resource 
            name={NOTIFICATION_ANALYTICS_RESOURCE}
            options={{
                label: 'Analytics',
                parent: 'Milestones',
                menuLabel: 'Analytics'
            }}
            icon={BarChartIcon}
            list={PregnancyMilestoneNotificationAnalyticsList}
            show={PregnancyMilestoneNotificationAnalyticsShow}
        />
        <Resource 
            name={VERSIONS_RESOURCE} 
            icon={VersionIcon} 
            options={{ label: 'Versions' }}
            list={VersionsList} 
            show={VersionShow} 
            edit={VersionEdit} 
            create={VersionCreate} 
        />
        <Resource
            name={HOTLINE_RESOURCE}
            icon={HeadsetMicIcon}
            options={{ label: 'Hotline' }}
            list={HotlineList}
        />
        <Resource
            name={CALLS_RESOURCE}
            icon={CallsIcon}
            options={{ label: 'Calls' }}
            list={CallsList}
            show={CallsShow}
        >
            <Route path=":id/edit-chart" element={<EditCallChart />} />
        </Resource>

        {/* Non visible resources required for API calls */}
        <Resource name={US_STATES_RESOURCE} />
        <Resource name={PERMISSION_TEMPLATE_RESOURCE} />
        <Resource name={PROVIDER_ROLES_RESOURCE} />
        <Resource name={NON_PROVIDER_ROLES_RESOURCE} />
        <Resource name={ALL_ORGANIZATIONS_RESOURCE} />
        <Resource name={LANGUAGES_RESOURCE} />
        <Resource name={FEEDING_PLANS_RESOURCE} />
        <Resource name={CHARTS_RESOURCE} />
        <Resource name={USERS_RESOURCE} />
    </Admin>
);

export default App;
