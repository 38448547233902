import React, { useEffect } from 'react';
import {
    Edit,
    TabbedForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    ArrayInput,
    SelectArrayInput,
    SimpleFormIterator,
    ReferenceArrayInput,
    BooleanInput,
    required,
    email,
    SaveButton,
    TopToolbar,
    useRecordContext,
    useNotify,
    useRedirect,
    DeleteWithConfirmButton,
    AutocompleteInput,
    useEditContext,
    Toolbar,
    useRefresh
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { RESOURCE_NAME as PROVIDER_ROLES_RESOURCE } from '../../roles/provider.roles.dataProvider';
import { RESOURCE_NAME as NON_PROVIDER_ROLES_RESOURCE } from '../../roles/non.provider.roles.dataProvider';
import { ALL_RESOURCE_NAME as ORGANIZATIONS_RESOURCE } from '../../organizations/organizations.dataProvider';
import { RESOURCE_NAME as US_STATES_RESOURCE } from '../../usStates/usStates.dataProvider';
import { RESOURCE_NAME as LANGUAGES_RESOURCE } from '../../languages/languages.dataProvider';
import { RESOURCE_NAME as FEEDING_PLANS } from '../../prenatalInfo/feedingPlans.dataProvider';
import { RESOURCE_NAME as CODES_RESOURCE } from '../../codes/codes.dataProvider';
import { userFields } from '../user.model';
import { rolesFields } from '../../roles/roles.model';
import { userRelativeFields } from '../userRelative.model';
import { codesFields } from '../../codes/codes.model';
import CustomDatePicker from '../../app/customDatePicker';
import { validatePhone } from '../../utils/validation.js';
import { prenatalInfoFields, PrenatalInfoTypeFourChoices, PrenatalInfoTypeThreeChoices } from '../../prenatalInfo/prenatalInfo.model';
import { RESOURCE_NAME as PERMISSION_TEMPLATE_RESOURCE } from "../../permissionTemplates/permission.template.dataProvider";
import { ConditionalField } from "../../utils/conditionalField";
import { optionalField } from "../../utils/constants";
import { useLocation } from "react-router-dom";

const UserTitle = () => {
    const record = useRecordContext();
    const { isLoading } = useEditContext();

    if (isLoading) return 'Loading...';
    return record ? `${ record[ userFields.firstName ] } ${ record[ userFields.lastName ] }` : '';
};

const validateEmail = [ required(), email() ];
const validateRequired = [ required() ];
const preserveIdentity = value => (value);

const OrgCodeField = () => {
    const record = useRecordContext();
    const { getValues, setValue } = useFormContext();
    const values = getValues();

    const isOrgForm = typeof values?.org_id !== 'undefined'
        && typeof record?.org_id !== 'undefined';

    useEffect(() => {
        if (isOrgForm) {
            if (values.org_id === record.org_id) {
                setValue('code_id', record.org_codes?.length > 0
                    ? record.org_codes[0].id
                    : null);
            }
            else setValue('code_id', null);
        }
    }, [values?.org_id, record?.org_id, record?.org_codes, isOrgForm, setValue]);

    return (
        <ReferenceInput
            source={userFields.codeId}
            reference={CODES_RESOURCE}
            perPage={1000}
            sort={{ field: codesFields.codeName, order: 'ASC' }}
            filter={{ organization_id: values?.org_id }}
            label="Code"
            defaultValue={record?.org_codes?.length > 0 ? record.org_codes[0].id : null}
            allowEmpty
            sx={{ width: 400 }}
        >
            <AutocompleteInput optionText={codesFields.codeName} sx={{ width: 400 }} />
        </ReferenceInput>
    );
};

const UserEditForm = () => {
    const { record, isLoading } = useEditContext();
    if (isLoading) return null;

    const { isProvider, isPatient, isAdmin } = record;

    return (
        <TabbedForm
            sanitizeEmptyValues
            toolbar={
                <Toolbar>
                    <SaveButton
                        label="SAVE"
                    />
                    <DeleteWithConfirmButton
                        label="DELETE"
                        confirmTitle="Delete User"
                        confirmContent="Are you sure you want to delete this user? This action is permanent and cannot be undone."
                    />
                </Toolbar>
            }
        >
            <TabbedForm.Tab
                label="User Details"
                path=""
            >
                <ReferenceInput
                    source={userFields.roleId}
                    reference={NON_PROVIDER_ROLES_RESOURCE}
                    perPage={10000}
                    sort={{ field: "name", order: 'ASC' }}
                    label="Role"
                    allowEmpty
                    sx={{ width: 400 }}
                >
                    <SelectInput
                        optionText="name"
                        validate={validateRequired}
                        readOnly
                        sx={{ width: 400 }}
                    />
                </ReferenceInput>
                <TextInput
                    source={userFields.firstName}
                    validate={validateRequired}
                    label="First Name"
                    sx={{ width: 400 }}
                />
                <TextInput
                    source={userFields.lastName}
                    label="Last Name"
                    parse={preserveIdentity}
                    sx={{ width: 400 }}
                />
                <TextInput
                    source={userFields.email}
                    validate={validateEmail}
                    label="Email"
                    sx={{ width: 400 }}
                />
                <TextInput
                    source={userFields.phoneNumber}
                    validate={[ isProvider ? required() : optionalField, validatePhone() ]}
                    label="Phone"
                    parse={preserveIdentity}
                    sx={{ width: 400 }}
                />
                {!(isProvider || isAdmin) && (
                    <>
                        <CustomDatePicker
                            source={userFields.dob}
                            label="DOB"
                            sx={{ width: 400 }}
                        />
                        <CustomDatePicker
                            source={userFields.dueDate}
                            label="Due date"
                            sx={{ width: 400 }}
                        />
                    </>
                )}
                <ConditionalField condition={() => isProvider}>
                    <ReferenceInput
                        source={userFields.permissionTemplateId}
                        reference={PERMISSION_TEMPLATE_RESOURCE}
                        sx={{ width: 400 }}
                    >
                        <SelectInput
                            optionText="name"
                            validate={validateRequired}
                            sx={{ width: 400 }}
                        />
                    </ReferenceInput>
                    <ReferenceArrayInput
                        source={userFields.providerRoleIds}
                        perPage={10000}
                        sort={{ field: "name", order: 'ASC' }}
                        reference={PROVIDER_ROLES_RESOURCE}
                        label="Provider Roles"
                        allowEmpty
                        sx={{ width: 400 }}
                    >
                        <SelectArrayInput optionText={rolesFields.roleName} sx={{ width: 400 }} />
                    </ReferenceArrayInput>
                    <TextInput
                        source={userFields.providerBio}
                        multiline
                        rows={5}
                        resettable
                        sx={{ width: 400 }}
                    />
                    <TextInput
                        source={userFields.providerBioEs}
                        label="Provider Bio (Spanish)"
                        multiline
                        rows={5}
                        resettable
                        sx={{ width: 400 }}
                    />
                </ConditionalField>
                {!(isProvider || isAdmin) && (
                    <>
                        <ReferenceInput
                            source={userFields.orgId}
                            reference={ORGANIZATIONS_RESOURCE}
                            perPage={10000}
                            sort={{ field: "name", order: 'ASC' }}
                            label="Organization"
                            allowEmpty
                            sx={{ width: 400 }}
                        >
                            <SelectInput optionText="name" sx={{ width: 400 }} />
                        </ReferenceInput>
                        <TextInput
                            source={userFields.wicId}
                            label="WIC ID"
                            parse={preserveIdentity}
                            sx={{ width: 400 }}
                        />
                    </>
                )}
                <OrgCodeField />
                {!isAdmin && (
                    <>
                        <ReferenceInput
                            source={userFields.usState}
                            reference={US_STATES_RESOURCE}
                            label="State"
                            sx={{ width: 400 }}
                        >
                            <SelectInput
                                optionText="name"
                                label="State"
                                validate={[ isPatient ? optionalField : required() ]}
                                sx={{ width: 400 }}
                            />
                        </ReferenceInput>

                    </>
                )}
                {isProvider && (
                    <TextInput
                        source={userFields.zipCode}
                        label="ZIP code"
                        parse={preserveIdentity}
                        sx={{ width: 400 }}
                    />
                )}
                <ReferenceInput
                    source={userFields.lang}
                    reference={LANGUAGES_RESOURCE}
                    label="Language"
                    allowEmpty
                    sx={{ width: 400 }}
                >
                    <SelectInput  label="Language" optionText="name" sx={{ width: 400 }} />
                </ReferenceInput>
                <SelectInput
                    source={userFields.birthWish}
                    label={prenatalInfoFields.birthWish}
                    choices={PrenatalInfoTypeFourChoices}
                    sx={{ width: 400 }}
                />
                <SelectInput
                    source={userFields.birthPartner}
                    label={prenatalInfoFields.birthPartner}
                    choices={PrenatalInfoTypeThreeChoices}
                    sx={{ width: 400 }}
                />
                <SelectInput
                    source={userFields.prenatalVisit}
                    label={prenatalInfoFields.prenatalVisit}
                    choices={PrenatalInfoTypeFourChoices}
                    sx={{ width: 400 }}
                />
                <ReferenceInput
                    source={userFields.feedingPlan}
                    reference={FEEDING_PLANS}
                    label={prenatalInfoFields.feedingPlan}
                    sx={{ width: 400 }}
                >
                    <SelectInput optionText="name" sx={{ width: 400 }} />
                </ReferenceInput>
                <BooleanInput
                    source={userFields.isActive}
                    label="Is active"
                    sx={{ width: 400 }}
                />
                 {!isPatient && (
                    <BooleanInput
                        source={userFields.isMfaRequired}
                        label="MFA Required"
                        sx={{ width: 400 }}
                    />
                )}
            </TabbedForm.Tab>
            <TabbedForm.Tab
                label="Relatives"
                path="relatives"
            >
                <ArrayInput source={userFields.relatives} sx={{ width: 400 }}>
                    <SimpleFormIterator>
                        <TextInput
                            source={userRelativeFields.name}
                            label="Name"
                            sx={{ width: 400 }}
                        />
                        <CustomDatePicker
                            source={userRelativeFields.dob}
                            label="DoB"
                            validate={required()}
                            sx={{ width: 400 }}
                        />
                    </SimpleFormIterator>
                </ArrayInput>
            </TabbedForm.Tab>
        </TabbedForm>
    );
};

const UserEdit = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const location = useLocation();

    const onSuccess = () => {
        notify('User updated successfully');
        refresh();
        redirect(`${location.pathname}/show`);
    };

    const onError = (error) => {
        notify(error?.reason || error?.message || 'Error updating user', { type: 'error' });
    };

    return (
        <Edit
            title={<UserTitle />}
            mutationMode="pessimistic"
            mutationOptions={{
                onSuccess,
                onError
            }}
        >
            <UserEditForm />
        </Edit>
    );
};

export default UserEdit;
