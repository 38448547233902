import { format, fromUnixTime } from 'date-fns';
import { useNotify } from 'react-admin';
import { codesFields } from "@/codes/codes.model";

export const codeExporter = async (codes, notify = useNotify()) => {
    if (codes[0]?.url) {
        notify('Downloading code data...', { type: 'info' });
        try {
            const response = await fetch(codes[0].url);
            codes = await response.json();
            notify('User data downloaded successfully', { type: 'info' });
        } catch (error) {
            console.error('Error downloading large export:', error);
            notify('Error downloading code data', { type: 'error' });
            return;
        }
    }

    notify('Preparing CSV export...', { type: 'info' });

    const codesForExport = codes.map(code => {
        const createdAtTimestamp = code[codesFields.createdAt];

        return {
            'Id': code[codesFields.id],
            'Organization Id': code[codesFields.orgId],
            'Organization Name': code[codesFields.orgName],
            'Code Name': code[codesFields.codeName],
            'Code Type': code[codesFields.codeType],
            'Redeemed': code[codesFields.redeemed],
            'Invalidated': code[codesFields.invalidated],
            'Created at date': createdAtTimestamp ? format(fromUnixTime(createdAtTimestamp/1000), 'yyyy-MM-dd') : '',
            'Created at time': createdAtTimestamp ? format(fromUnixTime(createdAtTimestamp/1000), 'HH:mm:ss') : '',
        };
    });

    const fields = [
        'Id',
        'Organization Id',
        'Organization Name',
        'Code Name',
        'Code Type',
        'Redeemed',
        'Invalidated',
        'Created at date',
        'Created at time',
    ];

    const csvData = fields.join(',') + '\n' +
        codesForExport.map(code =>
            fields.map(field => {
                const value = code[field];
                return value === null || value === undefined ? '' : `"${String(value).replace(/"/g, '""')}"`;
            }).join(',')
        ).join('\n');

    const timestamp = format(new Date(), 'yyyy-MM-dd-HHmmss');
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `codes-${timestamp}.csv`;
    link.click();
    URL.revokeObjectURL(link.href);

    notify('CSV export completed successfully', { type: 'success' });
}; 