import React from 'react';
import PropTypes from 'prop-types';
import { DndContext, useSensor, useSensors, PointerSensor } from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import FileDropzone from '../FileDropzone';

const CsvUploader = ({ 
    onFileSelect, 
    selectedFile, 
    dropzoneText = 'Upload Medicaid member list csv file. Drag or click to choose.',
    fileLabel = 'File to upload:'
}) => {
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5,
            },
        })
    );

    const handleDragEnd = (event) => {
        const { over } = event;
        if (over && over.id === 'file-dropzone') {
            const files = event.active.data.current?.files;
            if (files && files.length > 0) {
                onFileSelect({ file: files[0] });
            }
        }
    };

    const handleFileSelect = (file) => {
        onFileSelect({ file });
    };

    return (
        <DndContext
            sensors={sensors}
            modifiers={[restrictToWindowEdges]}
            onDragEnd={handleDragEnd}
        >
            <div>
                <FileDropzone 
                    onFileSelect={handleFileSelect}
                    selectedFile={selectedFile}
                    accept="text/csv"
                    dropzoneText={dropzoneText}
                    fileLabel={fileLabel}
                />
            </div>
        </DndContext>
    );
};

CsvUploader.propTypes = {
    onFileSelect: PropTypes.func.isRequired,
    selectedFile: PropTypes.object,
    dropzoneText: PropTypes.string,
    fileLabel: PropTypes.string
};

export default CsvUploader; 