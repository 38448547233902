import { addMonths, format, startOfMonth } from 'date-fns';
import React, { useState } from 'react';
import {
    Button,
    FilterList,
    FilterListItem,
    FilterListSection,
    FilterLiveSearch,
    useGetList,
    useResourceContext,
    useListFilterContext
} from 'react-admin';
import { SidebarFilter } from '../../filters/SidebarFilter';
import { SidebarFilterBoolean } from '../../filters/sidebarFilterItems';
import { RESOURCE_NAME as ORGANIZATIONS_RESOURCE } from '../../organizations/organizations.dataProvider';
import { RESOURCE_NAME as PROVIDER_ROLES_RESOURCE } from '../../roles/roles.dataProvider';
import { RESOURCE_NAME as US_STATES_RESOURCE } from '../../usStates/usStates.dataProvider';
import { userFields } from '../user.model';
import { Box, Card, CardContent, Button as MuiButton, TextField, Divider } from "@mui/material";

// Import icons for the sections
import BusinessIcon from '@mui/icons-material/Business';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WorkIcon from '@mui/icons-material/Work';
import LanguageIcon from '@mui/icons-material/Language';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import BadgeIcon from '@mui/icons-material/Badge';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import SearchIcon from '@mui/icons-material/Search';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ClearIcon from '@mui/icons-material/Clear';

// Due date filter - only used for patients
const DueDateFilter = () => {
    const getNext12Months = () => {
        const months = [];
        const today = new Date();
        for (let i = 0; i < 12; i++) {
            const date = addMonths(startOfMonth(today), i);
            months.push({
                label: format(date, 'MMM yyyy'),
                value: { [userFields.dueDate]: startOfMonth(date).toISOString()}
            });
        }
        return months;
    };

    return (
        <FilterList label="Due date" icon={<CalendarTodayIcon />}>
            {getNext12Months().map(month => (
                <FilterListItem
                    key={month.label}
                    label={month.label}
                    value={month.value}
                />
            ))}
        </FilterList>
    );
}

const OrganizationFilter = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [expanded, setExpanded] = useState(false);
    const { data: organizations } = useGetList(ORGANIZATIONS_RESOURCE, {
        pagination: { page: 1, perPage: 500 },
        sort: { field: 'name', order: 'ASC' }
    });

    if (!organizations) return null;

    // Filter organizations based on search term
    const filteredOrgs = Object.values(organizations).filter(org => 
        org.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    // Limit the number of organizations shown if not expanded
    const displayOrgs = expanded ? filteredOrgs : filteredOrgs.slice(0, 10);

    return (
        <>
            {/* Simple search input for organizations */}
            <Box mb={1}>
                <TextField
                    size="small"
                    placeholder="Search organizations..."
                    fullWidth
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    variant="outlined"
                    InputProps={{
                        startAdornment: <SearchIcon fontSize="small" color="disabled" sx={{ mr: 0.5 }} />
                    }}
                />
            </Box>
            
            <FilterList label="Organization">
                {displayOrgs.map(org => (
                    <FilterListItem
                        key={org.id}
                        label={org.name}
                        value={{ [userFields.orgId]: org.id }}
                    />
                ))}
            </FilterList>
            
            {/* Show More/Less button */}
            {filteredOrgs.length > 10 && (
                <Box textAlign="center" mt={1}>
                    <MuiButton 
                        size="small" 
                        onClick={() => setExpanded(!expanded)}
                    >
                        {expanded ? 'Show Less' : `Show ${filteredOrgs.length - 10} More`}
                    </MuiButton>
                </Box>
            )}
        </>
    );
};

const ProviderRoleFilter = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [expanded, setExpanded] = useState(false);
    const { data: roles } = useGetList(PROVIDER_ROLES_RESOURCE, {
        pagination: { page: 1, perPage: 500 },
        sort: { field: 'name', order: 'ASC' }
    });

    if (!roles) return null;

    // Filter roles based on search term
    const filteredRoles = Object.values(roles).filter(role => 
        role.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    // Limit the number of roles shown if not expanded
    const displayRoles = expanded ? filteredRoles : filteredRoles.slice(0, 10);
    
    // Check if a role is selected in the current filters
    const isSelected = (value, filters) => {
        const roleIds = filters[userFields.providerRoleIds];
        if (!roleIds) return false;
        
        // Handle both array and single value cases
        if (Array.isArray(roleIds)) {
            return roleIds.includes(value[userFields.providerRoleIds]);
        }
        return roleIds === value[userFields.providerRoleIds];
    };
    
    // Toggle a role in the current filters
    const toggleFilter = (value, filters) => {
        const roleId = value[userFields.providerRoleIds];
        let currentRoleIds = filters[userFields.providerRoleIds];
        
        // Initialize as empty array if not set
        if (!currentRoleIds) {
            return {
                ...filters,
                [userFields.providerRoleIds]: [roleId]
            };
        }
        
        // Convert to array if it's a single value
        if (!Array.isArray(currentRoleIds)) {
            currentRoleIds = [currentRoleIds];
        }
        
        // Toggle the role
        if (currentRoleIds.includes(roleId)) {
            // Remove the role if already selected
            const newRoleIds = currentRoleIds.filter(id => id !== roleId);
            return {
                ...filters,
                [userFields.providerRoleIds]: newRoleIds.length ? newRoleIds : undefined
            };
        } else {
            // Add the role if not already selected
            return {
                ...filters,
                [userFields.providerRoleIds]: [...currentRoleIds, roleId]
            };
        }
    };

    return (
        <>
            {/* Simple search input for roles */}
            <Box mb={1}>
                <TextField
                    size="small"
                    placeholder="Search roles..."
                    fullWidth
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    variant="outlined"
                    InputProps={{
                        startAdornment: <SearchIcon fontSize="small" color="disabled" sx={{ mr: 0.5 }} />
                    }}
                />
            </Box>
            
            <FilterList label="Provider role">
                {displayRoles.map(role => (
                    <FilterListItem
                        key={role.id}
                        label={role.name}
                        value={{ [userFields.providerRoleIds]: role.id }}
                        isSelected={isSelected}
                        toggleFilter={toggleFilter}
                    />
                ))}
            </FilterList>
            
            {/* Show More/Less button */}
            {filteredRoles.length > 10 && (
                <Box textAlign="center" mt={1}>
                    <MuiButton 
                        size="small" 
                        onClick={() => setExpanded(!expanded)}
                    >
                        {expanded ? 'Show Less' : `Show ${filteredRoles.length - 10} More`}
                    </MuiButton>
                </Box>
            )}
        </>
    );
};

const StateFilter = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [expanded, setExpanded] = useState(false);
    const { data: usStates } = useGetList(US_STATES_RESOURCE, {
        pagination: { page: 1, perPage: 500 },
        sort: { field: 'name', order: 'ASC' }
    });

    if (!usStates) return null;

    // Filter states based on search term
    const filteredStates = Object.values(usStates).filter(state => 
        state.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    // Limit the number of states shown if not expanded
    const displayStates = expanded ? filteredStates : filteredStates.slice(0, 10);

    return (
        <>
            {/* Simple search input for states */}
            <Box mb={1}>
                <TextField
                    size="small"
                    placeholder="Search states..."
                    fullWidth
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    variant="outlined"
                    InputProps={{
                        startAdornment: <SearchIcon fontSize="small" color="disabled" sx={{ mr: 0.5 }} />
                    }}
                />
            </Box>
            
            <FilterList label="State">
                {displayStates.map(usState => (
                    <FilterListItem
                        key={usState.id}
                        label={usState.name}
                        value={{ [userFields.usState]: usState.id }}
                    />
                ))}
            </FilterList>
            
            {/* Show More/Less button */}
            {filteredStates.length > 10 && (
                <Box textAlign="center" mt={1}>
                    <MuiButton 
                        size="small" 
                        onClick={() => setExpanded(!expanded)}
                    >
                        {expanded ? 'Show Less' : `Show ${filteredStates.length - 10} More`}
                    </MuiButton>
                </Box>
            )}
        </>
    );
}

const LanguageFilter = () => {
    return (
        <FilterList label="Language" icon={<LanguageIcon />}>
            <FilterListItem label="English" value={{ [userFields.lang]: "en" }} />
            <FilterListItem label="Spanish" value={{ [userFields.lang]: "es" }} />
            <FilterListItem label="Both" value={{ [userFields.lang]: "es+en" }} />
        </FilterList>
    );
}

// Custom components for WIC ID and Is Active with icons
const WicIdFilter = () => (
    <SidebarFilterBoolean 
        label="WIC ID" 
        value={userFields.wicId} 
        icon={<CardMembershipIcon />}
    />
);

const IsActiveFilter = () => (
    <SidebarFilterBoolean 
        label="Is Active" 
        value={userFields.isActive} 
        icon={<ToggleOnIcon />}
    />
);

export function UserSidebarFilters () {
    const resource = useResourceContext();
    const isProvider = resource === 'providers';
    const isPatient = resource === 'patients';
    const { filterValues, setFilters } = useListFilterContext();
    
    // Check if any filters are active
    const hasActiveFilters = Object.keys(filterValues).length > 0;
    
    // Clear all filters
    const handleClearFilters = () => {
        setFilters({});
    };
    
    if (!isPatient && !isProvider) return null;
    
    return (
        <SidebarFilter>
            <>
                {/* Search always at the top */}
                <FilterLiveSearch source="q" label="Search" icon={<SearchIcon />} />
                
                {/* Clear all filters button */}
                {hasActiveFilters && (
                    <Box mt={2} mb={2} textAlign="center">
                        <Button
                            label="Clear all filters"
                            onClick={handleClearFilters}
                            startIcon={<ClearIcon />}
                            fullWidth
                            variant="outlined"
                            size="small"
                        />
                        <Divider sx={{ mt: 2 }} />
                    </Box>
                )}
                
                {/* Basic filters for patients */}
                {isPatient && (
                    <FilterListSection 
                        label="Basic Info" 
                        icon={<BadgeIcon />} 
                        defaultExpanded
                    >
                        <WicIdFilter />
                        <IsActiveFilter />
                        <LanguageFilter />
                        {/* Due date filter - only shown for patients */}
                        <DueDateFilter />
                    </FilterListSection>
                )}
                
                {/* Basic filters for providers */}
                {isProvider && (
                    <FilterListSection 
                        label="Basic Info" 
                        icon={<BadgeIcon />} 
                        defaultExpanded
                    >
                        <LanguageFilter />
                        {/* No due date filter for providers */}
                    </FilterListSection>
                )}
                
                {/* Organization Section with collapsible UI */}
                {isPatient && (
                    <FilterListSection 
                        label="Organization" 
                        icon={<BusinessIcon />} 
                        defaultExpanded={false}
                    >
                        <OrganizationFilter />
                    </FilterListSection>
                )}
                
                {/* Provider Role Section with collapsible UI */}
                {isProvider && (
                    <FilterListSection 
                        label="Provider Role" 
                        icon={<WorkIcon />} 
                        defaultExpanded={false}
                    >
                        <ProviderRoleFilter />
                    </FilterListSection>
                )}
                
                {/* State Section with collapsible UI */}
                {(isPatient || isProvider) && (
                    <FilterListSection 
                        label="State" 
                        icon={<LocationOnIcon />} 
                        defaultExpanded={false}
                    >
                        <StateFilter />
                    </FilterListSection>
                )}
            </>
        </SidebarFilter>
    );
}