import * as React from 'react';
import { makeStyles } from '@mui/styles';
import CustomDatePicker from "./customDatePicker.js"

const useStyles = makeStyles({
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between'
    },
});

const PeriodInput = ({ source, fromLabel, toLabel }) => {
    const classes = useStyles();
    
    return (
        <span className={classes.row}>
            <CustomDatePicker 
                source={`${source}.gte`} 
                label={fromLabel} 
                useStartOfDay={true} 
            />
            &nbsp;
            <CustomDatePicker 
                source={`${source}.lte`} 
                label={toLabel} 
                useEndOfDay={true}
            />
        </span>
    );
};

export default PeriodInput;