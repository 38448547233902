import React from 'react';
import {
    Show, 
    SimpleShowLayout,
    TextField,
    TopToolbar,
    EditButton,
    DeleteButton
} from 'react-admin';
import VersionTitle from './fields/versionTitle.component';
import DefaultPlaceholderField from '../../material/defPlaceholderField.component';
import YesNoField from '../../material/yesNoField.component';
import { versionsFields } from '../versions.model';
import VersionPlatform from './fields/versionPlatform.component';
import VersionAppType from './fields/versionAppType.component';

const ShowActions = () => (
    <TopToolbar>
        <EditButton />
        <DeleteButton mutationMode="pessimistic" />
    </TopToolbar>
);

const VersionShow = () => {
    return (
        <Show 
            title={<VersionTitle />} 
            actions={<ShowActions />}
        >
            <SimpleShowLayout>
                <DefaultPlaceholderField source={versionsFields.id} wrapField={<TextField/>} label="Id" />
                <VersionPlatform label="Platform" />
                <VersionAppType label="App type" />
                <DefaultPlaceholderField source={versionsFields.version} wrapField={<TextField/>} label="Version" />
                <YesNoField source={versionsFields.forceUpdate} wrapField={<TextField/>} label="Force Update" />
                <DefaultPlaceholderField source={versionsFields.build} wrapField={<TextField/>} label="Build" />
                <DefaultPlaceholderField source={versionsFields.releaseNotes} wrapField={<TextField/>} label="Release Notes" />
            </SimpleShowLayout>
        </Show>
    );
};

export default VersionShow;
