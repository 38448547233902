import React from 'react';
import { Link, useRecordContext, TextField } from 'react-admin';
import { userFields, userOrgCodeFields } from '../../user.model';
import DefaultPlaceholderField from '../../../material/defPlaceholderField.component';

const OrganizationCodeLinkField = ({ label }) => {
    const record = useRecordContext();

    if (!record) {
        return null;
    }

    const orgCodes = record[userFields.orgCodes];
    if (!orgCodes || orgCodes.length === 0) {
        return <DefaultPlaceholderField source={userFields.orgCodes} wrapField={<TextField/>} />;
    }

    return (
        <div>
            {orgCodes.map((code, index) => (
                <React.Fragment key={code[userOrgCodeFields.id]}>
                    <Link to={`/organization-codes/${code[userOrgCodeFields.id]}/show`}>
                        <TextField record={code} source={userOrgCodeFields.name} />
                    </Link>
                    {index < orgCodes.length - 1 && <TextField record={{text: ', '}} source="text" />}
                </React.Fragment>
            ))}
        </div>
    );
};

export default OrganizationCodeLinkField;