import { ADMIN_RESOURCE, PATIENT_RESOURCE, PROVIDER_RESOURCE, USER_ROLE_RESOURCE_MAPPER } from './constants';

export let userFields = {
    // id: "id",
    // email: "email",
    // firstName: "first_name",
    // lastName: "last_name",
    // roleId: "role_id",
    // roleName: "role_name",
    // dob: "dob",
    // dueDate: "due_date",
    // lang: "lang",
    // recurlyActive: "recurly_active",
    // isActive: "is_active",
    // isDeleted: "is_deleted",
    // phoneNumber: "phone_number",
    // orgId: "org_id",
    // orgName: "org_name",
    // usState: "us_state",
    // createdAt: "created_at",
    // updatedAt: "updated_at",
    // isMfaRequired: "mfa_required",
    // code: "code_name",
    // codes: "codes",
    // recurly_uuid: "recurly_uuid",
    // code_active: "coupon_active",
    // code_id: "coupon_id",
    // coupon_name: "coupon_name",
    // providerRoleIds: "provider_role_ids",
    // orgCodes: "org_codes",
    // gift: "gift"

    //export, list, show, edit, create
    id: "id",
    //export, filter, list, show, edit, create
    email: "email",
    //export, filter, list, show, edit, create
    firstName: "first_name",
    //export, filter, list, show, edit, create
    lastName: "last_name",
    //filter, list, edit, create
    roleId: "role_id",
    //export, filter, list, show, create
    roleName: "role_name",
    //export, list, show, edit, create
    dob: "dob",
    //export, list, show, edit, create
    dueDate: "due_date",
    //export, list, show, edit, create
    lang: "lang",
    //export, filter, list, show
    recurlyActive: "recurly_active",
    //export
    race: "race",
    //export
    zip: "zip",
    //export, show, edit
    birthWish: "birth_wish",
    //export, show, edit
    birthPartner: "birth_partner",
    //export, show, edit
    prenatalVisit: "prenatal_visit",
    //export, show, edit
    feedingPlan: "feeding_plan",
    //export, filter, list, show, reate
    isActive: "is_active",
    //filter, list
    isDeleted: "is_deleted",
    //export, show, edit, create
    phoneNumber: "phone_number",
    //filter, show, edit, create
    orgId: "org_id",
    //export, show
    orgName: "org_name",
    //export, show, edit, create
    usState: "us_state",
    //export, show
    createdAt: "created_at",
    //show
    updatedAt: "updated_at",
    //show, edit, create
    isMfaRequired: "mfa_required",

    //SDK-Admin show
    code: "code_name",
    codeId: "code_id",
    
    //codes: "codes",    
    //recurly_uuid: "recurly_uuid",
    recurlyUuid: "recurly_uuid",
//    code_active: "coupon_active",
//    code_id: "coupon_id",
//    coupon_name: "coupon_name",
    //providerRoleIds: "provider_role_ids",
    //filter, show, edit, create
    providerRoles: "provider_roles",
    providerRoleIds: "provider_role_ids",
    //providerRoleNames: "provider_role_names",
    //export, show
    orgCodes: "org_codes",
    //gift: "gift"    
    //show
    gifts: "gifts",

    //export, show, edit, create
    relatives: "relatives",
    //export
    pushEnabled: "push_enabled",
    //export
    shareFeedback: "share_feedback",
    //sdk
    subscription: "subscription",
    //WIC ID
    wicId: "wic_id",
    //Healthie ID
    healthieId: "healthie_id",
    // Healthie permission template id
    permissionTemplateId: "template_permission_id",
    permissionTemplateName: "template_permission_name",
    providerBio: "provider_bio",
    providerBioEs: "provider_bio_es",
    zipCode: "zip_code",
};

export let userCombinedFields = {
    isSdkUserActive: "sdk_user_active",
};

export const userLang = {
    EN: "English",
    ES: "Spanish",
    ES_EN: "Spanish+English",
    FRCA: "French Canadian",
    FRCA_EN: "French Canadian+English"
};

export let userOrgCodeFields = {
    id: "id",
    name: "name"  
}

export let userGiftFields = {
    id: "id",
    code: "code"  
}

export let userProviderRoleFields = {
    id: "id",
    name: "name"
}

export let userRelativeFields = {
    id: "id",
    name: "name",
    dob: "dob"
}