import { Card, CardContent } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';

const LeftCard = withStyles(theme => ({
    root: {
        flexBasis: "15rem",
        flexGrow: 0,
        flexShrink: 0,
        maxWidth: "15rem",
        [theme.breakpoints.up("sm")]: {
            order: -1,
            width: "15rem",
            marginRight: "1em",
        },
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
}))(Card);

export function SidebarFilter ({children}) {
    return (
        <LeftCard>
            <CardContent>
                {children}
            </CardContent>
        </LeftCard>
    );
}