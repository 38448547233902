import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    downloadCSV,
    TopToolbar,
    ExportButton,
    useListContext
} from 'react-admin';
import { summaryFields } from '../summary.model';
import PeriodInput from "../../../app/periodInput.component";
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import moment from 'moment';

const exporter = summary => {
    const summaryForExport = summary.map(summary => {
        // console.log("exporter summary ::: ", summary);
        summary['Name'] = summary.name;
        summary['Number of codes redeemed'] = summary.activeCount === 0 ? "" : summary.activeCount;
        summary['Number of calls made'] = summary.callsCount === 0 ? "" : summary.callsCount;
        summary['Average call rating'] = summary.patientRating === "n/a" ? "" : summary.patientRating;
        return summary;
    });

    const csv = convertToCSV({
        data: summaryForExport,
        fields: ['Name', 'Number of codes redeemed', 'Number of calls made', 'Average call rating']
    });
    downloadCSV(csv, 'codes-summary-' + moment(new Date()).format('YYYY-MM-DD').toString());
};

const summaryFilters = [
    <PeriodInput
        source="redeemed"
        fromLabel="Redeemed At From"
        toLabel="Redeemed At To"
        label="Redeemed At"
    />,
    <PeriodInput
        source="created"
        fromLabel="Created At From"
        toLabel="Created At To"
        label="Created At"
    />
];

const ListActions = () => {
    const { total } = useListContext();
    return (
        <TopToolbar>
            <ExportButton disabled={total === 0} />
        </TopToolbar>
    );
};

const SummaryList = () => (
    <List 
        title="Codes Summary" 
        sort={{ field: 'activeCount', order: 'DESC' }} 
        exporter={exporter} 
        filters={summaryFilters}
        actions={<ListActions />}
        bulkActionButtons={false}
    >
        <Datagrid>
            <TextField source={summaryFields.name} label="Name" />
            <TextField source={summaryFields.activeCount} label="Number of Codes Redeemed" />
            <TextField source={summaryFields.callsCount} label="Number of Calls Made" />
            <TextField source={summaryFields.patientRating} label="Average Call Rating" />
        </Datagrid>
    </List>
);

export default SummaryList;
