import React from 'react';
import {
    CreateButton,
    Datagrid,
    DateField,
    EditButton,
    ExportButton,
    FilterButton,
    List,
    ShowButton,
    TextField,
    TextInput,
    TopToolbar,
    useListContext,
    useNotify
} from 'react-admin';
import { DATE_TIME_OPTIONS } from '../../app/app.component';
import PeriodInput from "../../app/periodInput.component";
import DefaultPlaceholderField from '../../material/defPlaceholderField.component';
import { orgsFields } from '../orgs.model';
import OrganizationLinkField from './fields/organizationLink.component';
import { OrganizationSidebarFilters } from './OrganizationSidebarFilters';
import {DEFAULT_LIST_PERPAGE} from "utils/constants";
import { organizationExporter } from "@/organizations/utils/organizationExport";

const filters = [
    <TextInput source={orgsFields.orgName} label="Name" alwaysOn />,
    <PeriodInput
        source="createdBetween"
        fromLabel="Created At From"
        toLabel="Created At To"
        label="Created At"
    />,
    <PeriodInput
        source="updatedBetween"
        fromLabel="Updated At From"
        toLabel="Updated At To"
        label="Updated At"
    />
];

const ListActions = () => {
    const { total } = useListContext();
    const notify = useNotify();

    return (
        <TopToolbar>
            <FilterButton />
            <CreateButton />
            <ExportButton
                disabled={total === 0}
                exporter={(organizations) => organizationExporter(organizations, notify)}
            />
        </TopToolbar>
    );
};

export const OrgsList = () => (
    <List
        exporter={organizationExporter}
        filters={filters}
        actions={<ListActions />}
        aside={<OrganizationSidebarFilters />}
        sort={{ field: 'id', order: 'DESC' }}
        perPage={DEFAULT_LIST_PERPAGE}
        sx={{
            '& .single-line-ellipsis': {
                maxWidth: '5em',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
            }
        }}
    >
        <Datagrid bulkActionButtons={false}>
            <OrganizationLinkField label="Id" source={orgsFields.id} />
            <DefaultPlaceholderField 
                source={orgsFields.clientGroupId} 
                wrapField={<TextField />} 
                label="Healthie Client Group ID" 
            />
            <DefaultPlaceholderField 
                source={orgsFields.orgName} 
                wrapField={<TextField />} 
                label="Name" 
            />
           
            <DefaultPlaceholderField 
                source={orgsFields.orgNursePhone} 
                wrapField={<TextField />} 
                label="Nurse Phone" 
            />
            <DefaultPlaceholderField 
                source={orgsFields.payerId} 
                wrapField={<TextField />} 
                label="Payer ID" 
            />
             <DefaultPlaceholderField 
                source={orgsFields.orgCreatedAt} 
                wrapField={<DateField />} 
                options={DATE_TIME_OPTIONS} 
                label="Created At" 
            />
            <DefaultPlaceholderField 
                source={orgsFields.orgUpdatedAt} 
                wrapField={<DateField />} 
                options={DATE_TIME_OPTIONS} 
                label="Updated At" 
            />
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);
