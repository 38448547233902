// Resource names for different user types
export const USERS_RESOURCE = "users";
export const ADMIN_RESOURCE = "admins";
export const PATIENT_RESOURCE = "patients";
export const PROVIDER_RESOURCE = "providers";

// Role to resource mapping
export const USER_ROLE_RESOURCE_MAPPER = {
    [ADMIN_RESOURCE]: "admin",
    [PATIENT_RESOURCE]: "patient",
    [PROVIDER_RESOURCE]: "provider"
}; 