// in src/dataProvider
import { fetchUtils, withLifecycleCallbacks } from 'react-admin';
import auth from '../auth/auth0';
import UsersDataProviderRegistration from '../users/user.dataProvider';
import PatientsDataProviderRegistration from '../users/patient.dataProvider';
import ProvidersDataProviderRegistration from '../users/provider.dataProvider';
import AdminsDataProviderRegistration from '../users/admin.dataProvider';
import RolesDataProviderRegistration from '../roles/roles.dataProvider';
import ProviderRolesDataProviderRegistration from '../roles/provider.roles.dataProvider';
import NonProviderRolesDataProviderRegistration from '../roles/non.provider.roles.dataProvider';
import OrgsDataProviderRegistration from '../organizations/organizations.dataProvider';
import LinksDataProviderRegistration from '../contentLinks/contentLinks.dataProvider';
import UsStatesDataProviderRegistration from '../usStates/usStates.dataProvider';
import LanguagesDataProviderRegistration from '../languages/languages.dataProvider';
import VersionsDataProviderRegistration from '../versions/versions.dataProvider';
import GiftsDataProviderRegistration from '../gifts/gifts.dataProvider';
import CodesDataProviderRegistration from '../codes/codes.dataProvider';
import CallsDataProviderRegistration from '../calls/calls.dataProvider';
import QueuesDataProviderRegistration from '../queues/queues.dataProvider';
import PinpointDataProviderRegistration from '../pinpoint/pinpoint.dataProvider';
import HotlineDataProviderRegistration from '../hotline/hotline.dataProvider';
import ChartsDataProviderRegistration from '../charts/charts.dataProvider';
import FeedingPlansDataProviderRegistration from '../prenatalInfo/feedingPlans.dataProvider';
import ChatsDataProviderRegistration from '../chats/chats.dataProvider';
import PermissionTemplatesRegistration from '../permissionTemplates/permission.template.dataProvider';
import { pregnancyMilestoneNotificationsDataProvider, pregnancyMilestoneNotificationDeliveriesDataProvider, pregnancyMilestoneNotificationAnalyticsDataProvider } from '../notifications/pregnancyMilestoneNotifications.dataProvider';
import { allOrganizationsDataProvider as AllOrgsDataProviderRegistration } from '../organizations/organizations.dataProvider';
import dashboardDataProvider from '../dashboard/dashboard.dataProvider';

/**
 * Each Provider's registration should conform to the interface
 * 
 * {
 *     resource: <REACT_ADMIN_RESOURCE_NAME>,
 *     providerInterface: {
 *         providerRequestToHttpRequest,
 *         httpResponseToProviderData
 *     },
 * }
 */
const DATA_PROVIDER_REGISTRATIONS = [
    PatientsDataProviderRegistration,
    ProvidersDataProviderRegistration,
    AdminsDataProviderRegistration,
    UsersDataProviderRegistration,
    RolesDataProviderRegistration,
    ProviderRolesDataProviderRegistration,
    NonProviderRolesDataProviderRegistration,
    OrgsDataProviderRegistration,    
    AllOrgsDataProviderRegistration,
    LinksDataProviderRegistration,
    UsStatesDataProviderRegistration,
    LanguagesDataProviderRegistration,
    VersionsDataProviderRegistration,
    GiftsDataProviderRegistration,
    CodesDataProviderRegistration,
    CallsDataProviderRegistration,
    QueuesDataProviderRegistration,
    PinpointDataProviderRegistration,
    HotlineDataProviderRegistration,
    ChartsDataProviderRegistration,
    FeedingPlansDataProviderRegistration,
    ChatsDataProviderRegistration,
    PermissionTemplatesRegistration,
    pregnancyMilestoneNotificationsDataProvider,
    pregnancyMilestoneNotificationDeliveriesDataProvider,
    pregnancyMilestoneNotificationAnalyticsDataProvider,
    dashboardDataProvider
];

const resolveDataProvider = (resource) => {
    const registration = DATA_PROVIDER_REGISTRATIONS.find(
        registration => registration.resource === resource
    );
    
    if (!registration) {
        throw new Error(`Could not find data provider for resource "${resource}"`);
    }

    return registration.providerInterface;
};

const withAuthHeadersDecoration = (provider) => {
    return {
        providerRequestToHttpRequest: (type, params) => {
            const { url, options = {} } = provider.providerRequestToHttpRequest(type, params);
            const token = auth.getIdToken();
            const decoratedOptions = {
                ...options,
                headers: new Headers({
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                    ...(options.headers || {}),
                    ...(options.body ? { 'Content-Type': 'application/json' } : {}),
                }),
            };
            return { url, options: decoratedOptions };
        },
        httpResponseToProviderData: provider.httpResponseToProviderData,
    };
};

const lifecycleCallbacks = DATA_PROVIDER_REGISTRATIONS
    .filter(registration => registration.lifecycleCallbacks)
    .map(registration => registration.lifecycleCallbacks);

const baseDataProvider = {
    getList: (resource, params) => {
        const provider = withAuthHeadersDecoration(resolveDataProvider(resource));
        const { url, options } = provider.providerRequestToHttpRequest('GET_LIST', params);

        if (auth.isAuthenticated()) {
            auth.renewSessionIfExpiringSoon();
        } else {
            auth.logout();
            window.location.reload();
            return;
        }

        return fetchUtils.fetchJson(url, options).then(response => {
            return provider.httpResponseToProviderData(response, 'GET_LIST', params);
        }).catch(handleError);
    },

    getOne: (resource, params) => {
        const provider = withAuthHeadersDecoration(resolveDataProvider(resource));
        const { url, options } = provider.providerRequestToHttpRequest('GET_ONE', params);

        if (auth.isAuthenticated()) {
            auth.renewSessionIfExpiringSoon();
        } else {
            auth.logout();
            window.location.reload();
            return;
        }

        return fetchUtils.fetchJson(url, options).then(response => {
            return provider.httpResponseToProviderData(response, 'GET_ONE', params);
        }).catch(error => {
            return handleError(error);
        });
    },

    getMany: (resource, params) => {
        const provider = withAuthHeadersDecoration(resolveDataProvider(resource));
        const { url, options } = provider.providerRequestToHttpRequest('GET_MANY', params);

        if (auth.isAuthenticated()) {
            auth.renewSessionIfExpiringSoon();
        } else {
            auth.logout();
            window.location.reload();
            return;
        }

        return fetchUtils.fetchJson(url, options).then(response => {
            return provider.httpResponseToProviderData(response, 'GET_MANY', params);
        }).catch(handleError);
    },

    getManyReference: (resource, params) => {
        const provider = withAuthHeadersDecoration(resolveDataProvider(resource));
        const { url, options } = provider.providerRequestToHttpRequest('GET_MANY_REFERENCE', params);

        if (auth.isAuthenticated()) {
            auth.renewSessionIfExpiringSoon();
        } else {
            auth.logout();
            window.location.reload();
            return;
        }

        return fetchUtils.fetchJson(url, options).then(response => {
            return provider.httpResponseToProviderData(response, 'GET_MANY_REFERENCE', params);
        }).catch(handleError);
    },

    update: (resource, params) => {
        const provider = withAuthHeadersDecoration(resolveDataProvider(resource));
        const { url, options } = provider.providerRequestToHttpRequest('UPDATE', params);

        if (auth.isAuthenticated()) {
            auth.renewSessionIfExpiringSoon();
        } else {
            auth.logout();
            window.location.reload();
            return;
        }

        return fetchUtils.fetchJson(url, options).then(response => {
            return provider.httpResponseToProviderData(response, 'UPDATE', params);
        }).catch(handleError);
    },

    create: (resource, params) => {
        const provider = withAuthHeadersDecoration(resolveDataProvider(resource));
        const { url, options } = provider.providerRequestToHttpRequest('CREATE', params);

        if (auth.isAuthenticated()) {
            auth.renewSessionIfExpiringSoon();
        } else {
            auth.logout();
            window.location.reload();
            return;
        }

        return fetchUtils.fetchJson(url, options).then(response => {
            return provider.httpResponseToProviderData(response, 'CREATE', params);
        }).catch(handleError);
    },

    delete: (resource, params) => {
        const provider = withAuthHeadersDecoration(resolveDataProvider(resource));
        const { url, options } = provider.providerRequestToHttpRequest('DELETE', params);

        if (auth.isAuthenticated()) {
            auth.renewSessionIfExpiringSoon();
        } else {
            auth.logout();
            window.location.reload();
            return;
        }

        return fetchUtils.fetchJson(url, options).then(response => {
            return provider.httpResponseToProviderData(response, 'DELETE', params);
        }).catch(handleError);
    },

    deleteMany: (resource, params) => {
        const provider = withAuthHeadersDecoration(resolveDataProvider(resource));
        const { url, options } = provider.providerRequestToHttpRequest('DELETE_MANY', params);

        if (auth.isAuthenticated()) {
            auth.renewSessionIfExpiringSoon();
        } else {
            auth.logout();
            window.location.reload();
            return;
        }

        return fetchUtils.fetchJson(url, options).then(response => {
            return provider.httpResponseToProviderData(response, 'DELETE_MANY', params);
        }).catch(handleError);
    },

    updateChart: (resource, params) => {
        const provider = withAuthHeadersDecoration(resolveDataProvider(resource));
        const { url, options } = provider.providerRequestToHttpRequest('POST_UPDATE_CHART', params.data);

        if (auth.isAuthenticated()) {
            auth.renewSessionIfExpiringSoon();
        } else {
            auth.logout();
            window.location.reload();
            return;
        }

        return fetchUtils.fetchJson(url, options).then(response => {
            return provider.httpResponseToProviderData(response, 'POST_UPDATE_CHART', params);
        }).catch(handleError);
    },

    updateMedicaid: (resource, params) => {
        const provider = withAuthHeadersDecoration(resolveDataProvider(resource));
        const { url, options } = provider.providerRequestToHttpRequest('PUT_MEDICAID', params);

        if (auth.isAuthenticated()) {
            auth.renewSessionIfExpiringSoon();
        } else {
            auth.logout();
            window.location.reload();
            return;
        }

        return fetchUtils.fetchJson(url, options).then(response => {
            return provider.httpResponseToProviderData(response, 'PUT_MEDICAID', params);
        }).catch(handleError);
    },

    uploadToPinpoint: (resource, params) => {
        const provider = withAuthHeadersDecoration(resolveDataProvider(resource));
        const { url, options } = provider.providerRequestToHttpRequest('PUT_PINPOINT', params.data);

        if (auth.isAuthenticated()) {
            auth.renewSessionIfExpiringSoon();
        } else {
            auth.logout();
            window.location.reload();
            return;
        }

        return fetchUtils.fetchJson(url, options).then(response => {
            return provider.httpResponseToProviderData(response, 'PUT_PINPOINT', params);
        }).catch(handleError);
    },

    changePassword: (resource, params) => {
        const provider = withAuthHeadersDecoration(resolveDataProvider(resource));
        const { url, options } = provider.providerRequestToHttpRequest('POST_CHANGE_PASSWORD', params);

        if (auth.isAuthenticated()) {
            auth.renewSessionIfExpiringSoon();
        } else {
            auth.logout();
            window.location.reload();
            return;
        }

        return fetchUtils.fetchJson(url, options).then(response => {
            return provider.httpResponseToProviderData(response, 'POST_CHANGE_PASSWORD', params);
        }).catch(handleError);
    },

    getDashboardStats: () => {
        const provider = withAuthHeadersDecoration(resolveDataProvider(dashboardDataProvider.resource));
        const { url, options } = provider.providerRequestToHttpRequest('GET_DASHBOARD_STATS');

        if (auth.isAuthenticated()) {
            auth.renewSessionIfExpiringSoon();
        } else {
            auth.logout();
            window.location.reload();
            return;
        }

        return fetchUtils.fetchJson(url, options).then(response => {
            return provider.httpResponseToProviderData(response, 'GET_DASHBOARD_STATS');
        }).catch(handleError);
    }
};

const handleError = (response) => {
    if (response != null && (response.status === 403 || response.status === 401)) {
        alert("Session expired. Please log in again to continue.");
        window.location='/';
    }
    else if (response != null && response.body != null) {
        if (response.body.reason) {
            // Include both message and reason if available
            return Promise.reject({
                ...response.body,
                message: `${response.body.message}${response.body.reason ? `: ${response.body.reason}` : ''}`
            });
        }
        // Standard server error format
        return Promise.reject(response.body);
    }

    return Promise.reject(response); // Pass up whole error if it doesn't match the expected pattern.
};

export default withLifecycleCallbacks(baseDataProvider, lifecycleCallbacks);
