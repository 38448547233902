import * as React from 'react';
import { TextField } from 'react-admin';
import { codesFields, CodeTypeNames } from '../../codes.model';

const CodeType = ({ record = {}, source, ...rest }) => {
    const codeTypeName = CodeTypeNames[record[codesFields.codeType]] || 'n/a';
    const recordCopy = {
        ...record,
        codeTypeName,
    };

    return <TextField source="codeTypeName" record={recordCopy} {...rest} />;
};

export default CodeType;
