// in src/dataProvider
import { fetchUtils } from 'react-admin';

import auth from '../auth/auth0';
import SdkUserDataProviderRegistration from '../sdk/users/sdk.user.dataProvider';
import CodesDataProviderRegistration from '../sdk/sdk-codes/codes.dataProvider';
import DiscountCodesDataProviderRegistration from '../sdk/discount-codes/discountCodes.dataProvider';
import SDKCodesPlansDataProviderRegistration from '../sdk/plans/plans.dataProvider';
import SDKCodesSummaryDataProviderRegistration from '../sdk/codes-summary/summary.dataProvider';


/**
 * Each Provider's registration should conform to the interface
 * 
 * {
 *     resource: <REACT_ADMIN_RESOURCE_NAME>,
 *     providerInterface: {
 *         providerRequestToHttpRequest,
 *         httpResponseToProviderData
 *     },
 * }
 */
const DATA_PROVIDER_REGISTRATIONS = [
    SdkUserDataProviderRegistration,
    CodesDataProviderRegistration,
    DiscountCodesDataProviderRegistration,
    SDKCodesPlansDataProviderRegistration,
    SDKCodesSummaryDataProviderRegistration
];

/**
* @param {string} type Request type, e.g GET_LIST
* @param {string} resource Resource name, e.g. "posts"
* @param {Object} payload Request parameters. Depends on the request type
* @returns {Promise} the Promise for response
*/
export default (requestType, resource, requestParams) => {
    // console.log(`Requesting "${resource}" resource by "${requestType}" method.`);
    let { fetchJson } = fetchUtils;
    let dataProvider = withAuthHeadersDecoration(resolveDataProvider(resource));
    let { url, options } = dataProvider.providerRequestToHttpRequest(requestType, requestParams);

    return fetchJson(url, options).then(response =>
        dataProvider.httpResponseToProviderData(response, requestType, requestParams)
    );
};

/**
 * Decorates passed provider with authorization headers.
 */
const withAuthHeadersDecoration = (provider) => ({
    
    providerRequestToHttpRequest(requestType, requestParams) {
        let { url, options = {} } = provider.providerRequestToHttpRequest(requestType, requestParams);
        options.headers = new Headers({ Authorization: 'Bearer ' + auth.getIdToken() });
        return {url, options};
    },

    httpResponseToProviderData(httpResponse, requestType, requestParams) {
        return provider.httpResponseToProviderData(httpResponse, requestType, requestParams);
    }
});

const resolveDataProvider = (askedResource) => {
    let { providerInterface } = DATA_PROVIDER_REGISTRATIONS.find(registration => {
        let { resource, providerInterface } = registration;
        if (!resource) {
            throw new Error("DataProvider's registration must have resource field");
        }
        if (!providerInterface || typeof providerInterface !== 'object') {
            throw new Error("DataProvider's registration must have providerInterface field of Object type");
        }
        return resource === askedResource;
    });

    if (!providerInterface) {
        console.error(`Requested resource's "${askedResource}" DataProvider not found. Check data provider registrations.`);
    }
    return providerInterface;
};
