import * as React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    BooleanInput,
    required,
    SaveButton,
    TopToolbar,
    useNotify,
    useRedirect,
    useRefresh
} from 'react-admin';
import { makeStyles } from '@mui/styles';
import { orgsFields } from '../orgs.model';
import { validatePhone } from '../../utils/validation.js';
import ProviderRoleOrderedList from '../../components/ProviderRoleOrderedList';

const useStyles = makeStyles({
    roleName: { display: 'inline-block' },
    lineType: { display: 'inline-block', marginLeft: 32 }
});

const CreateActions = () => (
    <TopToolbar>
        <SaveButton />
    </TopToolbar>
);

const OrgCreate = () => {
    const classes = useStyles();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onSuccess = () => {
        notify('Organization created successfully');
        refresh();
        redirect('list', 'organizations');
    };

    return (
        <Create mutationOptions={{ onSuccess }}>
            <SimpleForm>
                <TextInput source={orgsFields.orgName} label='Name' validate={required()} />
                <TextInput source={orgsFields.orgNursePhone} label='Nurse Phone' validate={validatePhone()} />
                <TextInput source={orgsFields.payerId} label="Payer ID" />
                <BooleanInput source={orgsFields.contentLibraryEnabled} label="Content Library Enabled" />
                <BooleanInput source={orgsFields.medicaidIdRegistrationQuestionEnabled} label="Medicaid ID Registration Question Enabled" />
                <BooleanInput source={orgsFields.chatEnabled} label="Chat Enabled" />
                <BooleanInput source={orgsFields.enrollmentCodeRequired} label="Enrollment Code Required" />
                <BooleanInput source={orgsFields.requiresWicId} label=" WIC ID Required" />
                <ProviderRoleOrderedList rolesFieldName={orgsFields.roleIds}/>
            </SimpleForm>
        </Create>
    );
};

export default OrgCreate;