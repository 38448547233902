import { useReducer } from 'react';
import { reducer } from '../state/orgShowReducer';
import {
    SET_CSV_FILE,
    SET_DATA_MONTH,
    SET_DATA_YEAR,
    TOGGLE_DATA_DIALOG,
    SET_FILE_STATUS,
    SET_VALIDATION_ERRORS,
    SET_UPLOAD_PROGRESS,
    initialState,
    FILE_STATUS
} from '../state/orgShowTypes';

export const useOrgState = () => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const setCsvFile = (file) => dispatch({ type: SET_CSV_FILE, payload: file });
    const setDataMonth = (month) => dispatch({ type: SET_DATA_MONTH, payload: month });
    const setDataYear = (year) => dispatch({ type: SET_DATA_YEAR, payload: year });
    const toggleDataDialog = () => dispatch({ type: TOGGLE_DATA_DIALOG });
    const setFileStatus = (status) => dispatch({ type: SET_FILE_STATUS, payload: status });
    const setValidationErrors = (errors) => dispatch({ type: SET_VALIDATION_ERRORS, payload: errors });
    const setUploadProgress = (progress) => dispatch({ type: SET_UPLOAD_PROGRESS, payload: progress });

    return {
        state,
        setCsvFile,
        setDataMonth,
        setDataYear,
        toggleDataDialog,
        setFileStatus,
        setValidationErrors,
        setUploadProgress,
        FILE_STATUS
    };
}; 