import React from 'react';
import {
    FilterLiveSearch
} from 'react-admin';
import { SidebarFilter } from '../../filters/SidebarFilter';
import { SidebarFilterBoolean } from '../../filters/sidebarFilterItems';
import { orgsFields } from '../orgs.model';

export const OrganizationSidebarFilters = () => (
    <SidebarFilter>
        <> 
            <FilterLiveSearch source="q" />

            <SidebarFilterBoolean label="WIC ID" value={orgsFields.requiresWicId} />
            <SidebarFilterBoolean label="Nurse Phone" value={orgsFields.nursePhone} />
            <SidebarFilterBoolean label="Enrollment Code Required" value={orgsFields.enrollmentCodeRequired} />
            <SidebarFilterBoolean label="Content Library Enabled" value={orgsFields.contentLibraryEnabled} />
        </>
    </SidebarFilter>
);
