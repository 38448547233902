import * as React from 'react';
import { UserMenu, useUserMenu, Logout, useGetIdentity } from 'react-admin';
import { MenuItem, ListItemIcon, ListItemText, Typography, Divider } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import BadgeIcon from '@mui/icons-material/Badge';

const UserInfoMenuItem = React.forwardRef((props, ref) => {
    const { onClose } = useUserMenu();
    const { identity } = useGetIdentity();
    return (
        <MenuItem
            onClick={onClose}
            ref={ref}
            {...props}
            sx={{ 
                '&:hover': { 
                    backgroundColor: 'transparent',
                    cursor: 'default'
                }
            }}
        >
            <ListItemIcon>
                <PersonIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
                <Typography variant="body2" color="textSecondary">
                    {identity?.email}
                </Typography>
            </ListItemText>
        </MenuItem>
    );
});

const RoleInfoMenuItem = React.forwardRef((props, ref) => {
    const { onClose } = useUserMenu();
    const { identity } = useGetIdentity();
    return (
        <MenuItem
            onClick={onClose}
            ref={ref}
            {...props}
            sx={{ 
                '&:hover': { 
                    backgroundColor: 'transparent',
                    cursor: 'default'
                }
            }}
        >
            <ListItemIcon>
                <BadgeIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
                <Typography variant="body2" color="textSecondary">
                    {identity?.userRole}
                </Typography>
            </ListItemText>
        </MenuItem>
    );
});

export const CustomUserMenu = () => {
    const { identity } = useGetIdentity();
    return (
        <UserMenu>
            <UserInfoMenuItem />
            <RoleInfoMenuItem />
            <Divider />
            <Logout />
        </UserMenu>
    );
}; 