import dataProvider from "../../../app/dataProvider";
import { RESOURCE_NAME as ORGANIZATIONS_RESOURCE, PUT_MEDICAID } from "../../organizations.dataProvider";
import { formatDateSent } from './dateUtils';

export async function getPresignedUrl(orgId) {
    const response = await dataProvider(PUT_MEDICAID, ORGANIZATIONS_RESOURCE, {
        id: orgId,
        action: "getPresignedUrl"
    });
    return {
        url: response.data.url,
        key: response.data.key
    };
}

export async function uploadToS3(presignedUrl, file) {
    const response = await fetch(presignedUrl, {
        method: "PUT",
        body: file,
        headers: { "Content-Type": "text/csv" },
    });
    return response.ok;
}

export async function processUploadedCsv(orgId, s3Key, year, month) {
    return dataProvider(PUT_MEDICAID, ORGANIZATIONS_RESOURCE, {
        id: orgId,
        action: "processUploadedCsv",
        s3FileKey: s3Key,
        dateSent: formatDateSent(year, month)
    });
} 