import React from 'react';
import { useNotify, useDataProvider, useRecordContext } from 'react-admin';
import { useMutation } from '@tanstack/react-query';
import { Box, Typography, Button } from '@mui/material';
import CsvUploader from '../../components/CsvUploader';
import { useOrgState } from './hooks/useOrgState';
import { FILE_STATUS } from './state/orgShowTypes';
import { parseAndValidateCSV, validateRow } from './utils/csvValidation';
import { uploadToS3 } from './utils/uploadUtils';
import { RESOURCE_NAME as ORGANIZATIONS_RESOURCE } from "../organizations.dataProvider";
import DataDialog from "./dataDialog.component";

const notificationOpts = {
    type: 'error',
    autoHideDuration: 60000,
    messageArgs: { _: { persistent: true } }
};

const MedicaidMemberListUpload = () => {
    const record = useRecordContext();
    if (!record) return null;

    const notify = useNotify();
    const dataProvider = useDataProvider();

    const {
        state: { csvFile, dataMonth, dataYear, isDataDialogOpen, fileStatus },
        setCsvFile,
        setDataMonth,
        setDataYear,
        toggleDataDialog,
        setFileStatus
    } = useOrgState();

    const handleFileSelect = (fileInput) => {
        const file = fileInput?.file || fileInput;
        
        if (!file) {
            setFileStatus(FILE_STATUS.REMOVED);
            setCsvFile(null);
            return;
        }

        if (file.type !== 'text/csv') {
            setFileStatus(FILE_STATUS.REJECTED_FILE_TYPE);
            notify('Invalid file type. Please upload a CSV file.', notificationOpts);
            return;
        }

        setFileStatus(FILE_STATUS.PREPARING);
        setCsvFile(file);
        validateCSV({ file });
    };

    const formatDateSent = (year, month) => {
        const paddedMonth = month.toString().padStart(2, '0');
        return `${year}-${paddedMonth}`;
    };

    const { mutate: getPresignedUrl } = useMutation({
        mutationFn: async (orgId) => {
            return dataProvider.updateMedicaid(ORGANIZATIONS_RESOURCE, {
                id: orgId,
                action: "getPresignedUrl"
            });
        },
        onSuccess: async ({ data: { url: presignedUrl, key: s3Key } }) => {
            const uploadSuccess = await uploadToS3(presignedUrl, csvFile);
            if (uploadSuccess) {
                notify('CSV uploaded successfully, You will be notified by email when it is complete.', { 
                    ...notificationOpts, 
                    type: 'success' 
                });
                processUpload({ 
                    orgId: record.id, 
                    s3Key, 
                    year: dataYear, 
                    month: dataMonth 
                });
                toggleDataDialog();
            } else {
                notify('Upload to S3 failed.', notificationOpts);
            }
        },
        onError: (error) => {
            console.error("Failed to get presigned URL:", error);
            notify('Failed to get upload URL', notificationOpts);
        }
    });

    const { mutate: processUpload } = useMutation({
        mutationFn: async ({ orgId, s3Key, year, month }) => {
            return dataProvider.updateMedicaid(ORGANIZATIONS_RESOURCE, {
                id: orgId,
                action: "processUploadedCsv",
                s3FileKey: s3Key,
                dateSent: formatDateSent(year, month)
            });
        },
        onSuccess: () => {
            setCsvFile(null);
            setFileStatus(FILE_STATUS.REMOVED);
        },
        onError: (error) => {
            console.error("Failed to process upload:", error);
            notify('Failed to process upload', notificationOpts);
            setCsvFile(null);
            setFileStatus(FILE_STATUS.REMOVED);
        }
    });

    async function uploadMedicaidCSV() {
        if (!csvFile) {
            notify('Please attach a csv file', notificationOpts);
            return;
        }

        if (!record?.id) {
            notify('Organization ID not found', notificationOpts);
            return;
        }

        getPresignedUrl(record.id);
    }

    function validateCSV(csvFile) {
        csvFile.file.text().then((csvText) => {
            try {
                const { headers, missingHeaders, data, duplicates } = parseAndValidateCSV(csvText);

                if (missingHeaders.length > 0) {
                    notify(`Incorrect or missing headers: ${missingHeaders.join(", ")}.`, notificationOpts);
                    setFileStatus(FILE_STATUS.REMOVED);
                    setCsvFile(null);
                    return;
                }

                let hasErrors = false;
                for (let i = 0; i < data.length; i++) {
                    const rowError = validateRow(data[i], i);
                    if (rowError) {
                        notify(rowError, notificationOpts);
                        hasErrors = true;
                        break;
                    }
                }

                if (hasErrors) {
                    setFileStatus(FILE_STATUS.REMOVED);
                    setCsvFile(null);
                    return;
                }

                const { emails, phones, medicaidIDs } = duplicates;
                if (emails.length > 0 || phones.length > 0 || medicaidIDs.length > 0) {
                    const warningMessage = `Duplicate value(s) found: ${emails.length > 0
                        ? `Emails: ${emails.join(", ")}`
                        : ""
                        }${phones.length > 0
                            ? ` Phones: ${phones.join(", ")}`
                            : ""
                        }${medicaidIDs.length > 0
                            ? ` Medicaid IDs: ${medicaidIDs.join(", ")}`
                            : ""
                        }. Only the last entry will be saved.`;
                    notify(warningMessage, { ...notificationOpts, type: 'warning' });
                }

                setFileStatus(FILE_STATUS.DONE);
                toggleDataDialog();
            } catch (error) {
                notify('Error parsing CSV file', notificationOpts);
                setFileStatus(FILE_STATUS.REMOVED);
                setCsvFile(null);
            }
        }).catch(error => {
            notify('Error reading CSV file', notificationOpts);
            setFileStatus(FILE_STATUS.REMOVED);
            setCsvFile(null);
        });
    }

    const handleDialogClose = () => {
        toggleDataDialog();
    };

    return (
        <Box>
            <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                Medicaid Member List Upload
            </Typography>
            <CsvUploader 
                onFileSelect={handleFileSelect}
                selectedFile={csvFile}
                dropzoneText="Upload Medicaid member list csv file. Drag or click to choose."
            />
            <Box sx={{ mt: 2 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={uploadMedicaidCSV}
                    disabled={!csvFile}
                >
                    Upload
                </Button>
            </Box>
            <DataDialog
                open={isDataDialogOpen}
                onClose={handleDialogClose}
                onSubmit={uploadMedicaidCSV}
                dataMonth={dataMonth}
                dataYear={dataYear}
                onChangeDataMonth={setDataMonth}
                onChangeDataYear={setDataYear}
            />
        </Box>
    );
};

export default MedicaidMemberListUpload; 