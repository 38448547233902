import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    ReferenceInput,
    SelectInput,
    DateField,
    ExportButton,
    TopToolbar,
    FilterButton,
    useListContext
} from 'react-admin';
import { hotlineFields } from '../hotline.model';
import { CallLineType } from '../../roles/roles.model';
import { callStatus, CallStatusNames } from '../../calls/calls.model';
import DefaultPlaceholderField from '../../material/defPlaceholderField.component';
import BlankPlaceholderField from '../../material/blankPlaceholderField.component';
import PeriodInput from "../../app/periodInput.component";
import { RESOURCE_NAME as ROLES_RESOURCE } from '../../roles/roles.dataProvider';
import CallStatus from '../../calls/component/fields/callStatus.component';
import CallProvChartList from '../../calls/component/fields/callProvChartList.component';
import ProviderLinkField from '../../calls/component/fields/providerLink.component';
import CallTimeLabel from '../../calls/component/fields/callTimeLabel.component';
import ProviderEndReason from '../../calls/component/fields/providerEndReason.component';
import PatientEndReason from '../../calls/component/fields/patientEndReason.component';
import CallLinkField from '../../calls/component/fields/callLink.component';
import VoicemailField from '../../material/voicemailField.component';
import hotlineExporter from './hotlineExporter';

const choiceForCallStatus = (status) => ({
    id: status,
    name: CallStatusNames[status]
});

const hotlineFilters = [
    <SelectInput
        label="Call Status"
        source={hotlineFields.status}
        choices={[
            choiceForCallStatus(callStatus.REQUESTED),
            choiceForCallStatus(callStatus.ACCEPTED),
            choiceForCallStatus(callStatus.CANCELED),
            choiceForCallStatus(callStatus.COMPLETED),
            choiceForCallStatus(callStatus.MISSED),
            choiceForCallStatus(callStatus.ERROR)
        ]}
    />,
    <ReferenceInput 
        label="Call Type" 
        filter={{ line_type: CallLineType.PhoneToPacifyProvider.id }} 
        perPage={1000}
        source={hotlineFields.groupId} 
        reference={ROLES_RESOURCE}
    >
        <SelectInput optionText="name" />
    </ReferenceInput>,
    <TextInput label="Provider First Name Contains" source={hotlineFields.providerFirstName} allowEmpty />,
    <TextInput label="Provider Last Name Contains" source={hotlineFields.providerLastName} allowEmpty />,
    <PeriodInput
        source="startedBetween"
        fromLabel="Call Started At From"
        toLabel="Call Started At To"
        label="Call Started At"
    />,
    <PeriodInput
        source="endedBetween"
        fromLabel="Call Ended At From"
        toLabel="Call Ended At To"
        label="Call Ended At"
    />
];

const CALL_DATE_OPTIONS = {  
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'Etc/GMT+4'  
};

const ListActions = () => {
    const { total, filterValues } = useListContext();
    return (
        <TopToolbar>
            <FilterButton />
            <ExportButton 
                disabled={total === 0}
                maxResults={999999999}
            />
        </TopToolbar>
    );
};

const HotlineList = () => (
    <List 
        title="Hotline" 
        sort={{ field: 'id', order: 'DESC' }} 
        filters={hotlineFilters}
        exporter={hotlineExporter}
        bulkActionButtons={false}
        actions={<ListActions />}
    >
        <Datagrid>
            <CallLinkField label="Id" source={hotlineFields.id} sortable={true}/>
            <DefaultPlaceholderField source={hotlineFields.groupName} sortBy={hotlineFields.groupId} wrapField={<TextField/>} label="Type" />
            <DefaultPlaceholderField source={hotlineFields.lang} wrapField={<TextField/>} label="Lang" />
            <CallStatus source={hotlineFields.status} wrapField={<TextField/>} label="Status" />
            <CallProvChartList label="Provider Chart" />
            <DefaultPlaceholderField source={hotlineFields.phone} wrapField={<TextField/>} label="Patient Phone" />
            <DefaultPlaceholderField source={hotlineFields.patientRating} wrapField={<TextField/>} label="Patient Rating" />
            <ProviderLinkField label="Provider" addLabel/>
            <DefaultPlaceholderField source={hotlineFields.providerRating} wrapField={<TextField/>} label="Provider Rating" />
            <DefaultPlaceholderField source={hotlineFields.providerFeedback} wrapField={<TextField/>} label="Provider Feedback" />
            <DefaultPlaceholderField source={hotlineFields.callStartedAt} options={CALL_DATE_OPTIONS} wrapField={<DateField/>} label="Started At" />
            <DefaultPlaceholderField source={hotlineFields.acceptedAt} wrapField={<DateField/>} options={CALL_DATE_OPTIONS} label="Accepted At" />
            <DefaultPlaceholderField source={hotlineFields.endedAt} wrapField={<DateField/>} options={CALL_DATE_OPTIONS} label="Ended At" />
            <CallTimeLabel source={hotlineFields.timeToAnswer} sortable={false} label="Time to Answer" addLabel/>
            <CallTimeLabel source={hotlineFields.timeToCancel} sortable={false} label="Time to Cancel" addLabel/>
            <CallTimeLabel source={hotlineFields.timeToCallback} sortable={false} label="Time to Callback" addLabel/>
            <DefaultPlaceholderField source={hotlineFields.service} wrapField={<TextField/>} label="Service" />
            <ProviderEndReason label="Provider End Reason" />
            <PatientEndReason label="Patient End Reason" />
            <CallTimeLabel source={hotlineFields.callLength} sortable={false} addPlaceholder={true} label="Call Length" />
            <CallTimeLabel source={hotlineFields.callbackLength} sortable={false} addPlaceholder={true} label="Callback Length" />
            <CallTimeLabel source={hotlineFields.billableTime} sortable={false} addPlaceholder={true} label="Billable Time" />
            <BlankPlaceholderField source={hotlineFields.referral} sortable={false} wrapField={<TextField/>} label="Referral" />
            <BlankPlaceholderField source={hotlineFields.isRepeatCaller} sortable={false} wrapField={<TextField/>} label="Is Repeating Call" />
            <BlankPlaceholderField source={hotlineFields.isWic} sortable={false} wrapField={<TextField/>} label="Is WIC" />
            <BlankPlaceholderField source={hotlineFields.breastfeeding} sortable={false} wrapField={<TextField/>} label="Breastfeeding" />
            <BlankPlaceholderField source={hotlineFields.zipCode} sortable={false} wrapField={<TextField/>} label="Zip Code" />
            <BlankPlaceholderField source={hotlineFields.relation} sortable={false} wrapField={<TextField/>} label="Relation" />
            <BlankPlaceholderField source={hotlineFields.motherAge} sortable={false} wrapField={<TextField/>} label="Mother Age" />
            <BlankPlaceholderField source={hotlineFields.motherRace} sortable={false} wrapField={<TextField/>} label="Mother Race" />
            <BlankPlaceholderField source={hotlineFields.babyAge} sortable={false} wrapField={<TextField/>} label="Baby Age" />
            <BlankPlaceholderField source={hotlineFields.babyGestationalAge} sortable={false} wrapField={<TextField/>} label="Baby Gestational Age" />
            <VoicemailField source={hotlineFields.voicemail} label="Voicemail" />
        </Datagrid>
    </List>
);

export default HotlineList;
