import { endOfDay, endOfMonth, parseISO, startOfDay, startOfMonth } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import queryString from 'query-string';
import {
    CREATE,
    DELETE,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,
    UPDATE
} from 'react-admin';
import { USER_ROLES } from '../roles/constants';
import { RESOURCE_NAME as NON_PROVIDER_ROLES_RESOURCE } from '../roles/non.provider.roles.dataProvider';
import { USERS_RESOURCE } from './constants';
import { userFields } from "./user.model";

const API_URL = import.meta.env.VITE_API_URL;

const POST_NOTIF = "POST_NOTIF"
const POST_CHANGE_PASSWORD = "POST_CHANGE_PASSWORD"

export const RESOURCE_NAME = USERS_RESOURCE;

const providerRequestToHttpRequest = (requestType, requestParams) => {
    switch (requestType) {
        case POST_NOTIF:
            return {
                url: `${API_URL}/${RESOURCE_NAME}/notify`,
                options: {
                    method: 'POST',
                    body: JSON.stringify(requestParams.data)
                },
            };
        case POST_CHANGE_PASSWORD:
            return {
                url: `${API_URL}/${RESOURCE_NAME}/changePassword`,
                options: {
                    method: 'POST',
                    body: JSON.stringify({
                        userId: requestParams.data.userId,
                        password: requestParams.data.password
                    })
                },
            };
        case GET_LIST:
            return composeGetUsersListRequest(requestParams);
        case GET_ONE:
            return {
                url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}`
            };
        case GET_MANY:
            {
                const query = {
                    filter: JSON.stringify({
                        id: requestParams.ids
                    }),
                };
                return {
                    url: `${API_URL}/${RESOURCE_NAME}?${queryString.stringify(query)}`
                };
            }
        case GET_MANY_REFERENCE:
            {
                const {
                    page,
                    perPage
                } = requestParams.pagination;
                const {
                    field,
                    order
                } = requestParams.sort;
                const query = {
                    sort: JSON.stringify([field, order]),
                    range: JSON.stringify([(page - 1) * perPage, (page * perPage) - 1]),
                    filter: JSON.stringify({
                        ...requestParams.filter,
                        [requestParams.target]: requestParams.id
                    }),
                };
                return {
                    url: `${API_URL}/${RESOURCE_NAME}?${queryString.stringify(query)}`
                };
            }
        case UPDATE:
            return {
                url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}`,
                options: {
                    method: 'PUT',
                    body: JSON.stringify(requestParams.data)
                },
            };
        case CREATE:
            return {
                url: `${API_URL}/${RESOURCE_NAME}`,
                options: {
                    method: 'POST',
                    body: JSON.stringify(requestParams.data)
                },
            };
        case DELETE:
            return {
                url: `${API_URL}/${RESOURCE_NAME}/${requestParams.id}`,
                options: {
                    method: 'DELETE',
                },
            };
        default:
            throw new Error(`Unsupported fetch action type ${requestType}`);
    }
};

const composeGetUsersListRequest = (requestParams) => {
    const { page, perPage } = requestParams.pagination;
    const { field, order } = requestParams.sort;
    
    let prepareFilters = () => {
        let requestFilters = requestParams.filter;
        
        if (requestFilters === undefined) {
            return {};
        }

        let createEqFilter = (name, value) => ({
            name,
            comparison: "eq",
            value
        });

        let createIsNullFilter = (name) => ({
            name,
            comparison: "isnull"
        });
    
        let createIsNotNullFilter = (name) => ({
            name,
            comparison: "notnull"
        });

        let createInFilter = (name, value) => ({
            name,
            comparison: "in",
            value
        });
        let createContainsFilter = (name, value) => ({
            name,
            comparison: "contains",
            value
        });
        let createDateBetweenFromFilter = (name, value) => ({ name, comparison: "betweenFrom", value });
        let createDateBetweenToFilter = (name, value) => ({ name, comparison: "betweenTo", value });

        let resultFilters = [];
        
        // Handle role_name filter first (for role-specific providers)
        if (requestFilters.hasOwnProperty('role_name')) {
            resultFilters.push(createEqFilter('roleName', requestFilters.role_name));
        }

        // append "id" filter
        if (requestFilters.hasOwnProperty(userFields.id)) {
            resultFilters.push(createEqFilter('id', requestFilters[userFields.id]));
        }

        // append "healthie_id" filter
        if (requestFilters.hasOwnProperty(userFields.healthieId)) {
            resultFilters.push(createEqFilter('healthieId', requestFilters[userFields.healthieId]));
        }

        // append "email" filter
        if (requestFilters.hasOwnProperty(userFields.email)) {
            resultFilters.push(createContainsFilter('email', requestFilters[userFields.email]));
        }
        // append "firstName" filter
        if (requestFilters.hasOwnProperty(userFields.firstName)) {
            resultFilters.push(createContainsFilter('firstName', requestFilters[userFields.firstName]));
        }
        // append "lastName" filter
        if (requestFilters.hasOwnProperty(userFields.lastName)) {
            resultFilters.push(createContainsFilter('lastName', requestFilters[userFields.lastName]));
        }
        // append "roleName" filter
        if (requestFilters.hasOwnProperty(userFields.roleName)) {
            resultFilters.push(createContainsFilter('roleName', requestFilters[userFields.roleName]));
        }
        // append "roleId" filter
        if (requestFilters.hasOwnProperty(userFields.roleId)) {
            resultFilters.push(createInFilter('roleId', requestFilters[userFields.roleId]));
        }
        // append "orgId" filter
        if (requestFilters.hasOwnProperty(userFields.orgId)) {
            resultFilters.push(createEqFilter('orgId', requestFilters[userFields.orgId]));
        }
        // append "isActive" filter
        if (requestFilters.hasOwnProperty(userFields.isActive)) {
            resultFilters.push(createEqFilter('isActive', requestFilters[userFields.isActive]));
        }
        // append "isDeleted" filter
        if (requestFilters.hasOwnProperty(userFields.isDeleted)) {
            resultFilters.push(createEqFilter('isDeleted', requestFilters[userFields.isDeleted]));
        }
        // append "recurlyActive" filter
        if (requestFilters.hasOwnProperty(userFields.recurlyActive)) {
            resultFilters.push(createEqFilter('recurlyActive', requestFilters[userFields.recurlyActive]));
        }
        // append "providerRoleIds" filter
        if (requestFilters.hasOwnProperty(userFields.providerRoleIds)) {
            const providerRoleIds = requestFilters[userFields.providerRoleIds];
            
            // Use "in" comparison for arrays, "eq" for single values
            if (Array.isArray(providerRoleIds) && providerRoleIds.length > 1) {
                resultFilters.push(createInFilter('providerRoleIds', providerRoleIds));
            } else {
                const providerRoleId = providerRoleIds[0];
                resultFilters.push(createEqFilter('providerRoleIds', providerRoleId));
            }
        }
        // append "usState" filter
        if (requestFilters.hasOwnProperty(userFields.usState)) {
            resultFilters.push(createEqFilter('usState', requestFilters[userFields.usState]));
        }
        // append "usState" filter
        if (requestFilters.hasOwnProperty(userFields.wicId)) {
            if (requestFilters[userFields.wicId] === true) {
                resultFilters.push(createIsNotNullFilter('wicId'));
            } else {
                resultFilters.push(createIsNullFilter('wicId'));
            }
        }
        // append "lang" filter
        if (requestFilters.hasOwnProperty(userFields.lang)) {
            resultFilters.push(createEqFilter('lang', requestFilters[userFields.lang]));
        }
        // append "created at" filter
        if (requestFilters.hasOwnProperty(`${userFields.createdAt}_gte`) && requestFilters[`${userFields.createdAt}_gte`]) {
            const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const startDate = parseISO(requestFilters[`${userFields.createdAt}_gte`]);
            const utcStartOfDay = zonedTimeToUtc(startOfDay(startDate), userTimeZone);
            resultFilters.push(createDateBetweenFromFilter('createdAtFrom', utcStartOfDay.toISOString()));
        }
        if (requestFilters.hasOwnProperty(`${userFields.createdAt}_lte`) && requestFilters[`${userFields.createdAt}_lte`]) {
            const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const endDate = parseISO(requestFilters[`${userFields.createdAt}_lte`]);
            const utcEndOfDay = zonedTimeToUtc(endOfDay(endDate), userTimeZone);
            resultFilters.push(createDateBetweenToFilter('createdAtTo', utcEndOfDay.toISOString()));
        }

        // append "due date" filter
        if (requestFilters.hasOwnProperty(userFields.dueDate)) {
            let value = requestFilters[userFields.dueDate];
            const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            // Handle both single date and date range formats
            if (typeof value === 'string') {
                // Single date - use as both start and end of day
                const date = parseISO(value);
                // Convert to UTC while preserving local time boundaries
                const utcStartOfMonth = zonedTimeToUtc(startOfMonth(date), userTimeZone);
                const utcEndOfMonth = zonedTimeToUtc(endOfMonth(date), userTimeZone);
                
                resultFilters.push(createDateBetweenFromFilter('dueDateFrom', utcStartOfMonth.toISOString()));
                resultFilters.push(createDateBetweenToFilter('dueDateTo', utcEndOfMonth.toISOString()));
            }
        }

        return resultFilters;
    }

    const queryParams = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filters: JSON.stringify(prepareFilters()),
    };

    return { url: `${API_URL}/${RESOURCE_NAME}?${queryString.stringify(queryParams)}` };
}

const httpResponseToProviderData = (httpResponse, requestType, requestParams) => {
    var {
        headers,
        json
    } = httpResponse;
    
    // console.log(`[UserDataProvider] Processing ${requestType} response:`, { headers, json, requestParams });
    
    switch (requestType) {
        case GET_LIST:
            if (json['url'] != null) {
              return { data: [{ id: 9999999, url: json['url']}], total: 9999999 };
            }
            const contentRange = headers.get('content-range');
            
            let total = 0;
            if (contentRange) {
                const match = contentRange.match(/\d+$/);
                if (match) {
                    total = parseInt(match[0], 10);
                }
            }
            if (!total && Array.isArray(json)) {
                total = json.length;
            }
            
            return {
                data: Array.isArray(json) ? json : [],
                total: total
            };
        case GET_ONE:
            return {
                data: json
            };
        case CREATE:
            return {
                data: {
                    ...requestParams.data,
                    id: json.id
                }
            };
        default:
            return {
                data: json
            };
    }
};

// Cache for roles with improved invalidation
const rolesCache = {
    data: null,
    timestamp: null,
    CACHE_DURATION: 5 * 60 * 1000, // 5 minutes to balance freshness and performance
    fetchPromise: null // Store the promise to prevent multiple in-flight requests
};

// Shared roles lookup map to avoid recreating it for each batch
let rolesLookupMap = null;

const getRolesWithCache = async (dataProvider) => {
    const now = Date.now();
    const isStale = !rolesCache.timestamp || (now - rolesCache.timestamp > rolesCache.CACHE_DURATION);

    // If cache is still valid, use it
    if (rolesCache.data && !isStale) {
        return rolesCache.data;
    }

    try {
        // If there's already a fetch in progress, return its promise
        if (rolesCache.fetchPromise) {
            return await rolesCache.fetchPromise;
        }

        // Start a new fetch and store the promise immediately
        rolesCache.fetchPromise = (async () => {
            try {
                const { data: roles } = await dataProvider.getList(NON_PROVIDER_ROLES_RESOURCE, {
                    pagination: { page: 1, perPage: 1000 },
                    sort: { field: 'name', order: 'ASC' },
                    filter: {},
                    meta: {
                        cacheLifetime: rolesCache.CACHE_DURATION,
                        staleWhileRevalidate: true
                    }
                });

                // Update cache and lookup map atomically
                rolesCache.data = roles;
                rolesCache.timestamp = now;
                rolesLookupMap = roles.reduce((acc, role) => {
                    acc[role.id] = role;
                    return acc;
                }, {});

                return roles;
            } finally {
                // Clear the fetch promise after it resolves or rejects
                rolesCache.fetchPromise = null;
            }
        })();

        // Wait for and return the result
        return await rolesCache.fetchPromise;
    } catch (error) {
        // If we have cached data, use it even if stale on error
        if (rolesCache.data) {
            console.warn('Failed to fetch fresh roles, using stale data:', error);
            return rolesCache.data;
        }
        throw error;
    }
};

// Helper function to enrich a single record with role information
const enrichRecordWithRole = (record) => {
    // If we don't have a lookup map yet, return the record unchanged
    // It will be enriched in the batch operation
    if (!rolesLookupMap) {
        return record;
    }

    const role = rolesLookupMap[record.role_id];
    const roleName = role?.name?.toLowerCase();

    return {
        ...record,
        roleName,
        isProvider: roleName === USER_ROLES.PROVIDER,
        isPatient: roleName === USER_ROLES.PATIENT,
        isAdmin: roleName === USER_ROLES.ADMIN
    };
};

export const userLifecycleCallbacks = {
    resource: RESOURCE_NAME,
    afterReadMany: async (records, dataProvider) => {
        // Ensure roles are fetched and lookup map is created - only one fetch for the batch
        await getRolesWithCache(dataProvider);
        
        // Process all records in the batch using the shared lookup map
        return records.map(enrichRecordWithRole);
    },
    // Handle single record for backward compatibility
    afterRead: async (record, dataProvider) => {
        // For single records, we can still use the cached data if available
        if (!rolesLookupMap) {
            await getRolesWithCache(dataProvider);
        }
        
        return enrichRecordWithRole(record);
    }
};

// Export the original interface
export default {
    resource: RESOURCE_NAME,
    providerInterface: {
        providerRequestToHttpRequest,
        httpResponseToProviderData
    },
    lifecycleCallbacks: userLifecycleCallbacks
};