import React from 'react';
import { useRecordContext, TextField } from 'react-admin';
import YesNoBadge from './yesNoBadge.component';
import DefaultPlaceholderField from './defPlaceholderField.component';

const YesNoField = ({ source, label }) => {
    const record = useRecordContext();

    if (!record || !source) {
        return null;
    }

    if (record[source] === null || record[source] === undefined) {
        return (
            <DefaultPlaceholderField 
                source={source}
                record={record}
                label={label}
                wrapField={<TextField />}
            />
        );
    }

    const isActive = record[source] === 1;
    return <YesNoBadge isActive={isActive} />;
};

export default YesNoField;
