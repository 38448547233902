import React from 'react';
import { Chip } from '@mui/material';
import { codesFields } from '../../codes.model';

export default ({ record = {} }) => {
    const isRedeemed = record[codesFields.redeemed] === 1;
    return (
        <Chip
            label={isRedeemed ? 'Redeemed' : 'Not Redeemed'}
            color={isRedeemed ? 'success' : 'default'}
            size="small"
        />
    );
};