import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import auth from './auth/auth0';
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import AuthCallbackComponent from './auth/authCallback.component';
import App from './app/app.component';

const RootComponent = () => {
    const [role, setRole] = useState(auth.getPacifyRole());
    const navigate = useNavigate();

    // If we're on the callback page, render the callback component
    if (window.location.pathname === '/callback') {
        return (
            <Routes>
                <Route 
                    path="/callback" 
                    element={
                        <AuthCallbackComponent onSuccess={() => {
                            auth.setNavigate(navigate);
                            auth.handleAuthentication(setRole);
                        }} />
                    } 
                />
            </Routes>
        );
    }

    // If we have a role, render the main app
    if (role) {
        return <App />;
    }

    // Otherwise, redirect to login
    auth.login();
    return null;
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Router>
        <RootComponent />
    </Router>
);

// Unregister any existing service workers
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for(let registration of registrations) {
      registration.unregister();
    }
  });
}
