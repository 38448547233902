import React from 'react';
import {
  DndContext,
  closestCenter,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Select, InputLabel, MenuItem, Box } from '@mui/material';
import { Loading, Error, useRecordContext } from 'react-admin';
import SortableItem from './SortableItem';
import { useProviderRoles } from './hooks';

const ProviderRoleOrderedList = ({ source, record: recordProp, rolesFieldName }) => {
  // Use record from props if provided, otherwise fall back to context
  const recordContext = useRecordContext();
  const record = recordProp || recordContext;

  const {
    orderedProviderRoles,
    setOrderedRoles,
    sensors,
    getListData,
    isLoading,
    error
  } = useProviderRoles(record, rolesFieldName);

  if (isLoading) return <Loading />;
  if (error) return <Error />;

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      const oldIndex = orderedProviderRoles.findIndex(item => item.id === active.id);
      const newIndex = orderedProviderRoles.findIndex(item => item.id === over.id);
      
      const newItems = arrayMove(orderedProviderRoles, oldIndex, newIndex);
      setOrderedRoles(newItems);
    }
  };

  const onAddProviderRole = (event) => {
    const selectedRole = event.target.value;
    if (!orderedProviderRoles.some(role => role.id === selectedRole.id)) {
      setOrderedRoles([...orderedProviderRoles, selectedRole]);
    }
  };

  const onRemoveProviderRole = (roleToRemove) => {
    const newRoles = orderedProviderRoles.filter(role => role.id !== roleToRemove.id);
    setOrderedRoles(newRoles);
  };

  return (
    <Box sx={{
      backgroundColor: "#ddeeff",
      padding: 2.5,
      width: 400,
      '& .MuiInputBase-root': {
        width: '100%'
      }
    }}>
      <InputLabel id="all-provider-roles-label" sx={{ mb: 1 }}>
        Add a provider role by picking from this list
      </InputLabel>
      <Select
        labelId="all-provider-roles-label"
        id="all-provider-roles-select"
        value=""
        onChange={onAddProviderRole}
        variant="filled"
        sx={{ width: '100%', mb: 2 }}
      >
        {Object.values(getListData || {}).map(providerRole => (
          <MenuItem key={providerRole.id} value={providerRole}>
            {providerRole.name}
          </MenuItem>
        ))}
      </Select>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={orderedProviderRoles.map(role => role.id)}
          strategy={verticalListSortingStrategy}
        >
          {orderedProviderRoles.map((role) => (
            <SortableItem 
              key={role.id} 
              id={role.id} 
              value={role}
              onRemove={onRemoveProviderRole}
            />
          ))}
        </SortableContext>
      </DndContext>
    </Box>
  );
};

export default ProviderRoleOrderedList; 